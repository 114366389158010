import { environment } from "../../utils/environment";

const token = function (ctx) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem("access_token")
        resolve(accessToken)
    })
}

export const getAccount = async (data) => {
    let myHeaders = new Headers();
    myHeaders.append("x-user-id", data);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + await token());

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/account/my-account`, requestOptions);
    return response.json();
}

export const createAccount = async (payload) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + await token());

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/account`, requestOptions);
    return response.json();
}

export const deleteAccount = async (pid) => {
    let requestOptions = {
        method: 'DELETE',
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/account/${pid}`, requestOptions);
    return response.json();
}