import React from "react";

import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from "react-router-dom";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const Support2 = () => {
    const navigate = useNavigate();
    return (
        <div className="t">
            <div className="t-container">
                <div className="header-box">
                    <div className="header-layout-2">
                        <p>พบปัญหา</p>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-layout-2">
                        <div className="header-icon">

                        </div>
                        <div className="center-sec">
                            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
                                <path d="M60 0C26.4609 0 1.07414 27.9141 0.004875 60L0 72.4688C0 82.1484 7.67578 90 17.1422 90H21.4289C26.1562 90 30 86.0859 30 81.2344V50.0156C30 45.1641 26.1562 41.25 21.4289 41.25H17.1422C16.3741 41.25 15.6553 41.4175 14.9161 41.5191C22.2188 23.7891 39.6797 11.2969 60 11.2945C80.3297 11.2973 97.7812 23.8008 105.094 41.318C104.344 41.4141 103.617 41.25 102.867 41.25H98.5805C93.8438 41.25 90 45.1641 90 50.0156V81.2344C90 86.0859 93.8438 90 98.5781 90H102.865C104.945 90 106.912 89.5638 108.757 88.8703V93.75C108.757 98.9203 104.552 103.125 99.382 103.125H73.4367C71.4844 99.7734 67.8984 97.5 63.75 97.5H56.25C50.0391 97.5 45 102.539 45 108.75C45 114.961 50.0391 120 56.0391 120H63.75C67.9031 120 71.4914 117.724 73.4391 114.375H99.375C110.749 114.375 120 105.124 120 93.75V60C118.922 27.9141 93.5391 0 60 0ZM17.1422 52.5H18.75V78.75H17.1422C13.8938 78.75 11.25 75.7266 11.25 72.4688V60C11.25 59.4352 11.3155 58.8867 11.3347 58.3263C11.5617 55.0781 14.0414 52.5 17.1422 52.5ZM102.867 78.75H101.25V52.5H102.857C105.958 52.5 108.438 55.0875 108.665 58.3266C108.68 58.8984 108.75 59.4375 108.75 60V72.4781C108.75 75.7266 106.102 78.75 102.867 78.75Z" fill="#4065A9" />
                            </svg>
                            <div className="texts">
                                <p className="large-text">กรุณาติดต่อเจ้าหน้าที่</p>
                            </div>
                            <div className="texts">
                                <p className="normal-text">เพื่อรับการช่วยเหลือในทันที</p>
                                <p className="normal-text">02-678-0666 ต่อ 726-728</p>
                            </div>
                            <button className="question" onClick={() => { navigate("/faq"); }}>
                                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.65625 3C10.6875 3 11.5 3.8125 11.5 4.8125C11.5 5.4375 11.1562 6.03125 10.5938 6.375L9.40625 7.09375V7.15625C9.40625 7.46875 9.125 7.75 8.78125 7.75C8.4375 7.75 8.15625 7.46875 8.15625 7.15625V6.71875C8.15625 6.53125 8.25 6.3125 8.46875 6.1875L9.9375 5.3125C10.125 5.21875 10.2188 5.03125 10.2188 4.8125C10.2188 4.5 9.96875 4.25 9.65625 4.25H8.3125C8 4.25 7.75 4.5 7.75 4.8125C7.75 5.15625 7.4375 5.4375 7.125 5.4375C6.78125 5.4375 6.5 5.15625 6.5 4.8125C6.5 3.8125 7.28125 3 8.3125 3H9.65625ZM8.78125 8.375C9.25 8.375 9.59375 8.75 9.59375 9.1875C9.59375 9.65625 9.21875 10 8.78125 10C8.34375 10 8 9.65625 8 9.1875C8 8.75 8.34375 8.375 8.78125 8.375ZM9 0C13.4062 0 16.9688 2.9375 16.9688 6.5C16.9688 10.0938 13.4062 13 9 13C7.96875 13 6.96875 12.8438 6.09375 12.5625C5.15625 13.2188 3.65625 14 1.75 14C1.4375 14 1.15625 13.8438 1.0625 13.5312C0.96875 13.25 1 12.9375 1.21875 12.7188C1.21875 12.7188 2.1875 11.6562 2.65625 10.4375C1.625 9.34375 1 7.96875 1 6.5C1 2.90625 4.5625 0 9 0ZM9 11.5C12.5625 11.5 15.4375 9.28125 15.4375 6.5C15.4375 3.75 12.5312 1.5 8.96875 1.5C5.40625 1.5 2.5 3.75 2.5 6.5C2.5 7.84375 3.15625 8.84375 3.71875 9.4375L4.375 10.125L4.03125 11C3.875 11.4375 3.65625 11.875 3.40625 12.25C4.15625 12 4.78125 11.6562 5.21875 11.3438L5.8125 10.9062L6.53125 11.125C7.3125 11.375 8.15625 11.5 9 11.5Z" fill="white" />
                                </svg>
                                &nbsp;&nbsp;คำถามที่พบบ่อย
                            </button>
                        </div>
                        <div className="button-box">
                            <div className="button-layout-2">
                                <a className="button-blue tel" href="tel:026780666">โทร</a>
                                <button className="button-white" onClick={() => { navigate("/"); }}>กลับหน้าแรก</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support2