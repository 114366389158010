import { environment } from "../../utils/environment";

const token = function (ctx) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem("access_token")
        resolve(accessToken)
    })
}

export const saveCancelLog = async (formData) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + await token());

    let raw = JSON.stringify(formData);

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/logs`, requestOptions);
    return response.json();
}