import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Error = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/support");
    });
    
    return (
        <div></div>
    )
}

export default Error