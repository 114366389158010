import React, { useState, useEffect, useContext, useRef } from "react";
import CancelModal from "../../components/CancelModal";
import ErrorModal from "../../components/ErrorModal";
import ErrorOccurred from "../../components/ErrorOccurred";
import verifyService from "../../services/verify.service";
import LoadScreen from "../../components/LoadScreen";
import CameraComponent from "../../components/CameraComponent";
import JWE from "../../services/jwe/jwe";
import Resizer from "react-image-file-resizer";

import { saveCancelLog } from "../../services/api/admin.service";
import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { genCaseRef } from "../../utils/common";
import { sliceText, checkSize, checkExpired } from "../../utils/common";
import { getStorageData, setStorageData } from "../../utils/storage";
import { FaceCompare, LiveDetect } from "../../services/api/frs.service";
import { AuthContext } from "../../services/auth/AuthContext";
import { refreshToken } from "../../services/api/auth.service";
import { UpdateState } from "../../services/api/util.service";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const AccountVerify = ({ reset, confirm }) => {
    const webcamRef = useRef(null);
    const [openWebcam, setOpenWebcam] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [img_src, setImgSrc] = useState("");
    const [img_base64, setImgBase64] = useState("");
    const [frs_base64, setFrsBase64] = useState("");
    const [ocr_result, setOcrResult] = useState({});
    const [IDCardSuccess, setIDCardSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [iden, setIden] = useState(false);
    const [errorCount, setErrorCount] = useState(3);
    const [loginData, setLoginData] = useState(getStorageData("loginData"));
    const [isOpenCamera, setIsOpenCamera] = useState(false);

    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (iden === true) checkInput();
    }, [iden]);

    useEffect(() => {
        const cancel = async (payload) => {
            try {
                const response = await saveCancelLog(payload);
                navigate("/support");
            } catch (error) {
                navigate("/support");
            }
        }
        if (errorCount <= 0) {
            let local = JSON.parse(localStorage.getItem("user"));
            let payload = {
                // "caseRef": genCaseRef(), // TODO
                "case_ref": genCaseRef(),
                "phone": local.tel,
                "customer": "-",
                "device": "Web Service",
                "status": IDCardSuccess ? 'Reject การยืนยันใบหน้า เกิน 3 ครั้ง' : 'Reject บัตรประชาชน เกิน 3 ครั้ง',
                "note": IDCardSuccess ? 'VERIFY_FACE' : 'VERIFY_ID_CARD'
            };
            cancel(payload);
        }
    }, [errorCount]);

    const foundError = (type) => {
        switch (type) {
            case "photo":
                setErrorCount(errorCount - 1);
                setOpenModalErrorImage(true);
                setLoading(false);
                document.getElementById("form-cap").reset();
                break;
            case "video":
                setOpenModalErrorVideo(true);
                setErrorCount(errorCount - 1);
                setLoading(false);
                document.getElementById("form-vid").reset();
                break;
            default:
        }
    }

    const handleCapture = async (target) => {
        // TODO id card verify
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                let allow = await checkSize(file, 8);
                if (allow === true) {
                    const newUrl = URL.createObjectURL(file);
                    setImgSrc(newUrl);
                    setLoading(true);
                    const resizeFile = async (fileItem) => {
                        return new Promise((resolve) => {
                            Resizer.imageFileResizer(
                                fileItem,
                                1024,
                                1024,
                                "JPEG",
                                100,
                                0,
                                (uri) => {
                                    resolve(uri);
                                },
                                "file"
                            );
                        })
                    }
                    const newFile = await resizeFile(file);
                    // reader
                    let reader = new FileReader();
                    reader.onloadend = async function () {
                        setImgBase64(await sliceText(reader.result));
                    }
                    reader.readAsDataURL(newFile);
                    let formData = new FormData();
                    formData.append("image", newFile, newFile.name);
                    const data = await verifyService.verifyIdCard(formData);
                    if (data.result) {
                        if (data.result.date_of_expiry_en) {
                            let isExpired = await checkExpired(data.result.date_of_expiry_en);
                            if (isExpired) {
                                // expired
                                setLoading(false);
                                foundError("photo");
                                return;
                            }
                            // all pass
                            setLoading(false);
                            setErrorCount(3);
                            setOcrResult(data.result);
                            setIDCardSuccess(true);
                            return;
                        } else {
                            // no date_of_expiry_en
                            setLoading(false);
                            foundError("photo");
                            return;
                        }
                    } else {
                        if (data.detail === "Signature has expired") {
                            const newToken = await refreshToken(loginData.uuid);
                            if (newToken.access_token) {
                                localStorage.setItem("access_token", newToken.access_token);
                                localStorage.setItem("refresh_token", newToken.access_token);
                                setStorageData("loginData", { ...loginData, token: { access_token: newToken.access_token } });
                                setLoginData({ ...loginData, token: { access_token: newToken.access_token } });
                                setLoading(false);
                                setOpenErrorOccurred(true);
                                setErrorCount(3);
                                return;
                            } else {
                                dispatch({ type: "LOGOUT" });
                            }
                        }
                        // api failed
                        setLoading(false);
                        foundError("photo");
                        return;
                    }
                } else {
                    // over size
                    setLoading(false);
                    setOpenOverSize(true);
                    document.getElementById("form-cap").reset();
                    return;
                }
            }
            else {
                // file not found
                setOpenErrorOccurred(true);
                document.getElementById("form-cap").reset();
                return;
            }
        }
    }

    const handleCaptureVideo = async (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                let allow = await checkSize(file, 30);
                if (allow === true) {
                    setLoading(true);
                    let reader = new FileReader();
                    reader.onloadend = async function () {
                        if (reader.result) {
                            // console.log('reader.result', reader.result)
                            let vid_64 = await sliceText(reader.result);
                            const payloadVideoVerify = {
                                actions: "1",
                                video_base64: vid_64,
                                image_citizen_card_base64: img_base64,
                            };
                            if (loginData == null) dispatch({ type: "LOGOUT" });
                            try { // api
                                const data = await LiveDetect(payloadVideoVerify);
                                if (data.similarity >= 0) {
                                    setFrsBase64(img_base64);
                                    setIden(true); 
                                    confirm();
                                    let data = { pid_src: img_src, pid_base64: img_base64, ocr_result: ocr_result, frs_base64: frs_base64 };
                                    setStorageData("userData", { verification_info: data });
                                    navigate('/personal-information');
                                    return;
                                } else { // api ไม่ผ่าน
                                    if (data.detail === "Signature has expired") {
                                        const newToken = await refreshToken(loginData.uuid);
                                        if (newToken.access_token) {
                                            localStorage.setItem("access_token", newToken.access_token);
                                            localStorage.setItem("refresh_token", newToken.access_token);
                                            setStorageData("loginData", { ...loginData, token: { access_token: newToken.access_token } });
                                            setLoginData({ ...loginData, token: { access_token: newToken.access_token } });
                                            setOpenErrorOccurred(true);
                                        } else {
                                            dispatch({ type: "LOGOUT" });
                                        }
                                    } else {
                                        foundError("video"); 
                                        return;
                                    }
                                }
                            } catch (error) { // api failed
                                console.log(error)
                                setOpenErrorOccurred(true);
                            } finally {
                                document.getElementById("form-vid").reset();
                                setLoading(false);
                            }
                        }
                    }
                    reader.readAsDataURL(file);
                    return;
                }
                // over size
                setOpenOverSize(true);
                document.getElementById("form-vid").reset();
                return;
            }
            else {
                // file not found
                setOpenErrorOccurred(true);
                document.getElementById("form-vid").reset();
                return;
            }
        }
    }

    const faceCompare = async (snapshot) => {
        let epsilon = 85;
        let result = false;
        const encryptedData = await JWE.EncryptData({
            image1_base64: img_base64,
            image2_base64: snapshot,
        });
        try {
            const compareResponse = await FaceCompare(encryptedData, loginData?.token.access_token);
            if (compareResponse.data) {
                const stringData = await JWE.DecryptData(compareResponse.data);
                const formatString = await JWE.convertJSONFormat(stringData);
                const decryptedData = JSON.parse(formatString);
                decryptedData.similarity >= epsilon ? result = true : result = false;
            } else {
                // refresh token
                if (compareResponse.detail === "Signature has expired") {
                    const newToken = await refreshToken(loginData.uuid);
                    if (newToken.access_token) {
                        localStorage.setItem("access_token", newToken.access_token);
                        localStorage.setItem("refresh_token", newToken.access_token);
                        setStorageData("loginData", { ...loginData, token: { access_token: newToken.access_token } });
                        setLoginData({ ...loginData, token: { access_token: newToken.access_token } });
                        setLoading(false);
                        setOpenErrorOccurred(true);
                        return;
                    } else {
                        dispatch({ type: "LOGOUT" });
                    }
                } else {
                    setLoading(false);
                    setOpenErrorOccurred(true);
                    return;
                }
            }
        } catch (error) {
            setOpenErrorOccurred(true);
        } finally {
            return result;
        }
    }

    const updateFRS = async () => {
        const encryptedData = await JWE.EncryptData({
            pid: loginData?.pid.replaceAll(" ", ""),
            state: "frs"
        });
        const updateResponse = await UpdateState(encryptedData, loginData?.token.access_token);
        if (updateResponse.data) {
            //dec
            const stringData = await JWE.DecryptData(updateResponse.data);
            const decryptedData = JSON.parse(stringData);
            if (decryptedData.status === 0) {
                setLoading(false);
                confirm();
                let data = { pid_src: img_src, pid_base64: img_base64, ocr_result: ocr_result, frs_base64: frs_base64 };
                setStorageData("userData", { verification_info: data });
                navigate('/personal-information');
                return;
            } else {
                setLoading(false);
                setOpenErrorOccurred(true);
                return;
            }
        } else {
            // refresh token
            if (updateResponse.detail === "Signature has expired") {
                const newToken = await refreshToken(loginData.uuid);
                if (newToken.access_token) {
                    localStorage.setItem("access_token", newToken.access_token);
                    localStorage.setItem("refresh_token", newToken.access_token);
                    setStorageData("loginData", { ...loginData, token: { access_token: newToken.access_token } });
                    setLoginData({ ...loginData, token: { access_token: newToken.access_token } });
                    setOpenErrorOccurred(true);
                    return;
                } else {
                    dispatch({ type: "LOGOUT" });
                }
            } else {
                setLoading(false);
                setOpenErrorOccurred(true);
                return;
            }
        }
    }

    const checkInput = () => {
        // update state and navigate
        updateFRS();
    }

    const bypass = () => {
        checkInput();
    }

    const handleCamera = () => document.getElementById("input-camera").click();
    const handleVideo = () => document.getElementById("input-video").click();

    const [openModalErrorImage, setOpenModalErrorImage] = useState(false);
    const [openModalErrorVideo, setOpenModalErrorVideo] = useState(false);
    const [openErrorOccurred, setOpenErrorOccurred] = useState(false);
    const [openOverSize, setOpenOverSize] = useState(false);

    // Signout Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleLogout = () => {
        reset();
        navigate("/decline-support");
    }

    const [type, setType] = useState("");

    const handleOpenCamera = () => {
        setType("image");
        setIsOpenCamera(true);
    }

    const handleOpenVideo = () => {
        setType("video");
        setIsOpenCamera(true);
    }

    const handleCloseCamera = (value) => {
        setIsOpenCamera(value);
    }

    const dataURLtoFile = (dataUrl, filename) => {
        let arr = dataUrl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    const blobToFile = (blob, filename) => {
        const blobParts = [blob];
        const file = new File(blobParts, filename);
        
        return file;
    }

    const handlerGetCaptureFile = async (imageCapture) => {
        const file = await dataURLtoFile(imageCapture, "id_card");
        const allowFile = await checkSize(file, 8);

        if (allowFile === true) {
            const newUrl = URL.createObjectURL(file);
            setImgSrc(newUrl);
            setLoading(true);
            setImgBase64(await sliceText(imageCapture));
            let formData = new FormData();
            formData.append("image", file, file.name);
            const data = await verifyService.verifyIdCard(formData);
            if (data.result) {
                setLoading(false);
                setErrorCount(3);
                setOcrResult(data.result);
                setIDCardSuccess(true);
                return;
                // if (data.result.date_of_expiry_en) {
                //     let isExpired = await checkExpired(data.result.date_of_expiry_en);
                //     console.log("isExpired: ", isExpired)

                //     if (isExpired) {
                //         setLoading(false);
                //         foundError("photo");
                //         return;
                //     }
                //     setLoading(false);
                //     setErrorCount(3);
                //     setOcrResult(data.result);
                //     setIDCardSuccess(true);
                //     return;
                // } else {
                //     setLoading(false);
                //     foundError("photo");
                //     return;
                // }
            } else {
                if (data.detail === "Signature has expired") {
                    const newToken = await refreshToken(loginData.uuid);
                    if (newToken.access_token) {
                        localStorage.setItem("access_token", newToken.access_token);
                        localStorage.setItem("refresh_token", newToken.access_token);
                        setStorageData("loginData", { ...loginData, token: { access_token: newToken.access_token } });
                        setLoginData({ ...loginData, token: { access_token: newToken.access_token } });
                        setLoading(false);
                        setOpenErrorOccurred(true);
                        setErrorCount(3);
                        return;
                    } else {
                        dispatch({ type: "LOGOUT" });
                    }
                }
                // api failed
                setLoading(false);
                foundError("photo");
                return;
            }
        } else {
            setLoading(false);
            setOpenOverSize(true);
        }
    }

    const handlerGetVideoFile = async (blob) => {
        const file = await blobToFile(blob, "video");
        const allowFile = await checkSize(file, 30);

        if (allowFile === true) {
            setLoading(true);
            let reader = new FileReader();
            reader.onloadend = async function () {
                if (reader.result) {
                    // console.log('reader.result', reader.result)
                    let videoBase64 = await sliceText(reader.result);
                    const payloadVideoVerify = {
                        actions: "1",
                        video_base64: videoBase64,
                        image_citizen_card_base64: img_base64,
                    };

                    if (loginData == null) dispatch({ type: "LOGOUT" });

                    try { // api
                        const data = await LiveDetect(payloadVideoVerify);
                        if (data.similarity >= 0) {
                            // TODO pass
                            setLoading(false);
                            setFrsBase64(img_base64);
                            setIden(true); 
                            confirm();
                            let data = { pid_src: img_src, pid_base64: img_base64, ocr_result: ocr_result, frs_base64: frs_base64 };
                            setStorageData("userData", { verification_info: data });
                            navigate('/personal-information');
                            return;
                        } else { // api ไม่ผ่าน
                            if (data.detail === "Signature has expired") {
                                const newToken = await refreshToken(loginData.uuid);
                                if (newToken.access_token) {
                                    localStorage.setItem("access_token", newToken.access_token);
                                    localStorage.setItem("refresh_token", newToken.access_token);
                                    setStorageData("loginData", { ...loginData, token: { access_token: newToken.access_token } });
                                    setLoginData({ ...loginData, token: { access_token: newToken.access_token } });
                                    setOpenErrorOccurred(true);
                                } else {
                                    dispatch({ type: "LOGOUT" });
                                }
                            } else {
                                setLoading(false);
                                foundError("video"); 
                                return;
                            }
                        }
                    } catch (error) { // api failed
                        setLoading(false);
                        setOpenErrorOccurred(true);
                    } finally {
                        setLoading(false);
                    }
                }
            }
            reader.readAsDataURL(file);
        } else {
            setOpenOverSize(true);
        }
    }

    return (
        <div className="t">
            {openModalErrorImage &&
                <ErrorModal
                    open={openModalErrorImage}
                    handleClose={() => { setOpenModalErrorImage(false); }}
                    errorCount={errorCount}
                    reset={() => reset()}
                    note={"VERIFY_ID_CARD"}
                    status={"Reject บัตรประชาชน เกิน 3 ครั้ง"}
                >
                    <p>กรุณาถ่ายรูปบัตรประชาชนใหม่</p>
                    <ul>
                        <li>อยู่ในที่มีแสงสว่างเพียงพอ</li>
                        <li>มั่นใจว่าบัตรยังไม่หมดอายุ</li>
                    </ul>
                </ErrorModal>
            }
            {openModalErrorVideo &&
                <ErrorModal
                    open={openModalErrorVideo}
                    handleClose={() => { setOpenModalErrorVideo(false); }}
                    errorCount={errorCount}
                    reset={() => reset()}
                    note={"VERIFY_FACE"}
                    status={"Reject การยืนยันใบหน้า เกิน 3 ครั้ง"}
                >
                    <p>กรุณาถ่ายรูปหน้าของคุณใหม่</p>
                    <ul>
                        <li>อยู่ในที่มีแสงสว่างเพียงพอ</li>
                        <li>หันหน้าแล้วหยุดข้างละ 1 วินาที</li>
                        <li>ลองถอดแว่นตา หรือสิ่งบดบังใบหน้า</li>
                    </ul>
                </ErrorModal>
            }
            {openErrorOccurred && <ErrorOccurred open={openErrorOccurred} handleClose={() => { setOpenErrorOccurred(false); }} >
                <div>กรุณาลองอีกครั้ง</div>
            </ErrorOccurred>}
            {openOverSize && <ErrorOccurred open={openOverSize} handleClose={() => { setOpenOverSize(false); setLoading(false) }} >
                <div>ขนาดของไฟล์ใหญ่เกินไป</div>
                <div>กรุณาลองอีกครั้ง</div>
            </ErrorOccurred>}
            {loading && <LoadScreen />}
            {
                isOpenCamera ? 
                (
                    <CameraComponent closeCamera={handleCloseCamera} file={type === "video" ? handlerGetVideoFile : handlerGetCaptureFile} type={type} />
                ) :
                (
                <div className="t-container">
                {!IDCardSuccess && <>
                    {openModal && <CancelModal open={openModal} logout={handleLogout} handleClose={handleClose} note={"CANCEL"} status={"ยกเลิกการยืนยันตัวตน"} />}
                    <div className="header-box">
                        <div className="header-layout-2">
                            <p>เปิดบัญชีใหม่</p>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="content-layout-2">
                            <div className="sec1">
                                <div className="sec1-head">
                                    <p className="large-text">เริ่มการยืนยันข้อมูล</p>
                                </div>
                                <div className="sec1-box">
                                    <div className="eachBox">
                                        <div className="icon">
                                            <svg width="36" height="54" viewBox="0 0 36 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M28.4488 0H7.11219C3.11159 0 0 3.05859 0 6.75V47.25C0 51.0469 3.11159 54 7.11219 54H28.4488C32.3383 54 35.561 51.0469 35.561 47.25V6.75C35.561 3.05859 32.3383 0 28.4488 0ZM32.0049 47.25C32.0049 49.1484 30.338 50.625 28.4488 50.625H7.11219C5.11189 50.625 3.5561 49.1484 3.5561 47.25V6.75C3.5561 4.95703 5.11189 3.375 7.11219 3.375H28.4488C30.338 3.375 32.0049 4.95703 32.0049 6.75V47.25ZM21.3366 42.1875H14.2244C13.2242 42.1875 12.4463 43.0312 12.4463 43.875C12.4463 44.8242 13.2242 45.5625 14.2244 45.5625H21.3366C22.2256 45.5625 23.1146 44.8242 23.1146 43.875C23.1146 43.0312 22.2256 42.1875 21.3366 42.1875Z" fill="#4065A9" />
                                                <path d="M14.3056 25.5C15.7031 25.5 16.8611 24.4375 16.8611 23.0714C16.8611 21.7433 15.7031 20.6429 14.3056 20.6429C12.8681 20.6429 11.75 21.7433 11.75 23.0714C11.75 24.4375 12.8681 25.5 14.3056 25.5ZM24.5278 24.5893H20.6944C20.1354 24.5893 19.7361 25.0067 19.7361 25.5C19.7361 26.0312 20.1354 26.4107 20.6944 26.4107H24.5278C25.0469 26.4107 25.4861 26.0312 25.4861 25.5C25.4861 25.0067 25.0469 24.5893 24.5278 24.5893ZM15.5833 26.7143H13.0278C11.2309 26.7143 9.83333 28.0804 9.83333 29.75C9.83333 30.0915 10.1128 30.3571 10.4722 30.3571H18.1389C18.4583 30.3571 18.7778 30.0915 18.7778 29.75C18.7778 28.0804 17.3403 26.7143 15.5833 26.7143ZM24.5278 21.5536H20.6944C20.1354 21.5536 19.7361 21.971 19.7361 22.4643C19.7361 22.9955 20.1354 23.375 20.6944 23.375H24.5278C25.0469 23.375 25.4861 22.9955 25.4861 22.4643C25.4861 21.971 25.0469 21.5536 24.5278 21.5536ZM26.4444 17H8.55556C7.11806 17 6 18.1004 6 19.4286V31.5714C6 32.9375 7.11806 34 8.55556 34H26.4444C27.842 34 29 32.9375 29 31.5714V19.4286C29 18.1004 27.842 17 26.4444 17ZM27.0833 31.5714C27.0833 31.9129 26.7639 32.1786 26.4444 32.1786H8.55556C8.19618 32.1786 7.91667 31.9129 7.91667 31.5714V19.4286C7.91667 19.125 8.19618 18.8214 8.55556 18.8214H26.4444C26.7639 18.8214 27.0833 19.125 27.0833 19.4286V31.5714Z" fill="#4065A9" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M27.0833 31.5714C27.0833 31.9129 26.7639 32.1786 26.4444 32.1786H8.55556C8.19618 32.1786 7.91667 31.9129 7.91667 31.5714V19.4286C7.91667 19.125 8.19618 18.8214 8.55556 18.8214H26.4444C26.7639 18.8214 27.0833 19.125 27.0833 19.4286V31.5714ZM16.8611 23.0714C16.8611 24.4375 15.7031 25.5 14.3056 25.5C12.8681 25.5 11.75 24.4375 11.75 23.0714C11.75 21.7433 12.8681 20.6429 14.3056 20.6429C15.7031 20.6429 16.8611 21.7433 16.8611 23.0714ZM20.6944 24.5893H24.5278C25.0469 24.5893 25.4861 25.0067 25.4861 25.5C25.4861 26.0312 25.0469 26.4107 24.5278 26.4107H20.6944C20.1354 26.4107 19.7361 26.0312 19.7361 25.5C19.7361 25.0067 20.1354 24.5893 20.6944 24.5893ZM13.0278 26.7143H15.5833C17.3403 26.7143 18.7778 28.0804 18.7778 29.75C18.7778 30.0915 18.4583 30.3571 18.1389 30.3571H10.4722C10.1128 30.3571 9.83333 30.0915 9.83333 29.75C9.83333 28.0804 11.2309 26.7143 13.0278 26.7143ZM20.6944 21.5536H24.5278C25.0469 21.5536 25.4861 21.971 25.4861 22.4643C25.4861 22.9955 25.0469 23.375 24.5278 23.375H20.6944C20.1354 23.375 19.7361 22.9955 19.7361 22.4643C19.7361 21.971 20.1354 21.5536 20.6944 21.5536Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <p className="large-text">เตรียมบัตรประชาชน</p>
                                            <p className="normal-text">วางในแนวนอน และอยู่ในที่มีแสงสว่าง</p>
                                        </div>
                                    </div>
                                    <div className="eachBox">
                                        <div className="icon">
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M27 0C25.5973 0 24.4688 1.12852 24.4688 2.53125V9.28125C24.4688 10.684 25.5973 11.8125 27 11.8125C28.4027 11.8125 29.5312 10.684 29.5312 9.28125V2.53125C29.5312 1.12852 28.4027 0 27 0ZM27 42.1875C25.5973 42.1875 24.4688 43.316 24.4688 44.7188V51.4688C24.4688 52.8715 25.5973 54 27 54C28.4027 54 29.5312 52.8715 29.5312 51.4688V44.7188C29.5312 43.316 28.4027 42.1875 27 42.1875ZM51.4688 29.5312C52.8715 29.5312 54 28.4027 54 27C54 25.5973 52.8715 24.4688 51.4688 24.4688H44.7188C43.316 24.4688 42.1875 25.5973 42.1875 27C42.1875 28.4027 43.316 29.5312 44.7188 29.5312H51.4688ZM11.8125 27C11.8125 25.5973 10.684 24.4688 9.28125 24.4688H2.53125C1.12852 24.4688 0 25.5973 0 27C0 28.4027 1.12852 29.5312 2.53125 29.5312H9.28125C10.684 29.5312 11.8125 28.4027 11.8125 27ZM46.0898 11.4855C47.0812 10.4941 47.0812 8.89102 46.0898 7.91016C45.0984 6.9293 43.4953 6.91875 42.5145 7.91016L37.7367 12.6879C36.7453 13.6793 36.7453 15.2824 37.7367 16.2633C38.7281 17.2441 40.3313 17.2547 41.3121 16.2633L46.0898 11.4855ZM16.2633 37.7367C15.2719 36.7453 13.6688 36.7453 12.6879 37.7367L7.91016 42.5145C6.91875 43.5059 6.91875 45.109 7.91016 46.0898C8.90156 47.0707 10.5047 47.0812 11.4855 46.0898L16.2633 41.3121C17.2547 40.3207 17.2547 38.7176 16.2633 37.7367ZM42.5145 46.0898C43.5059 47.0812 45.109 47.0812 46.0898 46.0898C47.0707 45.0984 47.0812 43.4953 46.0898 42.5145L41.3121 37.7367C40.3207 36.7453 38.7176 36.7453 37.7367 37.7367C36.7559 38.7281 36.7453 40.3313 37.7367 41.3121L42.5145 46.0898ZM16.2633 16.2633C17.2547 15.2719 17.2547 13.6688 16.2633 12.6879L11.4855 7.91016C10.4941 6.91875 8.89102 6.91875 7.91016 7.91016C6.9293 8.90156 6.91875 10.5047 7.91016 11.4855L12.6879 16.2633C13.6793 17.2547 15.2824 17.2547 16.2633 16.2633ZM27 38.8125C33.5285 38.8125 38.8125 33.5285 38.8125 27C38.8125 20.4715 33.5285 15.1875 27 15.1875C20.4715 15.1875 15.1875 20.4715 15.1875 27C15.1875 33.5285 20.4715 38.8125 27 38.8125Z" fill="#4065A9" />
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <p className="">อยู่ในที่ที่มีแสงสว่างเพียงพอ</p>
                                            <p className="">ในการยืนยันตัวตน</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sec2">
                                <div className="texts">
                                    <p>หากมีข้อความแจ้งเตือน</p>
                                    <p>กรุณากด <b>อนุญาต</b></p>
                                    <p>เพื่อเข้าถึงกล้องถ่ายรูป</p>
                                </div>
                                <div className="button-box">
                                    <div className="button-layout-1">
                                        <button onClick={handleOpen}>ยกเลิก</button>
                                        <button onClick={handleOpenCamera}>ถัดไป</button>
                                    </div>
                                </div>
                                <form id="form-cap">
                                    <input
                                        id="input-camera"
                                        accept="image/*"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => handleCapture(e.target)}
                                        hidden
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </>}
                {IDCardSuccess && <>
                    {openModal && <CancelModal open={openModal} logout={handleLogout} handleClose={handleClose} note={"VERIFY_FACE"} status={"ยกเลิกการยืนยันใบหน้า"} />}
                    {/* <div className="popup" id="popup1">
                        <button onClick={() => { document.getElementById("popup1").remove(); }}>X</button>
                        <button onClick={() => { bypass(); }}>Bypass Live-Detect</button>
                    </div> */}
                    <div className="header-box">
                        <div className="header-layout-2">
                            <p>เปิดบัญชีใหม่&lsquo;</p>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="content-layout-2">
                            <div className="sec1">
                                <div className="sec1-head">
                                    <p className="large-text">ยืนยันใบหน้า</p>
                                    <p className="normal-text">กรุณาทำตามขั้นตอนต่อไปนี้</p>
                                </div>
                                <div className="sec1-box">
                                    <div className="eachBox">
                                        <div className="icon">
                                            <svg width="54" height="51" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.7615 20.6365C22.1286 21.2694 21.2701 21.625 20.375 21.625C19.4799 21.625 18.6214 21.2694 17.9885 20.6365C17.3556 20.0035 17 19.1451 17 18.25C17 17.3549 17.3556 16.4965 17.9885 15.8635C18.6214 15.2306 19.4799 14.875 20.375 14.875C21.2701 14.875 22.1286 15.2306 22.7615 15.8635C23.3944 16.4965 23.75 17.3549 23.75 18.25C23.75 19.1451 23.3944 20.0035 22.7615 20.6365Z" fill="#4065A9" />
                                                <path d="M36.7615 20.6365C36.1286 21.2694 35.2701 21.625 34.375 21.625C33.4799 21.625 32.6214 21.2694 31.9885 20.6365C31.3556 20.0035 31 19.1451 31 18.25C31 17.3549 31.3556 16.4965 31.9885 15.8635C32.6214 15.2306 33.4799 14.875 34.375 14.875C35.2701 14.875 36.1286 15.2306 36.7615 15.8635C37.3944 16.4965 37.75 17.3549 37.75 18.25C37.75 19.1451 37.3944 20.0035 36.7615 20.6365Z" fill="#4065A9" />
                                                <rect x="2.5" y="2.5" width="49" height="46" rx="17.5" stroke="#4065A9" strokeWidth="5" />
                                                <path d="M19 35C19 35 21 38.5 27 38.5C33 38.5 35 35 35 35" stroke="#4065A9" strokeWidth="3" strokeLinecap="round" />
                                                <path d="M25 29C25 29 25.5 30 27 30C28.5 30 29 29 29 29" stroke="#4065A9" strokeWidth="3" strokeLinecap="round" />
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <p className="large-text">1. เริ่มจากใบหน้าตรง</p>
                                        </div>
                                    </div>
                                    <div className="eachBox">
                                        <div className="icon">
                                            <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M48.9375 24.125C48.9375 13.8734 40.6266 5.5625 30.375 5.5625H27.8438C21.5051 5.5625 15.641 9.4332 13.1203 14.8754C12.709 15.7613 12.3293 16.6684 11.918 17.607L11.7598 17.9762C11.3063 19.0414 10.8211 20.1594 10.2938 21.2563C9.22852 23.45 7.9207 25.6859 6.01172 27.5949L5.54766 28.059C5.2418 28.3648 5.0625 28.7867 5.0625 29.2191C5.0625 30.1262 5.80078 30.8645 6.70781 30.8645H9.28125C10.684 30.8645 11.8125 31.993 11.8125 33.3957V40.9895C11.8125 41.9176 12.5719 42.677 13.5 42.677H22.7812C24.184 42.677 25.3125 43.8055 25.3125 45.2082V51.9582C25.3125 53.3609 24.184 54.4895 22.7812 54.4895C21.3785 54.4895 20.25 53.3609 20.25 51.9582V47.75H13.5C9.77695 47.75 6.75 44.723 6.75 41V35.9375H6.70781C3.00586 35.9375 0 32.9316 0 29.2297C0 27.4473 0.706642 25.7492 1.96172 24.4836L2.42578 24.0195C3.76524 22.6801 4.78828 21.0137 5.7375 19.052C6.21211 18.0711 6.65508 17.0586 7.10859 15.9934L7.2668 15.6242C7.66758 14.6855 8.08945 13.7047 8.53242 12.7449C11.8758 5.52031 19.5328 0.5 27.8438 0.5H30.375C43.4215 0.5 54 11.0785 54 24.125C54 29.7781 52.0066 34.9777 48.6949 39.0488C47.7562 40.209 47.25 41.4113 47.25 42.5398V51.9688C47.25 53.3715 46.1215 54.5 44.7188 54.5C43.316 54.5 42.1875 53.3715 42.1875 51.9688V42.5398C42.1875 39.9137 43.3371 37.6039 44.7715 35.8531C47.3766 32.6469 48.9375 28.5758 48.9375 24.125ZM18.5625 24.125C17.6674 24.125 16.809 23.7694 16.176 23.1365C15.5431 22.5035 15.1875 21.6451 15.1875 20.75C15.1875 19.8549 15.5431 18.9965 16.176 18.3635C16.809 17.7306 17.6674 17.375 18.5625 17.375C19.4576 17.375 20.316 17.7306 20.949 18.3635C21.5819 18.9965 21.9375 19.8549 21.9375 20.75C21.9375 21.6451 21.5819 22.5035 20.949 23.1365C20.316 23.7694 19.4576 24.125 18.5625 24.125Z" fill="#4065A9" />
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <p className="large-text">2. หันซ้าย และหยุด 1 วินาที</p>
                                        </div>
                                    </div>
                                    <div className="eachBox">
                                        <div className="icon">
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.0625 23.625C5.0625 13.3734 13.3734 5.0625 23.625 5.0625H26.1562C32.4949 5.0625 38.359 8.9332 40.8797 14.3754C41.291 15.2613 41.6707 16.1684 42.082 17.107L42.2402 17.4762C42.6937 18.5414 43.1789 19.6594 43.7062 20.7563C44.7715 22.95 46.0793 25.1859 47.9883 27.0949L48.4523 27.559C48.7582 27.8648 48.9375 28.2867 48.9375 28.7191C48.9375 29.6262 48.1992 30.3645 47.2922 30.3645H44.7188C43.316 30.3645 42.1875 31.493 42.1875 32.8957V40.4895C42.1875 41.4176 41.4281 42.177 40.5 42.177H31.2188C29.816 42.177 28.6875 43.3055 28.6875 44.7082V51.4582C28.6875 52.8609 29.816 53.9895 31.2188 53.9895C32.6215 53.9895 33.75 52.8609 33.75 51.4582V47.25H40.5C44.223 47.25 47.25 44.223 47.25 40.5V35.4375H47.2922C50.9941 35.4375 54 32.4316 54 28.7297C54 26.9473 53.2934 25.2492 52.0383 23.9836L51.5742 23.5195C50.2348 22.1801 49.2117 20.5137 48.2625 18.552C47.7879 17.5711 47.3449 16.5586 46.8914 15.4934L46.7332 15.1242C46.3324 14.1855 45.9105 13.2047 45.4676 12.2449C42.1242 5.02031 34.4672 0 26.1562 0H23.625C10.5785 0 0 10.5785 0 23.625C0 29.2781 1.99336 34.4777 5.30508 38.5488C6.24375 39.709 6.75 40.9113 6.75 42.0398V51.4688C6.75 52.8715 7.87852 54 9.28125 54C10.684 54 11.8125 52.8715 11.8125 51.4688V42.0398C11.8125 39.4137 10.6629 37.1039 9.22852 35.3531C6.62344 32.1469 5.0625 28.0758 5.0625 23.625ZM35.4375 23.625C36.3326 23.625 37.191 23.2694 37.824 22.6365C38.4569 22.0035 38.8125 21.1451 38.8125 20.25C38.8125 19.3549 38.4569 18.4965 37.824 17.8635C37.191 17.2306 36.3326 16.875 35.4375 16.875C34.5424 16.875 33.684 17.2306 33.051 17.8635C32.4181 18.4965 32.0625 19.3549 32.0625 20.25C32.0625 21.1451 32.4181 22.0035 33.051 22.6365C33.684 23.2694 34.5424 23.625 35.4375 23.625Z" fill="#4065A9" />
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <p className="large-text">3. หันขวา และหยุด 1 วินาที</p>
                                        </div>
                                    </div>
                                    <div className="eachBox">
                                        <div className="icon">
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M27 0C25.1332 0 23.625 1.5082 23.625 3.375V10.125C23.625 11.9918 25.1332 13.5 27 13.5C28.8668 13.5 30.375 11.9918 30.375 10.125V7.03477C39.9516 8.63789 47.25 16.9699 47.25 27C47.25 38.1797 38.1797 47.25 27 47.25C15.8203 47.25 6.75 38.1797 6.75 27C6.75 21.3363 9.07031 16.2105 12.825 12.5402C14.1539 11.2324 14.175 9.10195 12.8777 7.7625C11.5805 6.42305 9.43945 6.4125 8.1 7.70977C3.11133 12.6141 0 19.4484 0 27C0 41.9133 12.0867 54 27 54C41.9133 54 54 41.9133 54 27C54 12.0867 41.9133 0 27 0ZM20.3555 16.7695C19.3641 15.7781 17.7609 15.7781 16.7801 16.7695C15.7992 17.7609 15.7887 19.3641 16.7801 20.3449L25.2176 28.7824C26.209 29.7738 27.8121 29.7738 28.793 28.7824C29.7738 27.791 29.7844 26.1879 28.793 25.207L20.3555 16.7695Z" fill="#4065A9" />
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <p className="large-text">กดปุ่มบันทึกวิดีโอ</p>
                                            <p className="large-text">มีเวลา 5 วินาที</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sec2">
                                <div className="button-box">
                                    <div className="button-layout-1">
                                        <button onClick={handleOpen}>ยกเลิก</button>
                                        <button onClick={handleOpenVideo}>ถัดไป</button>
                                    </div>
                                </div>
                                <form id="form-vid">
                                    <input
                                        id="input-video"
                                        accept="video/*"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => handleCaptureVideo(e.target)}
                                        hidden
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
                )
            }
        </div>
    )
}

export default AccountVerify