import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import RecordRTC from "recordrtc";
import { isIOS, isAndroid } from "react-device-detect";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

if (isIOS) import("../assets/styles/ios.scss");
if (isAndroid) import("../assets/styles/android.scss");

const style = {
  cameraButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",

    br: {
      // width: "48%",
      borderRadius: "8px",
      bgcolor: "#4065A9",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: ["Kanit", "sans-serif"],
    },
  },
};

const CameraComponent = ({ closeCamera, file, type }) => {
  const webcamRef = useRef(null);
  const rectIdCard = useRef(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [currentFaceRecordText, setCurrentFaceRecordText] = useState("");
  const [capturedImage, setCapturedImage] = useState(null);
  let [recording, setRecording] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  let [mediaRecorder, setMediaRecorder] = useState(null);
  const [isInitialCamera, setIsInitialCamera] = useState(false);
  const [frontCamera, setFrontCamera] = useState(true);
  const [timeoutHandle, setTimeoutHandle] = useState(null);
  const [blob, setBlob] = useState(null);
  const [webcamPosition, setWebcamPosition] = useState({ top: 0, left: 0 });

  const handleUploadFileImage = () =>
    document.getElementById("upload-image").click();

  useEffect(() => {
    if (type === "video") {
      setFrontCamera(true);
    } else if (type === "image") {
      setFrontCamera(false);
    } else {
      setFrontCamera(false);
    }
  }, [type]);

  useEffect(() => {
    const getWebcamPosition = async () => {
      if (webcamRef.current && webcamRef.current.video) {
        const videoElement = webcamRef.current.video;
        const rect = videoElement.getBoundingClientRect();
        const { top, left } = rect;
        setWebcamPosition({ top, left });

        setTimeout(() => {
          setIsInitialCamera(true);
        }, 1800);
      }
    };

    getWebcamPosition();

    window.addEventListener("resize", getWebcamPosition);

    return () => {
      window.removeEventListener("resize", getWebcamPosition);
    };
  }, []);

  const containerBox = useRef(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const cropTop = img.height * (30 / 100);
      const cropHeight = img.height * (40 / 100);
      const cropLeft = img.width * (10 / 100);
      const cropWidth = img.width * (80 / 100);

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx.drawImage(
        img,
        cropLeft,
        cropTop,
        cropWidth,
        cropHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const croppedImageSrc = canvas.toDataURL("image/jpeg");
      setCapturedImage(croppedImageSrc);
    };
  };

  const startRecording = async () => {
    const options = {
      type: "video",
      mimeType: "video/mp4",
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 2500000,
      bitsPerSecond: 2500000,
    };

    const stream = webcamRef.current.video.srcObject;
    const recorder = new RecordRTC(stream, options);

    recorder.startRecording();

    recording = true;
    mediaRecorder = recorder;
    setRecording(true);
    setMediaRecorder(recorder);

    const timeouts = [0, 1, 2, 3, 4, 5, 6];
    const actions = ["ใบหน้าตรง", "หันซ้าย", "หันขวา"];
    let timeoutId = null;

    if (recording) {
      setTimeout(() => {
        for (let index = 0; index < timeouts.length; index++) {
          timeoutId = setTimeout(() => {
            if (index >= 0 && index < 2) {
              setCurrentFaceRecordText(actions[0]);
            } else if (index >= 2 && index < 4) {
              setCurrentFaceRecordText(actions[1]);
            } else if (index >= 4 && index < 6) {
              setCurrentFaceRecordText(actions[2]);
            } else {
              setCurrentFaceRecordText("");
            }

            if (index === timeouts.length - 1) {
              stopRecording();
            }
          }, 1000 * index);
        }

        setTimeoutHandle(timeoutId);
      }, 1500);
    }
  };

  const stopRecording = () => {
    setCurrentFaceRecordText("");
    clearTimeout(timeoutHandle);
    setTimeoutHandle(null);

    if (mediaRecorder && recording) {
      mediaRecorder.stopRecording(() => {
        setRecording(false);
        const blob = mediaRecorder.getBlob();
        const url = URL.createObjectURL(blob);
        setBlob(blob);
        setVideoURL(url);

        const tracks = webcamRef.current.video.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      });
    }
  };

  const changeFileImage = (e) => {
    if (e.target.files && e.target.files.length !== 0) {
      const file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = async function () {
        if (reader.result) {
          setCapturedImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const saveRecordVideo = () => {
    file(blob);
    closeCamera(false);
  };

  const saveRecordImage = () => {
    file(capturedImage);
    closeCamera(false);
  };

  const clearWebcamRef = () => {
    setBlob(null);
    setVideoURL(null);
    setCapturedImage(null);
  };

  return (
    <Container maxWidth="sm">
      {frontCamera && (
        <Grid
          sx={{ flexGrow: 1 }}
          container
          justifyContent="center"
          // spacing={2}
          style={{ marginTop: "2rem" }}
        >
          <div>กรุณามองตรงไม่สวมแว่นไม่สวมหมวกลืมตา</div>
        </Grid>
      )}
      <div ref={containerBox}>
        {capturedImage && (
          <img
            src={capturedImage}
            alt="Captured"
            style={{
              marginTop: "1.5rem",
              width: "100%",
              border: "2px solid black",
            }}
          />
        )}
        {videoURL && (
          <video
            src={videoURL}
            controls
            style={{
              marginTop: "1.5rem",
              width: "100%",
              border: "2px solid black",
            }}
          ></video>
        )}
        {!capturedImage && !videoURL && (
          <>
            <div
              style={{
                position: "relative",
                border: "2px solid black",
                display: "flex",
                marginTop: "1rem",
              }}
            >
              <Webcam
                ref={webcamRef}
                audio={false}
                mirrored={false}
                screenshotFormat="image/jpeg"
                minScreenshotWidth={1024}
                minScreenshotHeight={1024}
                videoConstraints={{
                  facingMode: type === "image" ? "environment" : "user",
                }}
                style={{
                  width: "100%",
                  transform: frontCamera ? "scaleX(-1)" : "",
                }}
              />
              {isInitialCamera && !frontCamera && (
                <div
                  ref={rectIdCard}
                  style={{
                    position: "absolute",
                    top: "30%",
                    left: "10%",
                    width: "80%",
                    height: "40%",
                    border: "2px solid green",
                    backgroundColor: "transparent",
                  }}
                />
              )}

              <Stack
                direction="row"
                spacing={1}
                style={{
                  position: "absolute",
                  top: "10%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {currentFaceRecordText !== "" && (
                  <Chip
                    style={{ color: "white", fontSize: "20px" }}
                    label={currentFaceRecordText}
                  />
                )}
              </Stack>
            </div>
          </>
        )}
        <input
          id="upload-image"
          accept="image/*"
          type="file"
          onChange={(e) => changeFileImage(e)}
          hidden
        />
      </div>
      <Grid
        sx={{ flexGrow: 1 }}
        container
        justifyContent="center"
        style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
      >
        {capturedImage || videoURL ? (
          <>
            <Button
              sx={style.cameraButton.br}
              variant="contained"
              style={{ marginRight: "5px", width: "35%" }}
              onClick={clearWebcamRef}
            >
              ถ่ายใหม่
            </Button>
            <Button
              sx={style.cameraButton.br}
              variant="contained"
              style={{ marginLeft: "5px", width: "35%" }}
              onClick={frontCamera ? saveRecordVideo : saveRecordImage}
            >
              ถัดไป
            </Button>
          </>
        ) : (
          <>
            {frontCamera ? (
              <>
                {recording ? (
                  <Button
                    sx={style.cameraButton.br}
                    style={{ width: "60%" }}
                    variant="contained"
                  >
                    <RadioButtonCheckedIcon
                      style={{ paddingRight: "0.5em", color: "red" }}
                    />
                    กำลังบันทึกวิดิโอ
                  </Button>
                ) : (
                  <Button
                    sx={style.cameraButton.br}
                    style={{ width: "60%" }}
                    variant="contained"
                    onClick={startRecording}
                  >
                    <RadioButtonUncheckedIcon
                      style={{ paddingRight: "0.5em" }}
                    />
                    เริ่มบันทึกวิดิโอ
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button
                  sx={style.cameraButton.br}
                  style={{ marginRight: "5px", width: "40%" }}
                  variant="contained"
                  onClick={handleUploadFileImage}
                >
                  เลือกรูปภาพ
                </Button>
                <Button
                  sx={style.cameraButton.br}
                  style={{ marginLeft: "5px", width: "40%" }}
                  variant="contained"
                  onClick={capture}
                >
                  เริ่มถ่ายภาพ
                </Button>
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default CameraComponent;
