import { environment } from "../../utils/environment";

const token = function (ctx) {
  return new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem("access_token");
    resolve(accessToken);
  });
};

export const getHoliday = async (access_token) => {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + access_token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/holiday/is_holiday`,
    requestOptions
  );
  return response.json();
};

export const DOPACheck = async (data) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + (await token()));

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/dopa/check-card-status/check-card-services`,
    requestOptions
  );
  return response.json();
};

export const UpdateState = async (data, access_token) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + access_token);

  let requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify({ data: data }),
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/account/change-state`,
    requestOptions
  );
  return response.json();
};

export const checkCreateAccount = async (data, access_token) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + access_token);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ data: data }),
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/account/request-ndid`,
    requestOptions
  );
  return response.json();
};
