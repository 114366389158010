const PrivateKey = `-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCbVetxLTsm99Jv
iG+uiLWEDkH+luwyHECPyJ8U7r8I7kCzILznENzs/7N6EG+MtNLS4XE+0UWLq5oC
qHu/YBrjQFzJD9Sl7gIHXLzeWJ5sBg18RyN6RTp3ZgADMj4rxqqX38s47ZnoiJY4
wBMvnC8oTmJsYUWfJ4W5maaSQZc1zHT2GGlH2dpJ+YiJ2M6aUrjXDUHARJehwk/h
kfFkE+hUot9PalvrQ3/fTjNPIvd2vFwqKTPDCqkozcUBKdWq/jScXnRzNRpz0Zz7
IDXipJuh8l/vyv7dPYPLj2dG7apQuBx2XrviPkCpKOxSebcc65RN6yD7sOYBoGMt
OZLeHzhVAgMBAAECggEAO7OjqJ2WSQ1hy204Y2/2t9kVA0k1YbMbG0gj4FM8iC2u
H0q7X+HKz6odbJJJlV5gUx3DvT2Iibx8Fn50BWNmIia4IqzbcaTPEc5SZvDPfrw6
zaO33oQiCwbghVn3rDKQmYuxm2Ey0L1CY3BrT1arh0XNLVObgyaZDnMgIsFj4RBo
gTwlyA1KvrWfsU5xR3n06iC2DTimlE7Yawl3x/AJCx9K0BC7tAeM9ZA6uAE8TMxg
L42cMymGydtO/XIeVPvnpalEYmhQ+ETkapnlrxUbQ/eWPmqGy80XiSkjWhNJz/4r
jTR2B70YFTeltTwF35wcGWtD36rB4kmFhX55ctHhtwKBgQDbJNmjpc8rNCUXaNyg
uufqvmJHcBzWBN4s1v0jZNVcbJL/ngcjUrQMjslS72bT/vZvlHDTEAvb3zgQs1qf
5fer9DtEVcRBRekJIadonIeQK8cQcnx6w/9Rea3ZDiWsWlsLEmlWCtC0GIHHZMZm
hNPvckGLvM2+SZcwArS0zF9CYwKBgQC1ddRI/2BT3nioU6nQe3vGH1GLHH4q0xAL
oEnxcqVqTLoMxiQFxCnoiaPtgfsY9rOgC5TwyZ8rkbFhdv7P+4JJZ4ZTeLnky5G0
SoFz+9tgLsSXn7t8SOPUpDpeNoS4yu45zSEFjT6GZdZefW2vOp1tZr6SjEewz+aG
PxMQUGS75wKBgHu5hWsAbUO+MAbUfGP+wntlO8WLhaxLmUiFpOu7vT5uSlwpU2xd
ACxq3T2Gzm54b5g1nbF8SMW9dEDxkgPe6YNa469EIQadOuGd749/eIYsQTqG+MKz
L85HPaDjfN+fL/HDVlduycA0aekye1VcPu/ceSc01JBPqh1AGdhN3qZzAoGAdbEI
kJfh2EOwqiwpNakBbEyfzMQcKJZgD8pUax9anglJmkNg1cSXEdVD1yW4wEYx1pns
bge3DsEd9QMC3c5YgFd+zeC9YOGPjQnN0HYHoOh0ZYsaRM/ZxO8HVFez2U71F9Qt
mLY1WWHvmbVsUHyJ4o0DCXWmgc01zNhX+vrJev0CgYEAiBqth0atJ4KZURaYmEil
Aru9AHR8AwNo62RT0du4t55k1Ex+i14hyHltTd2RjKv4/lBaBbxGPWfUOuCO/DK2
ReuTx1O1mKC2VzqqI7IJbui90BrplZ7zUV1eFAmKXwKD7HLeGV+Kmpw+7qeKpxXG
5pCFpyYmuhDveOi7qbA6U7c=
-----END PRIVATE KEY-----`;

export default PrivateKey ;