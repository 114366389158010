export const Business = [
    // {
    //     id: 1,
    //     title: 'บุคคลธรรมดา',
    // },
    // {
    //     id: 2,
    //     title: 'นิติบุคคล',
    // },
    {
        id: 1,
        title: "เกษตรและอุตสาหกรรมอาหาร",
    },
    {
        id: 2,
        title: "สินค้าอุปโภคบริโภค",
    },
    {
        id: 3,
        title: "ธุรกิจการเงิน",
    },
    {
        id: 4,
        title: "สินค้าอุตสาหกรรม",
    },
    {
        id: 5,
        title: "อสังหาริมทรัพย์และก่อสร้าง",
    },
    {
        id: 6,
        title: "ทรัพยากร",
    },
    {
        id: 7,
        title: "บริการ",
    },
    {
        id: 8,
        title: "เทคโนโลยี",
    },
    {
        id: 9,
        title: "อื่นๆ",
    },
];

// export const Business = [
//     {
//         "id": 1,
//         "title": "เกษตรกรรม การป่าไม้ และการประมง"
//     },
//     {
//         "id": 2,
//         "title": "การทำเหมืองแร่ และเหมืองหิน"
//     },
//     {
//         "id": 3,
//         "title": "การผลิต"
//     },
//     {
//         "id": 4,
//         "title": "ไฟฟ้า ก๊าซ ไอน้ำ และระบบการปรับอากาศ"
//     },
//     {
//         "id": 5,
//         "title": "การจัดหาน้ำ การจัดการน้ำเสีย และของเสียรวมถึงกิจกรรมที่เกี่ยวข้อง"
//     },
//     {
//         "id": 6,
//         "title": "การก่อสร้าง"
//     },
//     {
//         "id": 7,
//         "title": "การขายส่งและการขายปลีก การซ่อมยานยนต์และจักรยานยนต์"
//     },
//     {
//         "id": 8,
//         "title": "การขนส่งและสถานที่เก็บสินค้า"
//     },
//     {
//         "id": 9,
//         "title": "ที่พักแรมและบริการด้านอาหาร"
//     },
//     {
//         "id": 10,
//         "title": "ข้อมูลข่าวสารและการสื่อสาร"
//     },
//     {
//         "id": 11,
//         "title": "กิจกรรมทางการเงินและการประกันภัย"
//     },
//     {
//         "id": 12,
//         "title": "กิจกรรมเกี่ยวกับอสังหาริมทรัพย์"
//     },
//     {
//         "id": 13,
//         "title": "กิจกรรมวิชาชีพวิทยาศาสตร์และกิจกรรมทางวิชาการ"
//     },
//     {
//         "id": 14,
//         "title": "กิจกรรมการบริหารและบริการสนับสนุน"
//     },
//     {
//         "id": 15,
//         "title": "การบริหารราชการ การป้องกันประเทศและการประกันสังคมภาคบังคับ"
//     },
//     {
//         "id": 16,
//         "title": "การศึกษา"
//     },
//     {
//         "id": 17,
//         "title": "กิจกรรมด้านสุขภาพและงานสังคมสงเคราะห์"
//     },
//     {
//         "id": 18,
//         "title": "ศิลปะ ความบันเทิงและนันทนาการ"
//     },
//     {
//         "id": 19,
//         "title": "ศิลปะ ความบันเทิงและนันทนาการ"
//     },
//     {
//         "id": 20,
//         "title": "กิจกรรมการจ้างงานในครัวเรือน กิจกรรมการผลิตสินค้า และบริการที่ทำขึ้นเองเพื่อใช้ในครัวเรือนฯ"
//     },
//     {
//         "id": 21,
//         "title": "กิจกรรมขององค์การระหว่างประเทศและภาคีสมาชิก"
//     },
// ]
