import { environment } from "../../utils/environment";

export const requestOTP = async (data, uuid) => {
    let myHeaders = new Headers();
    myHeaders.append("x-user-id", uuid);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/auth/request/otp`, requestOptions);
    return response.json();
}

export const verifyOTP = async (data, uuid) => {
    let myHeaders = new Headers();
    myHeaders.append("x-user-id", uuid);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/auth/verify/otp`, requestOptions);
    return response.json();
}