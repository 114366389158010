import axios from "axios";
import localStorageService from "./temp/localStorage.service";

import { environment } from "../utils/environment";

const url = environment.baseUrl + environment.port + "/capital-link/api";

const httpClient = (baseURL = url) => {
  const http = axios.create({
    baseURL,
  });

  http.interceptors.request.use(async (config) => {
    (
      config.headers
    ).Accept = 'application/json';

    (
      config.headers
    )['x-api-key'] = environment.xApiKey;

    const accessToken = localStorageService.getItem("ACCESS_TOKEN");
    if (accessToken) {
      (
        config.headers
      ).authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  http.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log(error);
      if (error.response) {
        if (error.response.status === 401) {
          return Promise.reject(error);
        } else if (error?.response?.status === 400) {
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    }
  );
  return http;
};

export default httpClient;