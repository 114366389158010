import React, { useState, useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import CancelModal from "../../components/CancelModal";
import $ from 'jquery';

import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from 'react-device-detect';
import { convertQuestionnaireFormat } from "../../utils/common"
import { setStorageData } from "../../utils/storage";
import { ReactComponent as RadioIcon } from "../../assets/icons/radio_icon.svg";
import { ReactComponent as RadioCheckedIcon } from "../../assets/icons/radio_checked_icon_green.svg";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

function CustomRadio(props) {
    return (
        <Radio
            checkedIcon={<RadioCheckedIcon />}
            icon={<RadioIcon />}
            {...props}
        />
    );
}

function CustomCheckbox(props) {
    return (
        <Checkbox
            checkedIcon={<RadioCheckedIcon />}
            icon={<RadioIcon />}
            {...props}
        />
    );
}

const Survey = ({ reset, confirm }) => {
    const navigate = useNavigate();
    const [next, setNext] = useState(false);
    const [ETC1, setETC1] = useState(false);
    const [ETC2, setETC2] = useState(false);
    const [ETC4, setETC4] = useState(false);
    const [ETC5, setETC5] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [surveyData, setSurveyData] = useState({
        'q_1_type': {
            q: "รูปแบบ",
            ans: "บุคคลธรรมดา",
        },
    });
    const [surveyCB1, setSurveyCB1] = useState({
        q: "1.วัตถุประสงค์ในการทําธุรกรรม (เลือกได้มากกว่า 1 ข้อ)",
        "เพื่อการออม": false,
        "เพื่อการลงทุนในธุรกิจ": false,
        "เพื่อการอุปโภคบริโภค": false,
        "อื่น ๆ (โปรดระบุ)": false,
        text: "",
    });
    const [surveyCB2, setSurveyCB2] = useState({
        q: "2. แหล่งที่มาของเงิน (เลือกได้มากกว่า 1 ข้อ)",
        "การออม": false,
        "ธุรกิจส่วนตัว": false,
        "มรดก": false,
        "อื่น ๆ (โปรดระบุ)": false,
        text: "",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleRadioChange = (event) => {
        if (event.target.name === "q_2_4") event.target.value === "อื่น ๆ (โปรดระบุ)" ? setETC4(true) : setETC4(false);
        else if (event.target.name === "q_2_5") event.target.value === "เป็น / เคยเป็น / เกี่ยวข้องเป็น (โปรดระบุ)" ? setETC5(true) : setETC5(false);
        setSurveyData({
            ...surveyData,
            [event.target.name]: {
                q: document.getElementById([event.target.name]).innerHTML,
                ans: (event.target.name === "q_2_4" && event.target.value === "อื่น ๆ (โปรดระบุ)") || (event.target.name === "q_2_5" && event.target.value === "เป็น / เคยเป็น / เกี่ยวข้องเป็น (โปรดระบุ)") ? "" : event.target.value
            },
        });
    }

    const handleCheckboxChange = (event) => {
        if (event.target.name === "q_2_1") {
            if (event.target.value === "อื่น ๆ (โปรดระบุ)") {
                if (ETC1 === true) setSurveyCB1({
                    ...surveyCB1,
                    text: ""
                });
                setETC1(!ETC1);
            } else {
                setSurveyCB1({
                    ...surveyCB1,
                    [event.target.value]: !(surveyCB1?.[event.target.value])
                });
            }
        } else if (event.target.name === "q_2_2") {
            if (event.target.value === "อื่น ๆ (โปรดระบุ)") {
                if (ETC2 === true) setSurveyCB2({
                    ...surveyCB2,
                    text: ""
                });
                setETC2(!ETC2);
            } else {
                setSurveyCB2({
                    ...surveyCB2,
                    [event.target.value]: !(surveyCB2?.[event.target.value])
                });
            }
        }
    }

    const handleSubmit1 = (event) => {
        event.preventDefault();
        let x = Object.values(surveyData).find(item => item.ans === "ใช่");
        if (x === undefined) handleNext();
        else navigate('/support2');
    };

    const handleSubmit2 = async (event) => {
        event.preventDefault();
        if ($('div.survey-choices-large.req1 :checkbox:checked').length > 0) {
            if ($('div.survey-choices-large.req2 :checkbox:checked').length > 0) {
                let data = {
                    questionnaire_info: {
                        ...surveyData,
                        'q_2_1': await convertQuestionnaireFormat(surveyCB1),
                        'q_2_2': await convertQuestionnaireFormat(surveyCB2)
                    }
                };
                setStorageData("userData", data);
                handle2cam();
            } else {
                setError(true);
                setErrorText('กรุณาเลือกคำตอบในข้อ 2 อย่างน้อยหนึ่งข้อ');
            }
        } else {
            setError(true);
            setErrorText('กรุณาเลือกคำตอบในข้อ 1 อย่างน้อยหนึ่งข้อ');
        }
    };

    const handleNext = () => {
        setNext(true);
        window.scrollTo(0, 0);
    }

    const handle2cam = () => {
        confirm();
        navigate("/account-verify");
    }

    const handleTextChange = (e) => {
        let input = e.target.value.replace(/[^\d\sa-zA-Zก-๏]/ig, "");
        switch (e.target.id) {
            case "etc1":
                setSurveyCB1({
                    ...surveyCB1,
                    text: input
                });
                break;
            case "etc2":
                setSurveyCB2({
                    ...surveyCB2,
                    text: input
                });
                break;
            case "etc4":
                setSurveyData({
                    ...surveyData,
                    "q_2_4": {
                        ...surveyData["q_2_4"],
                        ans: input,
                    },
                });
                break;
            case "etc5":
                setSurveyData({
                    ...surveyData,
                    "q_2_5": {
                        ...surveyData["q_2_5"],
                        ans: input,
                    },
                });
                break;
            default:
        }
    }

    // Signout Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleLogout = () => {
        reset();
        navigate("/decline-support");
    }
    return (
        <div className={`${isIOS ? "t" : "a"}`}>
            <div className={`${isIOS ? "t-container" : "a-container"}`}>
                {!next && <>
                    {openModal && <CancelModal open={openModal} logout={handleLogout} handleClose={handleClose} note={"CANCEL"} status={"ยกเลิกการทำแบบสอบถามเพื่อหาข้อบ่งชี้การเป็นบุคคลสหรัฐฯ"} />}
                    <div className={`${isIOS ? "header-box" : "android-header-box"}`}>
                        <div className={`${isIOS ? "header-layout-3" : "android-header"}`}>
                            <div className="sec1">
                                <div className="texts">
                                    <svg width="24" height="24" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
                                        <path d="M1.97187 14.5H8.89062C9.26562 15.075 9.74688 15.5781 10.3031 15.9781C10.2063 15.9906 10.1031 16 10 16H2C0.895313 16 0 15.1031 0 14V2C0 0.895313 0.895313 0 2 0H7.17188C7.70312 0 8.20938 0.210719 8.58438 0.585938L11.4156 3.41563C11.7906 3.79063 12 4.29688 12 4.82812V6.20625C11.4625 6.35938 10.9563 6.59062 10.5 6.89062V5H8C7.44688 5 7 4.55312 7 4V1.5H2C1.72375 1.5 1.5 1.72375 1.5 2V14C1.5 14.275 1.72375 14.5 2 14.5H1.97187ZM18 11.5C18 13.9844 15.9844 16 13.5 16C11.0156 16 8.97188 13.9844 8.97188 11.5C8.97188 9.01562 11.0156 7 13.5 7C15.9844 7 18 9.01562 18 11.5ZM14.8969 10.1469L13 12.0437L12.1031 11.1469C11.9094 10.95 11.5906 10.95 11.3969 11.1469C11.2 11.3406 11.2 11.6594 11.3969 11.8531L12.6469 13.1031C12.8406 13.3 13.1594 13.3 13.3531 13.1031L15.6031 10.8531C15.8 10.6594 15.8 10.3406 15.6031 10.1469C15.4094 9.95 15.0906 9.95 14.8969 10.1469Z" fill="white" />
                                    </svg>
                                    <p>แบบสอบถาม</p>
                                </div>
                                <div className="pages">
                                    <p>(3/4)</p>
                                </div>
                            </div>
                            <div className="sec2">
                                <div className="texts">
                                    <p>เพื่อหาข้อบ่งชี้การเป็นบุคคลสหรัฐฯ</p>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
                        <div className="content-layout-3">
                            <div className="main-survey">
                                <form name="form1" onSubmit={handleSubmit1}>
                                    <div className="q-box">
                                        <p id="q_1_type">รูปแบบ</p>
                                    </div>
                                    <div style={{ paddingBottom: "10px" }}>บุคคลธรรมดา</div>
                                    {/* <RadioGroup className="survey-choices" onChange={handleRadioChange} style={{justifyContent: 'start'}}>
                                        <FormControlLabel name="q_1_type" className="choiceBox" value="บุคคลธรรมดา"  control={<CustomRadio required={true} />} label="บุคคลธรรมดา" />
                                    </RadioGroup> */}
                                    <div className="q-box">
                                        <p>1.&nbsp;</p>
                                        <p id="q_1_1">เป็นพลเมืองอเมริกัน หรือมีหลายสัญชาติหนึ่งในนั้น คืออเมริกัน</p>
                                    </div>
                                    <RadioGroup className="survey-choices" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_1_1" className="choiceBox" value="ใช่" control={<CustomRadio required={true} />} label="ใช่" />
                                        <FormControlLabel name="q_1_1" className="choiceBox" style={{ marginLeft: '6px' }} value="ไม่ใช่" control={<CustomRadio required={true} />} label="ไม่ใช่" />
                                    </RadioGroup>
                                    <div className="q-box">
                                        <p>2.&nbsp;</p>
                                        <p id="q_1_2">มีถิ่นฐานอยู่ในสหรัฐอเมริกา เพื่อวัตถุประสงค์ในการเก็บภาษีของสหรัฐ</p>
                                    </div>
                                    <RadioGroup className="survey-choices" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_1_2" className="choiceBox" value="ใช่" control={<CustomRadio required={true} />} label="ใช่" />
                                        <FormControlLabel name="q_1_2" className="choiceBox" style={{ marginLeft: '6px' }} value="ไม่ใช่" control={<CustomRadio required={true} />} label="ไม่ใช่" />
                                    </RadioGroup>
                                    <div className="q-box">
                                        <p>3.&nbsp;</p>
                                        <p id="q_1_3">ถือกรีนการ์ดของสหรัฐฯ</p>
                                    </div>
                                    <RadioGroup className="survey-choices" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_1_3" className="choiceBox" value="ใช่" control={<CustomRadio required={true} />} label="ใช่" />
                                        <FormControlLabel name="q_1_3" className="choiceBox" style={{ marginLeft: '6px' }} value="ไม่ใช่" control={<CustomRadio required={true} />} label="ไม่ใช่" />
                                    </RadioGroup>
                                    <div className="q-box">
                                        <p>4.&nbsp;</p>
                                        <p id="q_1_4">เกิดในสหรัฐอเมริกา</p>
                                    </div>
                                    <RadioGroup className="survey-choices" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_1_4" className="choiceBox" value="ใช่" control={<CustomRadio required={true} />} label="ใช่" />
                                        <FormControlLabel name="q_1_4" className="choiceBox" style={{ marginLeft: '6px' }} value="ไม่ใช่" control={<CustomRadio required={true} />} label="ไม่ใช่" />
                                    </RadioGroup>
                                    <div className="q-box">
                                        <p>5.&nbsp;</p>
                                        <p id="q_1_5">มีที่อยู่อาศัยในปัจจุบัน หรือที่อยู่เพื่อการติดต่อในประเทศสหรัฐฯ สําหรับ บัญชีที่เปิดไว้กับบริษัทฯ</p>
                                    </div>
                                    <RadioGroup className="survey-choices" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_1_5" className="choiceBox" value="ใช่" control={<CustomRadio required={true} />} label="ใช่" />
                                        <FormControlLabel name="q_1_5" className="choiceBox" style={{ marginLeft: '6px' }} value="ไม่ใช่" control={<CustomRadio required={true} />} label="ไม่ใช่" />
                                    </RadioGroup>
                                    <div className="q-box">
                                        <p>6.&nbsp;</p>
                                        <p id="q_1_6">มีหมายเลขโทรศัพท์ในสหรัฐที่ใช้ติดต่อกับท่านหรือคนอื่นที่เกี่ยวข้องกับบัญชี ที่เปิดกับบริษัทฯ</p>
                                    </div>
                                    <RadioGroup className="survey-choices" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_1_6" className="choiceBox" value="ใช่" control={<CustomRadio required={true} />} label="ใช่" />
                                        <FormControlLabel name="q_1_6" className="choiceBox" style={{ marginLeft: '6px' }} value="ไม่ใช่" control={<CustomRadio required={true} />} label="ไม่ใช่" />
                                    </RadioGroup>
                                    <div className="q-box">
                                        <p>7.&nbsp;</p>
                                        <p id="q_1_7">มีคําสั่งโอนเงินเป็นประจําอัตโนมัติจากบัญชีกับบริษัทไปบัญชีในสหรัฐฯ</p>
                                    </div>
                                    <RadioGroup className="survey-choices" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_1_7" className="choiceBox" value="ใช่" control={<CustomRadio required={true} />} label="ใช่" />
                                        <FormControlLabel name="q_1_7" className="choiceBox" style={{ marginLeft: '6px' }} value="ไม่ใช่" control={<CustomRadio required={true} />} label="ไม่ใช่" />
                                    </RadioGroup>
                                    <div className="q-box">
                                        <p>8.&nbsp;</p>
                                        <p id="q_1_8">มีการมอบอํานาจหรือให้อํานาจการลงลายมือชื่อแก่บุคคลที่มีที่อยู่ในสหรัฐฯ เพื่อการใด ๆ ที่เกี่ยวกับบัญชีที่เปิดกับบริษัทฯ</p>
                                    </div>
                                    <RadioGroup className="survey-choices" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_1_8" className="choiceBox" value="ใช่" control={<CustomRadio required={true} />} label="ใช่" />
                                        <FormControlLabel name="q_1_8" className="choiceBox" style={{ marginLeft: '6px' }} value="ไม่ใช่" control={<CustomRadio required={true} />} label="ไม่ใช่" />
                                    </RadioGroup>
                                    <div className="button-box">
                                        <div className="button-layout-1">
                                            <button onClick={handleOpen} type="button">ปฎิเสธ</button>
                                            <button type="submit">ยอมรับ</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>}
                {next && <div>
                    {openModal && <CancelModal open={openModal} logout={handleLogout} handleClose={handleClose} note={"CANCEL"} status={"ยกเลิกการทำแบบสอบถามวัตถุประสงค์ในการทําธุรกรรม"} />}
                    <div className={`${isIOS ? "header-box" : "android-header-box"}`}>
                        <div className={`${isIOS ? "header-layout-3" : "android-header"}`}>
                            <div className="sec1">
                                <div className="texts">
                                    <svg width="24" height="24" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
                                        <path d="M1.97187 14.5H8.89062C9.26562 15.075 9.74688 15.5781 10.3031 15.9781C10.2063 15.9906 10.1031 16 10 16H2C0.895313 16 0 15.1031 0 14V2C0 0.895313 0.895313 0 2 0H7.17188C7.70312 0 8.20938 0.210719 8.58438 0.585938L11.4156 3.41563C11.7906 3.79063 12 4.29688 12 4.82812V6.20625C11.4625 6.35938 10.9563 6.59062 10.5 6.89062V5H8C7.44688 5 7 4.55312 7 4V1.5H2C1.72375 1.5 1.5 1.72375 1.5 2V14C1.5 14.275 1.72375 14.5 2 14.5H1.97187ZM18 11.5C18 13.9844 15.9844 16 13.5 16C11.0156 16 8.97188 13.9844 8.97188 11.5C8.97188 9.01562 11.0156 7 13.5 7C15.9844 7 18 9.01562 18 11.5ZM14.8969 10.1469L13 12.0437L12.1031 11.1469C11.9094 10.95 11.5906 10.95 11.3969 11.1469C11.2 11.3406 11.2 11.6594 11.3969 11.8531L12.6469 13.1031C12.8406 13.3 13.1594 13.3 13.3531 13.1031L15.6031 10.8531C15.8 10.6594 15.8 10.3406 15.6031 10.1469C15.4094 9.95 15.0906 9.95 14.8969 10.1469Z" fill="white" />
                                    </svg>
                                    <p>แบบสอบถาม</p>
                                </div>
                                <div className="pages">
                                    <p>(4/4)</p>
                                </div>
                            </div>
                            <div className="sec2">
                                <div className="texts">
                                    <p>วัตถุประสงค์ในการทําธุรกรรม</p>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
                        <div className="content-layout-3">
                            <div className="main-survey">
                                <form name="form2" onSubmit={handleSubmit2}>
                                    <div className="q-box">
                                        <p>1.&nbsp;</p>
                                        <p id="q_2_1">วัตถุประสงค์ในการทําธุรกรรม (เลือกได้มากกว่า 1 ข้อ)</p>
                                    </div>
                                    <FormGroup className="survey-choices-large req1" onChange={handleCheckboxChange}>
                                        <FormControlLabel name="q_2_1" className="choiceBox" value="เพื่อการออม" control={<CustomCheckbox />} label="เพื่อการออม" />
                                        <FormControlLabel name="q_2_1" className="choiceBox" value="เพื่อการลงทุนในธุรกิจ" control={<CustomCheckbox />} label="เพื่อการลงทุนในธุรกิจ" />
                                        <FormControlLabel name="q_2_1" className="choiceBox" value="เพื่อการอุปโภคบริโภค" control={<CustomCheckbox />} label="เพื่อการอุปโภคบริโภค" />
                                        <FormControlLabel name="q_2_1" className="choiceBox" value="อื่น ๆ (โปรดระบุ)" control={<CustomCheckbox />} label="อื่น ๆ (โปรดระบุ)" />
                                        {ETC1 && <div className="input">
                                            <input type="text" id="etc1" name="text" onChange={handleTextChange} value={surveyCB1.text} required placeholder="โปรดระบุ....."></input>
                                        </div>}
                                    </FormGroup>
                                    <div className="q-box">
                                        <p>2.&nbsp;</p>
                                        <p id="q_2_2">แหล่งที่มาของเงิน (เลือกได้มากกว่า 1 ข้อ)</p>
                                    </div>
                                    <FormGroup className="survey-choices-large req2" onChange={handleCheckboxChange}>
                                        <FormControlLabel name="q_2_2" className="choiceBox" value="การออม" control={<CustomCheckbox />} label="การออม" />
                                        <FormControlLabel name="q_2_2" className="choiceBox" value="ธุรกิจส่วนตัว" control={<CustomCheckbox />} label="ธุรกิจส่วนตัว" />
                                        <FormControlLabel name="q_2_2" className="choiceBox" value="มรดก" control={<CustomCheckbox />} label="มรดก" />
                                        <FormControlLabel name="q_2_2" className="choiceBox" value="อื่น ๆ (โปรดระบุ)" control={<CustomCheckbox />} label="อื่น ๆ (โปรดระบุ)" />
                                        {ETC2 && <div className="input">
                                            <input type="text" id="etc2" name="text" onChange={handleTextChange} value={surveyCB2.text} required placeholder="โปรดระบุ....."></input>
                                        </div>}
                                    </FormGroup>
                                    <div className="q-box">
                                        <p>3.&nbsp;</p>
                                        <p id="q_2_3">ประมาณการรายได้โดยประมาณต่อเดือน (บาท)</p>
                                    </div>
                                    <RadioGroup className="survey-choices-large" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_2_3" className="choiceBox" value="น้อยกว่า 15,000" control={<CustomRadio required={true} />} label="น้อยกว่า 15,000" />
                                        <FormControlLabel name="q_2_3" className="choiceBox" value="15,001 - 30,000" control={<CustomRadio required={true} />} label="15,001 - 30,000" />
                                        <FormControlLabel name="q_2_3" className="choiceBox" value="30,001 -60,000" control={<CustomRadio required={true} />} label="30,001 -60,000" />
                                        <FormControlLabel name="q_2_3" className="choiceBox" value="60,001 -100,000" control={<CustomRadio required={true} />} label="60,001 -100,000" />
                                        <FormControlLabel name="q_2_3" className="choiceBox" value="100,001 ขึ้นไป" control={<CustomRadio required={true} />} label="100,001 ขึ้นไป" />
                                    </RadioGroup>
                                    <div className="q-box">
                                        <p>4.&nbsp;</p>
                                        <p id="q_2_4">เป็นผู้มีรายได้ ซึ่งมีแหล่งที่มาจากประเทศ</p>
                                    </div>
                                    <RadioGroup className="survey-choices-large" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_2_4" className="choiceBox" value="ไทย" control={<CustomRadio required={true} />} label="ไทย" />
                                        <FormControlLabel name="q_2_4" className="choiceBox" value="อื่น ๆ (โปรดระบุ)" control={<CustomRadio required={true} />} label="อื่น ๆ (โปรดระบุ)" />
                                        {ETC4 && <div className="input">
                                            <input type="text" id="etc4" name="text" onChange={handleTextChange} value={surveyData["q_2_4"].ans} required placeholder="โปรดระบุ....."></input>
                                        </div>}
                                    </RadioGroup>
                                    <div className="q-box">
                                        <p>5.&nbsp;</p>
                                        <p id="q_2_5">ท่านเป็นบุคคลที่มีสถานภาพทางการเมือง หรือเป็นสมาชิกในครอบครัว / ผู้ใกล้ชิดของบุคคลที่มีสถานภาพทางการเมืองหรือไม่</p>
                                    </div>
                                    <RadioGroup className="survey-choices-large" onChange={handleRadioChange}>
                                        <FormControlLabel name="q_2_5" className="choiceBox" value="ไม่เป็น" control={<CustomRadio required={true} />} label="ไม่เป็น" />
                                        <FormControlLabel name="q_2_5" className="choiceBox" value="เป็น / เคยเป็น / เกี่ยวข้องเป็น (โปรดระบุ)" control={<CustomRadio required={true} />} label="เป็น / เคยเป็น / เกี่ยวข้องเป็น (โปรดระบุ)" />
                                        {ETC5 && <div className="input">
                                            <input type="text" id="etc5" name="text" onChange={handleTextChange} value={surveyData["q_2_5"].ans} required placeholder="โปรดระบุ....."></input>
                                        </div>}
                                    </RadioGroup>
                                    <div className="error-text">
                                        {error && <h4>{errorText}</h4>}
                                    </div>
                                    <div className="button-box">
                                        <div className="button-layout-1">
                                            <button onClick={handleOpen} type="button">ปฎิเสธ</button>
                                            <button type="submit">ยอมรับ</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div >
    )
}

export default Survey