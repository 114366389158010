import React from "react";
import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from "react-router-dom";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const style = {
    box: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        margin: "12px"
    },
    content: {
        color: "#373737",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "normal",
    },
    desc: {
        color: "#7D7D7D",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
    },
    hr: {
        width: "100%"
    },
    backButton: {
        width: "100%",
        borderRadius: "8px",
        border: "1px solid #4065A9",
        color: "#4065A9",
        backgroundColor: "white",
        fontSize: "16px",
        fontWeight: "bold",
        padding: "10px 0px",
        marginTop: "16px"
    },
    arrow: {
        marginLeft: "16px"
    }
}

const Faq = () => {
    const navigate = useNavigate();
    return (
        <div className={`${isIOS ? "t" : "a"}`}>
            <div className={`${isIOS ? "t-container" : "a-container"}`}>
                <div className={`${isIOS ? "header-box" : "android-header-box-10vh"}`}>
                    <div className="header-layout-1">
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <p>คู่มือการเปิดบัญชี</p>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
                    <div className="faq">
                        <div style={style.box} onClick={() => navigate("/faq/open-account")}>
                            <div>
                                <p style={style.content}>ขั้นตอนการเปิดบัญชี</p>
                                <p style={style.desc}>ทั้งหมด 7 ขั้นตอน</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={{ width: "100%" }}>
                            <p style={style.desc}>คำถามที่พบบ่อย</p>
                        </div>

                        <div style={style.box} onClick={() => navigate("/faq/pid")}>
                            <div>
                                <p style={style.content}>ทำไมถึงถ่ายรูปบัตรประชาชนไม่ผ่าน?</p>
                                <p style={style.desc}>คำแนะนำเบื้องต้น</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box} onClick={() => navigate("/faq/frs")}>
                            <div>
                                <p style={style.content}>ทำไมถึงถ่ายรูปยืนยันใบหน้าไม่ผ่าน?</p>
                                <p style={style.desc}>คำอธิบาย</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box} onClick={() => navigate("/faq/ndid")}>
                            <div>
                                <p style={style.content}>การยืนยัน NDID</p>
                                <p style={style.desc}>คำอธิบาย</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box} onClick={() => navigate("/faq/passbook")}>
                            <div>
                                <p style={style.content}>ทำไมถึงถ่ายรูปถ่ายรูปหน้าสมุดบัญชีคู่ฝากไม่ผ่าน ?</p>
                                <p style={style.desc}>คำอธิบาย</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <button style={style.backButton} onClick={() => navigate("/")}>กลับหน้าแรก</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq