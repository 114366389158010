import React, { useEffect, useState } from "react";
import moment from "moment/moment";

import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { PIDFormat, telephoneFormat, laserCodeFormat } from "../../utils/common";
import { ThaiAddress } from "../../utils/address";
import { Occupation } from "../../utils/occupation";
import { Business } from "../../utils/business";
import { getStorageData, setStorageData } from "../../utils/storage";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const AccountSummary = ({ confirm }) => {
    const [next, setNext] = useState(true);
    const [user, setUserData] = useState(getStorageData("userData"));
    const [pInfo, setPInfo] = useState(getStorageData("userData").personal_info);

    const [canAmphure, setCanAmphure] = useState(false);
    const [canTambon, setCanTambon] = useState(false);
    const [canCAmphure, setCanCAmphure] = useState(false);
    const [canCTambon, setCanCTambon] = useState(false);
    const [jobETC, setJobETC] = useState(false);
    const [contactETC, setContactETC] = useState(false);
    const [addressObj, setAddressObj] = useState({});
    const [CAddressObj, setCAddressObj] = useState({});
    const [jobEtcText, setJobEtcText] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!next) {
            let personalInfo = getStorageData("userData").personal_info;
            setPInfo(personalInfo);
            setAddressObj(ThaiAddress.find(element => element.name_th === personalInfo.company_address.provide));
            setCanAmphure(true);
            setCanTambon(true);
        }
    }, [next]);

    const navigate = useNavigate();

    const handlesubmit = () => {
        confirm();
        navigate('/passbook-verify');
    }

    const handleSubmit = (event) => {
        if (pInfo.job_info.occupation === "default"
            || pInfo.job_info.business_type === "default"
            || pInfo.company_address.provide === "default"
            || pInfo.company_address.district === "default"
            || pInfo.company_address.sub_district === "default"
            || document.getElementById("present_address").value === "default"
            || (document.getElementById("present_address").value === "etc" && pInfo.present_address.provide === "default")
            || (document.getElementById("present_address").value === "etc" && pInfo.present_address.district === "default")
            || (document.getElementById("present_address").value === "etc" && pInfo.present_address.sub_district === "default")) {
            setError(true);
            event.preventDefault();
            if (pInfo.job_info.occupation === "default") document.getElementById("job").parentNode.classList.add("error");
            if (pInfo.job_info.business_type === "default") document.getElementById("business_type").parentNode.classList.add("error");
            if (pInfo.company_address.provide === "default") document.getElementById("company_province").parentNode.classList.add("error");
            if (pInfo.company_address.district === "default") document.getElementById("company_district").parentNode.classList.add("error");
            if (pInfo.company_address.sub_district === "default") document.getElementById("company_sub_district").parentNode.classList.add("error");
            if (document.getElementById("present_address").value === "default") document.getElementById("present_address").parentNode.classList.add("error");
            if (document.getElementById("present_address").value === "etc" && pInfo.present_address.provide === "default") document.getElementById("contact_province").parentNode.classList.add("error");
            if (document.getElementById("present_address").value === "etc" && pInfo.present_address.district === "default") document.getElementById("contact_district").parentNode.classList.add("error");
            if (document.getElementById("present_address").value === "etc" && pInfo.present_address.sub_district === "default") document.getElementById("contact_sub_district").parentNode.classList.add("error");
        } else {
            event.preventDefault();
            let data = pInfo;
            if (jobETC) data.job_info.occupation = jobEtcText;
            let pa = document.getElementById("present_address").value;
            if (pa === "permanent_address") data.present_address = data.permanent_address;
            if (pa === "company_address") data.present_address = data.company_address
            setError(false);
            setStorageData("userData", { personal_info: data });
            window.scrollTo(0, 0);
            setNext(!next);
        }
    }

    const onInputChange = (e) => {
        switch (e.target.name) {
            case "provide":
                setCanAmphure(false);
                setCanTambon(false);
                if (e.target.value !== "default") {
                    setAddressObj(ThaiAddress.find(element => element.name_th === e.target.value));
                    !next ? (() => {
                        setPInfo({ ...pInfo, company_address: { ...pInfo.company_address, provide: e.target.value, district: "default", sub_district: "default" } });
                        document.getElementById("company_province").parentNode.classList.remove("error");
                    })() : (() => {
                        setPInfo({ ...pInfo, permanent_address: { ...pInfo.permanent_address, provide: e.target.value, district: "default", sub_district: "default" } });
                        document.getElementById("permanent_province").parentNode.classList.remove("error");
                    })();
                    setCanAmphure(true);
                } else {
                    !next ? (() => {
                        setPInfo({ ...pInfo, company_address: { ...pInfo.company_address, provide: "default", district: "default", sub_district: "default" } });
                        document.getElementById("company_province").parentNode.classList.remove("error");
                    })() : (() => {
                        setPInfo({ ...pInfo, permanent_address: { ...pInfo.permanent_address, provide: "default", district: "default", sub_district: "default" } });
                        document.getElementById("permanent_province").parentNode.classList.remove("error");
                    })();
                }
                break;
            case "district":
                setCanTambon(false);
                !next ? (() => {
                    setPInfo({ ...pInfo, company_address: { ...pInfo.company_address, district: e.target.value, sub_district: "default" } });
                    document.getElementById("company_district").parentNode.classList.remove("error");
                })() : (() => {
                    setPInfo({ ...pInfo, permanent_address: { ...pInfo.permanent_address, district: e.target.value, sub_district: "default" } });
                    document.getElementById("permanent_district").parentNode.classList.remove("error");
                })();
                setCanTambon(true);
                break;
            case "sub_district":
                !next ? (() => {
                    setPInfo({ ...pInfo, company_address: { ...pInfo.company_address, sub_district: e.target.value } });
                    document.getElementById("company_sub_district").parentNode.classList.remove("error");
                })() : (() => {
                    setPInfo({ ...pInfo, permanent_address: { ...pInfo.permanent_address, sub_district: e.target.value } });
                    document.getElementById("permanent_sub_district").parentNode.classList.remove("error");
                })();
                setCanTambon(true);
                break;
            case "house_no":
                !next ? setPInfo({ ...pInfo, company_address: { ...pInfo.company_address, house_no: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/ig, "") } })
                    : setPInfo({ ...pInfo, permanent_address: { ...pInfo.permanent_address, house_no: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/ig, "") } });
                break;
            case "job":
                document.getElementById("job").parentNode.classList.remove("error");
                if (e.target.value === "etc") setJobETC(true);
                else {
                    setPInfo({ ...pInfo, job_info: { ...pInfo.job_info, occupation: e.target.value } });
                    setJobETC(false);
                }
                setPInfo({ ...pInfo, job_info: { ...pInfo.job_info, occupation: e.target.value } });
                break;
            case "job_etc":
                setJobEtcText(e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/ig, ""));
                break;
            case "company":
                setPInfo({ ...pInfo, job_info: { ...pInfo.job_info, company: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/ig, "") } });
                break;
            case "business_type":
                document.getElementById("business_type").parentNode.classList.remove("error");
                setPInfo({ ...pInfo, job_info: { ...pInfo.job_info, business_type: e.target.value } });
                break;
            case "role":
                setPInfo({ ...pInfo, job_info: { ...pInfo.job_info, job_position: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/ig, "") } });
                break;
            case "work_tel":
                if (e.target.value.length <= 10) setPInfo({ ...pInfo, company_address: { ...pInfo.company_address, workTel: e.target.value.replace(/[^\d]/ig, "") } });
                break;
            case "present_address":
                document.getElementById("present_address").parentNode.classList.remove("error");
                setPInfo({
                    ...pInfo, present_address: {
                        provide: "default",
                        district: "default",
                        sub_district: "default",
                        road: "",
                        village_no: "",
                        house_no: "",
                        zip_code: "",
                        contactTel: ""
                    }
                });
                e.target.value === "etc" ? setContactETC(true) : (() => { setContactETC(false); setCanCAmphure(false); setCanCTambon(false); })();
                break;
            case "contact_provide":
                setCanCAmphure(false);
                setCanCTambon(false);
                setCAddressObj(ThaiAddress.find(element => element.name_th === e.target.value));
                setPInfo({ ...pInfo, present_address: { ...pInfo.present_address, provide: e.target.value, district: "default", sub_district: "default" } });
                document.getElementById("contact_province").parentNode.classList.remove("error");
                setCanCAmphure(true);
                break;
            case "contact_district":
                setCanCTambon(false);
                setPInfo({ ...pInfo, present_address: { ...pInfo.present_address, district: e.target.value, sub_district: "default" } });
                document.getElementById("contact_district").parentNode.classList.remove("error");
                setCanCTambon(true);
                break;
            case "contact_sub_district":
                setPInfo({ ...pInfo, present_address: { ...pInfo.present_address, sub_district: e.target.value } });
                document.getElementById("contact_sub_district").parentNode.classList.remove("error");
                setCanCTambon(true);
                break;
            case "contact_house_no":
                setPInfo({ ...pInfo, present_address: { ...pInfo.present_address, house_no: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/ig, "") } });
                break;
            case "contact_tel":
                if (e.target.value.length <= 10) setPInfo({ ...pInfo, present_address: { ...pInfo.present_address, contactTel: e.target.value.replace(/[^\d]/ig, "") } });
                break;
            case "phone_number":
                if (e.target.value.length <= 10) setPInfo({ ...pInfo, phone_number: e.target.value.replace(/[^\d]/ig, "") });
                break;
            case "email":
                setPInfo({ ...pInfo, email: e.target.value.replace(/[^a-z@\d.-_]/ig, "") });
                break;
            default:
        }
    };

    // Signout Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleLogout = () => {
        // reset();
        navigate("/decline-support");
    }
    return (
        <div className={`${isIOS ? "t" : "a"}`}>
            <div className={`${isIOS ? "t-container" : "a-container"}`}>
                <div className={`${isIOS ? "header-box" : "android-header-box-10vh"}`}>
                    <div className="header-layout-2">
                        {next ? <p>ยืนยันข้อมูลเพื่อเปิดบัญชี</p> : <p>แก้ไขข้อมูล</p>}
                    </div>
                </div>
                <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
                    <div className="content-layout-3">
                        <div className="final-content">
                            <p>ข้อมูลส่วนตัว</p>
                            <div>{user.personal_info.first_name} {user.personal_info.last_name}</div>
                            <div>{moment(user.personal_info.birth_date, 'YYYY-MM-DD').add(543, 'y').format('DD MMM YYYY')}</div>
                            <div>{PIDFormat(user.personal_info.pid, 'add')}</div>

                            <p>วันเกิด</p>
                            <div>{moment(user.personal_info.birth_date, 'YYYY-MM-DD').add(543, 'y').format('DD MMM YYYY')}</div>

                            <p>วันหมดอายุบัตร</p>
                            <div>{moment(user.personal_info.expire_date, 'YYYY-MM-DD').add(543, 'y').format('DD MMM YYYY')}</div>
                            {next ? <>
                                <p>ที่อยู่ปัจจุบัน</p>
                                <div>{user.personal_info.present_address.house_no}&nbsp;
                                    แขวง/ตำบล {user.personal_info.present_address.sub_district}&nbsp;
                                    อำเภอ/เขต {user.personal_info.present_address.district}&nbsp;
                                    จังหวัด {user.personal_info.present_address.provide}&nbsp;
                                    {user.personal_info.present_address.zip_code}
                                </div>

                                <p>อาชีพ</p>
                                <div>{user.personal_info.job_info.occupation}</div>

                                <p>ที่ทำงาน</p>
                                <div>{user.personal_info.job_info.company}</div>
                                <div>{user.personal_info.job_info.business_type}</div>
                                <div>{user.personal_info.job_info.job_position}</div>

                                <p>สถานที่ทำงาน</p>
                                <div>{user.personal_info.company_address.house_no}&nbsp;
                                    แขวง/ตำบล {user.personal_info.company_address.sub_district}&nbsp;
                                    อำเภอ/เขต {user.personal_info.company_address.district}&nbsp;
                                    จังหวัด {user.personal_info.company_address.provide}&nbsp;
                                    {user.personal_info.company_address.zip_code}
                                </div>

                                <p>อื่นๆ</p>
                                <div>{user.personal_info.permanent_address.house_no}&nbsp;
                                    แขวง/ตำบล {user.personal_info.permanent_address.sub_district}&nbsp;
                                    อำเภอ/เขต {user.personal_info.permanent_address.district}&nbsp;
                                    จังหวัด {user.personal_info.permanent_address.provide}&nbsp;
                                    {user.personal_info.permanent_address.zip_code}
                                </div>

                                <p>ติดต่อ</p>
                                <div>{telephoneFormat(user.personal_info.phone_number, 'add')}</div>
                                <div>{user.personal_info.email}</div>
                            </> :
                                <>
                                    <form name="form2" onSubmit={(event) => handleSubmit(event)}>
                                        <div className="section">
                                            <p>อาชีพ</p>
                                            <div className="input">
                                                <select id="job" name="job" onChange={(e) => onInputChange(e)} required value={pInfo.job_info.occupation}>
                                                    <option value="default" disabled>กรุณาเลือกอาชีพ</option>
                                                    {Occupation.map((item) => (
                                                        <option value={item.title} id={item.id} key={item.id}>{item.title}</option>
                                                    ))}
                                                    <option value="etc">อื่นๆ</option>
                                                </select>
                                            </div>
                                            {jobETC && <div className="input">
                                                <input type="text" name="job_etc" placeholder="โปรดระบุ..." onChange={(e) => onInputChange(e)} required />
                                            </div>}
                                        </div>
                                        <div className="section">
                                            <p>ที่ทำงาน</p>
                                            <div className="input">
                                                <input type="text" name="company" placeholder="ชื่อที่ทำงาน" value={pInfo.job_info.company} onChange={(e) => onInputChange(e)} required />
                                            </div>
                                            <div className="input">
                                                <select id="business_type" name="business_type" onChange={(e) => onInputChange(e)} required value={pInfo.job_info.business_type}>
                                                    <option value="default" disabled>ประเภทธุรกิจ</option>
                                                    {Business.map((item) => (
                                                        <option value={item.title} id={item.id} key={item.id}>{item.title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="input">
                                                <input type="text" name="role" placeholder="ตำแหน่ง" value={pInfo.job_info.job_position} onChange={(e) => onInputChange(e)} required />
                                            </div>
                                        </div>
                                        <div className="section">
                                            <p>สภานที่ทำงาน</p>
                                            <div className="input">
                                                <select id="company_province" name="provide" onChange={(e) => onInputChange(e)} required value={pInfo.company_address.provide}>
                                                    <option value="default" disabled>จังหวัด</option>
                                                    {ThaiAddress.map((item) => (
                                                        <option value={item.name_th} id={item.id} key={item.id}>{item.name_th}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="input">
                                                {!canAmphure && <select id="company_district" name="district" disabled required value={pInfo.company_address.district}>
                                                    <option value="default" disabled>กรุณาเลือกจังหวัด</option>
                                                </select>}
                                                {canAmphure && <select id="company_district" name="district" onChange={(e) => onInputChange(e)} required value={pInfo.company_address.district}>
                                                    <option value="default" disabled>อำเภอ / เขต</option>
                                                    {addressObj.amphure.map((item) => (
                                                        <option value={item.name_th} id={item.id} key={item.id}>{item.name_th}</option>
                                                    ))}
                                                </select>}
                                            </div>
                                            <div className="input">
                                                {!canTambon &&
                                                    <select id="company_sub_district" name="sub_district" disabled required value={pInfo.company_address.sub_district}>
                                                        <option value="default" disabled>กรุณาเลือกอำเภอ / เขต</option>
                                                    </select>
                                                }
                                                {canTambon && <select id="company_sub_district" name="sub_district" onChange={(e) => onInputChange(e)} required value={pInfo.company_address.sub_district}>
                                                    <option value="default" disabled>ตำบล / แขวง</option>
                                                    {addressObj.amphure.find(element => element.name_th === pInfo.company_address.district).tambon.map((item) => (
                                                        <option value={item.name_th} id={item.id} key={item.id}>{item.name_th}</option>
                                                    ))}
                                                </select>}
                                            </div>
                                            <div className="input">
                                                <input type="text" placeholder="ที่อยู่" name="house_no" value={pInfo.company_address.house_no} onChange={(e) => onInputChange(e)} required />
                                            </div>
                                            {/* <div className="input">
                                                <input type="text" placeholder="หมายเลขโทรศัพท์​" name="work_tel" value={pInfo.company_address.workTel} onChange={(e) => onInputChange(e)} required />
                                            </div> */}
                                        </div>
                                        <div className="section">
                                            <p>สถานที่สะดวกในการติดต่อ</p>
                                            <div className="input">
                                                <select id="present_address" name="present_address" onChange={(e) => onInputChange(e)} required>
                                                    <option value="default" disabled>กรุณาเลือกสถานที่สะดวกในการติดต่อ</option>
                                                    <option value="permanent_address">ตามทะเบียนบ้าน</option>
                                                    <option value="company_address">สถานที่ทำงาน</option>
                                                    <option value="etc">อื่นๆ</option>
                                                </select>
                                            </div>
                                            {contactETC && <div className="section">
                                                <div className="input">
                                                    <select id="contact_province" name="contact_provide" onChange={(e) => onInputChange(e)} required value={pInfo.present_address.provide}>
                                                        <option value="default" disabled>จังหวัด</option>
                                                        {ThaiAddress.map((item) => (
                                                            <option value={item.name_th} id={item.id} key={item.id}>{item.name_th}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="input">
                                                    {!canCAmphure && <select id="contact_district" name="contact_district" disabled required value={pInfo.present_address.district}>
                                                        <option value="default" disabled>กรุณาเลือกจังหวัด</option>
                                                    </select>}
                                                    {canCAmphure && <select id="contact_district" name="contact_district" onChange={(e) => onInputChange(e)} required value={pInfo.present_address.district}>
                                                        <option value="default" disabled>อำเภอ / เขต</option>
                                                        {CAddressObj.amphure.map((item) => (
                                                            <option value={item.name_th} id={item.id} key={item.id}>{item.name_th}</option>
                                                        ))}
                                                    </select>}
                                                </div>
                                                <div className="input">
                                                    {!canCTambon && <select id="contact_sub_district" name="contact_sub_district" disabled required value={pInfo.present_address.sub_district}>
                                                        <option value="default" disabled>กรุณาเลือกอำเภอ / เขต</option>
                                                    </select>}
                                                    {canCTambon && <select id="contact_sub_district" name="contact_sub_district" onChange={(e) => onInputChange(e)} required value={pInfo.present_address.sub_district}>
                                                        <option value="default" disabled>ตำบล / แขวง</option>
                                                        {CAddressObj.amphure.find(element => element.name_th === pInfo.present_address.district).tambon.map((item) => (
                                                            <option value={item.name_th} id={item.id} key={item.id}>{item.name_th}</option>
                                                        ))}
                                                    </select>}
                                                </div>
                                                <div className="input">
                                                    <input type="text" placeholder="ที่อยู่" name="contact_house_no" value={pInfo.present_address.house_no} onChange={(e) => onInputChange(e)} required />
                                                </div>
                                                {/* <div className="input">
                                                    <input type="text" placeholder="หมายเลขโทรศัพท์​" name="contact_tel" value={pInfo.present_address.contactTel} onChange={(e) => onInputChange(e)} required />
                                                </div> */}
                                            </div>}
                                        </div>
                                        <div className="section">
                                            <p>ติดต่อ</p>
                                            <div className="input">
                                                <input type="text" placeholder="เบอร์ติดต่อ" name="phone_number" value={pInfo.phone_number} onChange={(e) => onInputChange(e)} required />
                                            </div>
                                            <div className="input">
                                                <input type="email" placeholder="อีเมล" name="email" value={pInfo.email} onChange={(e) => onInputChange(e)} required />
                                            </div>
                                        </div>
                                        {error && <div className="error-text">
                                            <h4 style={{ margin: '12px 0px' }}>ข้อมูลผิดพลาด โปรดแก้ไขและลองอีกครั้ง</h4>
                                        </div>}
                                        <div className="button-box" style={{ marginTop: '36px' }}>
                                            <div className="button-layout-1">
                                                <button onClick={() => { window.scrollTo(0, 0); setNext(!next); }}>ยกเลิก</button>
                                                <button>ยืนยันการแก้ไข</button>
                                            </div>
                                        </div>
                                    </form>
                                </>}
                        </div>
                        {next && <div className="button-box">
                            <div className="button-layout-2">
                                <button className="button-white" onClick={() => { window.scrollTo(0, 0); setNext(!next); }}>แก้ไข</button>
                                <button className="button-blue" onClick={handlesubmit}>ยืนยัน</button>
                            </div>
                        </div>}
                        {/* <div className="button-box">
                            <div className="button-layout-2">
                                <button className="button-white" onClick={() => setNext(!next)}>แก้ไข</button>
                                <button className="button-blue" onClick={handlesubmit}>ยืนยัน</button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AccountSummary