import React, { useContext, useEffect, useState } from "react";
import CancelModal from "../../components/CancelModal";
import DatePicker from "../../components/DatePicker";
import moment from "moment";
import ErrorOccurred from "../../components/ErrorOccurred";
import JWE from "../../services/jwe/jwe";

import { TextField, Autocomplete } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from "react-device-detect";
import { ThaiAddress } from "../../utils/address";
import { Occupation } from "../../utils/occupation";
import { Business } from "../../utils/business";
import { getStorageData, setStorageData } from "../../utils/storage";
import { PIDFormat, laserCodeFormat } from "../../utils/common";
import { DOPACheck } from "../../services/api/util.service";
import { refreshToken } from "../../services/api/auth.service";
import { AuthContext } from "../../services/auth/AuthContext";

if (isIOS) import("../../assets/styles/ios.scss");
if (isAndroid) import("../../assets/styles/android.scss");

const PersonalAccount = ({ reset, confirm }) => {
  const [next, setNext] = useState(false);
  const [canAmphure, setCanAmphure] = useState(false);
  const [canTambon, setCanTambon] = useState(false);
  const [canCAmphure, setCanCAmphure] = useState(false);
  const [canCTambon, setCanCTambon] = useState(false);
  const [jobETC, setJobETC] = useState(false);
  const [businessTypeETC, setBusinessTypeETC] = useState(false);
  const [contactETC, setContactETC] = useState(false);
  const [addressObj, setAddressObj] = useState({});
  const [CAddressObj, setCAddressObj] = useState({});
  const [pInfo, setPInfo] = useState({
    pid: "",
    pid_laser_code: "",
    phone_number: "",
    email: "",
    prefix: "default",
    prefixEn: "",
    first_name: "",
    last_name: "",
    first_name_en: "",
    last_name_en: "",
    request_by_device: "Web Service",
    birth_date: moment(),
    expire_date: moment(),
    image_base64: "",
    present_address: {
      house_no: "",
      village_no: "",
      road: "",
      sub_district: "default",
      district: "default",
      provide: "default",
      zip_code: "",
      contactTel: "",
    },
    permanent_address: {
      house_no: "",
      village_no: "",
      road: "",
      sub_district: "default",
      district: "default",
      provide: "default",
      zip_code: "",
    },
    company_address: {
      house_no: "",
      village_no: "",
      alley: "",
      sub_district: "default",
      district: "default",
      provide: "default",
      zip_code: "",
      workTel: "",
    },
    job_info: {
      occupation: "default",
      company: "",
      business_type: "default",
      job_position: "",
    },
  });
  const [dob, setDob] = useState(null);
  const [issueDate, setIssueDate] = useState(null);
  const [doe, setDoe] = useState(null);
  const [jobEtcText, setJobEtcText] = useState(false);
  const [businessTypeEtcText, setBusinessTypeEtcText] = useState("");
  const [user, setUser] = useState(getStorageData("userData"));
  const [error, setError] = useState(false);
  const [dopaError, setDopaError] = useState(false);
  const [isDopaDesc, setIsDopaDesc] = useState(false);
  const [dopaDesc, setDopaDesc] = useState("");
  const [dopaErrorCount, setDopaErrorCount] = useState(0);
  const [loginData, setLoginData] = useState(getStorageData("loginData"));

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (dopaErrorCount >= 3) navigate("/support2");
  }, [dopaErrorCount]);

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const findPermanentZipCode = (address) => {
    let zipCode = "";

    const findProvince = ThaiAddress?.find(
      (element) => element?.name_th === address?.province
    );
    const findDistrict = findProvince?.amphure?.find(
      (element) => element.name_th === address?.district
    );
    const findSubDistrict = findDistrict?.tambon?.find(
      (element) =>
        element?.name_th ===
        address?.sub_district
          .replace("ต.", "")
          .replace(/แขวง/i, "")
          .replace(/ข./i, "")
    );

    zipCode = findSubDistrict?.zip_code;

    return zipCode;
  };

  const _findZipcode = (subDistrict, addressType) => {
    let zip_code = undefined;

    if (addressType === "permanent_address") {
      const provideInfo = ThaiAddress?.find(
        (element) => element?.name_th === pInfo?.permanent_address?.provide
      );
      if (provideInfo) {
        try {
          const tambonInfo = provideInfo?.amphure?.find(
            (element) => element.name_th === pInfo?.permanent_address?.district
          );
          const selectTambon = tambonInfo?.tambon?.find(
            (element) => element?.name_th === subDistrict
          );
          zip_code = selectTambon?.zip_code;
        } catch (ex) {
          console.error(ex);
        }
      }
    } else if (addressType === "company_address") {
      const provideInfo = ThaiAddress?.find(
        (element) => element?.name_th === pInfo?.company_address?.provide
      );
      if (provideInfo) {
        try {
          const tambonInfo = provideInfo?.amphure?.find(
            (element) => element.name_th === pInfo?.company_address?.district
          );
          const selectTambon = tambonInfo?.tambon?.find(
            (element) => element?.name_th === subDistrict
          );
          zip_code = selectTambon?.zip_code;
        } catch (ex) {
          console.error(ex);
        }
      }
    } else if (addressType === "present_address") {
      const provideInfo = ThaiAddress?.find(
        (element) => element?.name_th === pInfo?.present_address?.provide
      );
      if (provideInfo) {
        try {
          const tambonInfo = provideInfo?.amphure?.find(
            (element) => element.name_th === pInfo?.present_address?.district
          );
          const selectTambon = tambonInfo?.tambon?.find(
            (element) => element?.name_th === subDistrict
          );
          zip_code = selectTambon?.zip_code;
        } catch (ex) {
          console.error(ex);
        }
      }
    }

    return zip_code;
  };

  useEffect(() => {
    moment.locale("th");

    let userData = getStorageData("userData");
    let userTel = getStorageData("user");

    setUser(userData);
    setDob(
      moment(
        userData.verification_info.ocr_result.date_of_birth_en.replace(".", "")
      )
    );
    setDoe(
      moment(
        userData.verification_info.ocr_result.date_of_expiry_en.replace(".", "")
      )
    );
    setIssueDate(
      moment(
        userData.verification_info.ocr_result.date_of_issue_en.replace(".", "")
      )
    );

    const obj = ThaiAddress.find(
      (element) =>
        element.name_th ===
        userData.verification_info.ocr_result.province.replace("จ.", "")
    );

    obj === undefined
      ? (() => {
          setAddressObj(ThaiAddress);
          setPInfo({
            ...pInfo,
            pid: PIDFormat(
              userData.verification_info.ocr_result.id_number,
              "add"
            ),
            first_name: userData.verification_info.ocr_result.first_name_th,
            last_name: userData.verification_info.ocr_result.last_name_th,
            first_name_en: userData.verification_info.ocr_result.first_name_en,
            last_name_en: userData.verification_info.ocr_result.last_name_en,
            prefix: userData.verification_info.ocr_result.title_th,
            prefixEn: userData.verification_info.ocr_result.title_en,
            birth_date: moment(
              userData.verification_info.ocr_result.date_of_birth_en.replace(
                ".",
                ""
              )
            ).format("YYYY-MM-DD"),
            issue_date: moment(
              userData.verification_info.ocr_result.date_of_issue_en.replace(
                ".",
                ""
              )
            ).format("YYYY-MM-DD"),
            expire_date: moment(
              userData.verification_info.ocr_result.date_of_expiry_en.replace(
                ".",
                ""
              )
            ).format("YYYY-MM-DD"),
            // image_base64: userData.verification_info.pid_base64,
            permanent_address: {
              ...pInfo?.permanent_address,
              provide: "default",
              district: "",
              sub_district: "",
              zip_code: "",
              house_no: userData.verification_info.ocr_result.address_no,
            },
            phone_number: userTel.tel.replaceAll("-", ""),
          });
        })()
      : (() => {
          setAddressObj(obj);
          setPInfo({
            ...pInfo,
            pid: PIDFormat(
              userData.verification_info.ocr_result.id_number,
              "add"
            ),
            first_name: userData.verification_info.ocr_result.first_name_th,
            last_name: userData.verification_info.ocr_result.last_name_th,
            first_name_en: userData.verification_info.ocr_result.first_name_en,
            last_name_en:
              userData.verification_info.ocr_result.middle_last_name_en,
            prefix: userData.verification_info.ocr_result.title_th,
            prefixEn: userData.verification_info.ocr_result.title_en,
            birth_date: moment(
              userData.verification_info.ocr_result.date_of_birth_en.replace(
                ".",
                ""
              )
            ).format("YYYY-MM-DD"),
            issue_date: moment(
              userData.verification_info.ocr_result.date_of_issue_en.replace(
                ".",
                ""
              )
            ).format("YYYY-MM-DD"),
            expire_date: moment(
              userData.verification_info.ocr_result.date_of_expiry_en.replace(
                ".",
                ""
              )
            ).format("YYYY-MM-DD"),
            // image_base64: userData.verification_info.pid_base64,
            permanent_address: {
              ...pInfo?.permanent_address,
              provide: userData.verification_info.ocr_result.province.replace(
                "จ.",
                ""
              ),
              district: userData.verification_info.ocr_result.district.replace(
                "อ.",
                ""
              ),
              sub_district:
                userData.verification_info.ocr_result.sub_district.replace(
                  "ต.",
                  ""
                ),
              zip_code:
                findPermanentZipCode(userData.verification_info.ocr_result) ??
                "",
              house_no: userData.verification_info.ocr_result.address_no,
            },
            phone_number: userTel.tel.replaceAll("-", ""),
          });
          setCanAmphure(true);
          setCanTambon(true);
        })();
  }, []);

  const handlePicker = (newDate, type) => {
    switch (type) {
      case "dob":
        document.getElementById("dob").classList.remove("error");
        document.getElementById("doe").classList.remove("error");
        setDob(newDate);
        setPInfo({ ...pInfo, birth_date: newDate.format("YYYY-MM-DD") });
        break;
      case "doe":
        document.getElementById("dob").classList.remove("error");
        document.getElementById("doe").classList.remove("error");
        setDoe(newDate);
        setPInfo({ ...pInfo, expire_date: newDate.format("YYYY-MM-DD") });
        break;
      case "issueDate":
        document.getElementById("issueDate").classList.remove("error");
        document.getElementById("issueDate").classList.remove("error");
        setIssueDate(newDate);
        setPInfo({ ...pInfo, issue_date: newDate.format("YYYY-MM-DD") });
        break;
      default:
    }
  };

  const onInputChange = (e) => {
    switch (e.target.name) {
      case "pid":
        if (e.target.value.length <= 17) {
          let input = e.target.value.replace(/[^\d\s]/gi, "");
          let format = PIDFormat(
            input,
            e.target.value.length > pInfo?.pid.length ? "add" : "subtract"
          );
          setPInfo({ ...pInfo, pid: format });
        }
        break;
      case "first_name":
        setPInfo({
          ...pInfo,
          first_name: e.target.value.replace(/[^ก-๏\s]/gi, ""),
        });
        break;
      case "last_name":
        setPInfo({
          ...pInfo,
          last_name: e.target.value.replace(/[^ก-๏\s]/gi, ""),
        });
        break;
      case "first_name_en":
        setPInfo({
          ...pInfo,
          first_name_en: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
      case "last_name_en":
        setPInfo({
          ...pInfo,
          last_name_en: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
      case "gender":
        document.getElementById("gender").parentNode.classList.remove("error");
        setPInfo({ ...pInfo, prefix: e.target.value });
        break;
      case "permanent_zip_code":
        setPInfo({
          ...pInfo,
          permanent_address: {
            ...pInfo.permanent_address,
            zip_code: e.target.value.replace(/\D/g, "").slice(0, 5),
          },
        });
        break;
      case "provide":
        setCanAmphure(false);
        setCanTambon(false);
        if (e.target.value !== "default") {
          setAddressObj(
            ThaiAddress?.find((element) => element.name_th === e.target.value)
          );
          next
            ? (() => {
                setPInfo({
                  ...pInfo,
                  company_address: {
                    ...pInfo?.company_address,
                    provide: e.target.value,
                    district: "default",
                    sub_district: "default",
                  },
                });
                document
                  .getElementById("company_province")
                  .parentNode.classList.remove("error");
              })()
            : (() => {
                setPInfo({
                  ...pInfo,
                  permanent_address: {
                    ...pInfo?.permanent_address,
                    provide: e.target.value,
                    district: "default",
                    sub_district: "default",
                  },
                });
                document
                  .getElementById("permanent_province")
                  .parentNode.classList.remove("error");
              })();
          setCanAmphure(true);
        } else {
          next
            ? (() => {
                setPInfo({
                  ...pInfo,
                  company_address: {
                    ...pInfo?.company_address,
                    provide: "default",
                    district: "default",
                    sub_district: "default",
                  },
                });
                document
                  .getElementById("company_province")
                  .parentNode.classList.remove("error");
              })()
            : (() => {
                setPInfo({
                  ...pInfo,
                  permanent_address: {
                    ...pInfo?.permanent_address,
                    provide: "default",
                    district: "default",
                    sub_district: "default",
                  },
                });
                document
                  .getElementById("permanent_province")
                  .parentNode.classList.remove("error");
              })();
        }
        break;
      case "district":
        setCanTambon(false);
        next
          ? (() => {
              setPInfo({
                ...pInfo,
                company_address: {
                  ...pInfo?.company_address,
                  district: e.target.value,
                  sub_district: "default",
                },
              });
              document
                .getElementById("company_district")
                .parentNode.classList.remove("error");
            })()
          : (() => {
              setPInfo({
                ...pInfo,
                permanent_address: {
                  ...pInfo?.permanent_address,
                  district: e.target.value,
                  sub_district: "default",
                },
              });
              document
                .getElementById("permanent_district")
                .parentNode.classList.remove("error");
            })();
        setCanTambon(true);
        break;
      case "sub_district":
        next
          ? (() => {
              const zipcode = _findZipcode(e.target.value, "company_address");
              setPInfo({
                ...pInfo,
                company_address: {
                  ...pInfo?.company_address,
                  sub_district: e.target.value,
                  zip_code: zipcode,
                },
              });
              document
                .getElementById("company_sub_district")
                .parentNode.classList.remove("error");
            })()
          : (() => {
              try {
                const zipcode = _findZipcode(
                  e.target.value,
                  "permanent_address"
                );
                setPInfo({
                  ...pInfo,
                  zip_code: zipcode,
                  permanent_address: {
                    ...pInfo?.permanent_address,
                    sub_district: e.target.value,
                    zip_code: zipcode,
                  },
                });
                document
                  .getElementById("permanent_sub_district")
                  .parentNode.classList.remove("error");
              } catch (ex) {
                console.error(ex);
              }
            })();

        setCanTambon(true);
        break;
      case "house_no":
        next
          ? setPInfo({
              ...pInfo,
              company_address: {
                ...pInfo?.company_address,
                house_no: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/gi, ""),
              },
            })
          : setPInfo({
              ...pInfo,
              permanent_address: {
                ...pInfo?.permanent_address,
                house_no: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/gi, ""),
              },
            });
        break;
      case "pid_laser_code":
        if (e.target.value.length <= 14) {
          let input = e.target.value
            .replace(/[^\d-A-Za-z]/gi, "")
            .toUpperCase();
          let format = laserCodeFormat(
            input,
            e.target.value.length > pInfo?.pid_laser_code.length
              ? "add"
              : "subtract"
          );
          setPInfo({ ...pInfo, pid_laser_code: format });
        }
        break;
      case "job":
        document.getElementById("job").parentNode.classList.remove("error");
        if (e.target.value === "etc") setJobETC(true);
        else {
          setPInfo({
            ...pInfo,
            job_info: { ...pInfo?.job_info, occupation: e.target.value },
          });
          setJobETC(false);
        }
        setPInfo({
          ...pInfo,
          job_info: { ...pInfo?.job_info, occupation: e.target.value },
        });
        break;
      case "job_etc":
        setJobEtcText(e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/gi, ""));
        break;
      case "company":
        setPInfo({
          ...pInfo,
          job_info: {
            ...pInfo?.job_info,
            company: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/gi, ""),
          },
        });
        break;
      case "business_type":
        document
          .getElementById("business_type")
          .parentNode.classList.remove("error");
        e.target.value === "อื่นๆ"
          ? setBusinessTypeETC(true)
          : setBusinessTypeETC(false);
        setPInfo({
          ...pInfo,
          job_info: { ...pInfo?.job_info, business_type: e.target.value },
        });
        break;
      case "business_type_etc":
        setBusinessTypeEtcText(
          e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/gi, "")
        );
        break;
      case "role":
        setPInfo({
          ...pInfo,
          job_info: {
            ...pInfo?.job_info,
            job_position: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/gi, ""),
          },
        });
        break;
      case "work_tel":
        if (e.target.value.length <= 10)
          setPInfo({
            ...pInfo,
            company_address: {
              ...pInfo?.company_address,
              workTel: e.target.value.replace(/[^\d]/gi, ""),
            },
          });
        break;
      case "select_present_address":
        document
          .getElementById("select_present_address")
          .parentNode.classList.remove("error");
        if (e.target.value === "permanent_address") {
          setPInfo({ ...pInfo, permanent_address: pInfo.permanent_address });
          setPInfo({ ...pInfo, present_address: pInfo.permanent_address });
          setContactETC(false);
        } else if (e.target.value === "company_address") {
          setPInfo({ ...pInfo, permanent_address: pInfo.company_address });
          setPInfo({ ...pInfo, present_address: pInfo.company_address });
          setContactETC(false);
        } else if (e.target.value === "etc") {
          setPInfo({
            ...pInfo,
            present_address: {
              house_no: "",
              village_no: "",
              road: "",
              sub_district: "default",
              district: "default",
              provide: "default",
              zip_code: "",
              contactTel: "",
            },
          });
          setContactETC(true);
        } else {
          setPInfo({ ...pInfo, permanent_address: null });
          setContactETC(false);
          setCanCAmphure(false);
          setCanCTambon(false);
        }
        break;
      case "contact_province":
        setCanCAmphure(false);
        setCanCTambon(false);
        setCAddressObj(
          ThaiAddress?.find((element) => element.name_th === e.target.value)
        );
        setPInfo({
          ...pInfo,
          present_address: {
            ...pInfo?.present_address,
            provide: e.target.value,
            district: "default",
            sub_district: "default",
            zip_code: "",
          },
        });
        document
          .getElementById("contact_province")
          .parentNode.classList.remove("error");
        setCanCAmphure(true);
        break;
      case "contact_district":
        setCanCTambon(false);
        setPInfo({
          ...pInfo,
          present_address: {
            ...pInfo?.present_address,
            district: e.target.value,
            sub_district: "default",
            zip_code: "",
          },
        });
        document
          .getElementById("contact_district")
          .parentNode.classList.remove("error");
        setCanCTambon(true);
        break;
      case "contact_sub_district":
        const zipcode = _findZipcode(e.target.value, "present_address");
        setPInfo({
          ...pInfo,
          present_address: {
            ...pInfo?.present_address,
            sub_district: e.target.value,
            zip_code: zipcode,
          },
        });
        document
          .getElementById("contact_sub_district")
          .parentNode.classList.remove("error");
        setCanCTambon(true);
        break;
      case "contact_house_no":
        setPInfo({
          ...pInfo,
          present_address: {
            ...pInfo?.present_address,
            house_no: e.target.value.replace(/[^ก-๏\s\d-/()A-Za-z]/gi, ""),
          },
        });
        break;
      case "contact_tel":
        if (e.target.value.length <= 10)
          setPInfo({
            ...pInfo,
            present_address: {
              ...pInfo?.present_address,
              contactTel: e.target.value.replace(/[^\d]/gi, ""),
            },
          });
        break;
      case "phone_number":
        if (e.target.value.length <= 10)
          setPInfo({
            ...pInfo,
            phone_number: e.target.value.replace(/[^\d]/gi, ""),
          });
        break;
      case "email":
        setPInfo({
          ...pInfo,
          email: e.target.value.replace(/[^a-z@\d.-_]/gi, ""),
        });
        break;
      default:
    }
  };

  const DopaCheck = async () => {
    const payloadVerifyDOPA = {
      pid: pInfo?.pid.replaceAll(" ", ""),
      first_name: pInfo?.first_name,
      last_name: pInfo?.last_name,
      birthday: moment(pInfo?.birth_date, "YYYY-MM-DD")
        .add(543, "years")
        .format("YYYYMMDD"),
      laser: pInfo?.pid_laser_code.replaceAll("-", ""),
    };
    const dopa = await DOPACheck(payloadVerifyDOPA);
    if (dopa && dopa.code === 0) {
      //dec
      setCanAmphure(false);
      setCanTambon(false);
      setError(false);
      setNext(true);
    } else if (dopa && dopa.code !== 0) {
      setDopaDesc(dopa.desc);
      setDopaErrorCount(dopaErrorCount + 1);
      setIsDopaDesc(true);
    } else {
      // refresh token
      if (dopa.detail === "Signature has expired") {
        const newToken = await refreshToken(loginData.uuid);
        if (newToken.access_token) {
          localStorage.setItem("access_token", newToken.access_token);
          localStorage.setItem("refresh_token", newToken.access_token);
          setStorageData("loginData", {
            ...loginData,
            token: { access_token: newToken.access_token },
          });
          setLoginData({
            ...loginData,
            token: { access_token: newToken.access_token },
          });
          return;
        } else {
          dispatch({ type: "LOGOUT" });
        }
      } else {
        setDopaError(true);
        return;
      }
    }
  };

  function addErrorClassIf(condition, elementId) {
    if (condition) {
      const element = document.getElementById(elementId);
      if (element) {
        element.parentNode.classList.add("error");
      }
    }
  }

  const handleSubmit = (event, step) => {
    switch (step) {
      case "1":
        if (
          pInfo?.prefix === "default" ||
          pInfo?.permanent_address.provide === "default" ||
          pInfo?.permanent_address.district === "default" ||
          pInfo?.permanent_address.sub_district === "default" ||
          moment(pInfo?.birth_date, "YYYY-MM-DD").valueOf() >=
            moment(pInfo?.expire_date, "YYYY-MM-DD").valueOf()
        ) {
          setError(true);
          event.preventDefault();

          addErrorClassIf(pInfo?.prefix === "default", "gender");
          addErrorClassIf(
            pInfo?.permanent_address.provide === "default",
            "permanent_province"
          );
          addErrorClassIf(
            pInfo?.permanent_address.district === "default",
            "permanent_district"
          );
          addErrorClassIf(
            pInfo?.permanent_address.sub_district === "default",
            "permanent_sub_district"
          );

          const birthDateValid =
            moment(pInfo?.birth_date, "YYYY-MM-DD").valueOf() <
            moment(pInfo?.expire_date, "YYYY-MM-DD").valueOf();
          const currentDate = moment(); // Get the current date
          const expireDate = moment(pInfo?.expire_date, "YYYY-MM-DD"); // Parse the expire_date from pInfo
          const isDateValid = currentDate.isBefore(expireDate);

          addErrorClassIf(!birthDateValid, "dob");
          addErrorClassIf(!birthDateValid, "doe");
          addErrorClassIf(!isDateValid, "doe");
        } else {
          setPInfo({ ...pInfo, present_address: pInfo.permanent_address });
          event.preventDefault();
          DopaCheck();
        }
        break;
      case "2":
        if (
          pInfo?.job_info.occupation === "default" ||
          pInfo?.job_info.business_type === "default" ||
          pInfo?.company_address.provide === "default" ||
          pInfo?.company_address.district === "default" ||
          pInfo?.company_address.sub_district === "default" ||
          document.getElementById("select_present_address").value ===
            "default" ||
          pInfo?.present_address.provide === "default" ||
          pInfo?.present_address.district === "default" ||
          pInfo?.present_address.sub_district === "default"
        ) {
          setError(true);
          event.preventDefault();
          addErrorClassIf(pInfo?.job_info.occupation === "default", "job");
          addErrorClassIf(
            pInfo?.job_info.business_type === "default",
            "business_type"
          );
          addErrorClassIf(
            pInfo?.company_address.provide === "default",
            "company_province"
          );
          addErrorClassIf(
            pInfo?.company_address.district === "default",
            "company_district"
          );
          addErrorClassIf(
            pInfo?.company_address.sub_district === "default",
            "company_sub_district"
          );
          addErrorClassIf(
            document.getElementById("present_address").value === "default",
            "present_address"
          );
          addErrorClassIf(
            pInfo?.present_address?.provide === "default",
            "contact_province"
          );
          addErrorClassIf(
            pInfo?.present_address?.district === "default",
            "contact_district"
          );
          addErrorClassIf(
            pInfo?.present_address?.sub_district === "default",
            "contact_sub_district"
          );
        } else {
          event.preventDefault();
          let data = {
            ...pInfo,
            pid: pInfo?.pid.replaceAll(" ", ""),
          };
          delete data.present_address.contactTel;
          delete data.company_address.workTel;
          if (jobETC) data.job_info.occupation = jobEtcText;
          if (businessTypeETC)
            data.job_info.business_type = businessTypeEtcText;
          let pa = document.getElementById("select_present_address").value;
          if (pa === "permanent_address")
            data.present_address = data.permanent_address;
          if (pa === "company_address")
            data.present_address = {
              ...data.present_address,
              house_no: data.company_address.house_no,
              village_no: data.company_address.village_no,
              sub_district: data.company_address.sub_district,
              district: data.company_address.district,
              provide: data.company_address.provide,
              zip_code: data.company_address.zip_code,
              alley: data.company_address.alley,
            };
          setStorageData("userData", { personal_info: data });

          // console.log('data', data)

          confirm();
          navigate("/ndid");
        }
        break;
      default:
    }
  };

  // SignOut Modal Variables
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const handleLogout = () => {
    reset();
    navigate("/decline-support");
  };
  return (
    <div className={`${isIOS ? "t" : "a"}`}>
      {openModal && (
        <CancelModal
          open={openModal}
          logout={handleLogout}
          handleClose={handleClose}
          note={"CANCEL"}
          status={"ยกเลิกการกรอกข้อมูลส่วนตัว"}
        />
      )}
      {dopaError && (
        <ErrorOccurred
          open={dopaError}
          handleClose={() => {
            setDopaError(false);
          }}
        >
          <div>เกิดข้อผิดพลาด</div>
          <div>กรุณาลองใหม่อีกครั้ง</div>
        </ErrorOccurred>
      )}
      {isDopaDesc && (
        <ErrorOccurred
          open={isDopaDesc}
          handleClose={() => {
            setIsDopaDesc(false);
          }}
        >
          <div>{dopaDesc}</div>
          <div>แก้ไขข้อมูลได้อีก {3 - dopaErrorCount} ครั้ง</div>
        </ErrorOccurred>
      )}
      <div className={`${isIOS ? "t-container" : "a-container"}`}>
        {!next && (
          <>
            <div
              className={`${isIOS ? "header-box" : "android-header-box-10vh"}`}
            >
              <div className="header-layout-1">
                <div>&nbsp;</div>
                <div>
                  <p>ข้อมูลส่วนตัว</p>
                </div>
                <div>(1/2)</div>
              </div>
            </div>
            <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
              <div className="content-layout-4">
                <form
                  name="form1"
                  onSubmit={(event) => handleSubmit(event, "1")}
                >
                  <div className="user-img">
                    <img
                      src={user?.verification_info?.pid_src}
                      alt="snapshot"
                    />
                  </div>
                  <div className="section">
                    <p>หมายเลขบัตรประชาชน</p>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="กรุณากรอกหมายเลขบัตรประชาชน"
                        name="pid"
                        value={pInfo?.pid}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>คำนำหน้า</p>
                    <div>
                      <Autocomplete
                        freeSolo
                        options={[
                          { label: "นาย", value: "นาย" },
                          { label: "นาง", value: "นาง" },
                          { label: "นางสาว", value: "นางสาว" },
                          { label: "เด็กชาย", value: "เด็กชาย" },
                          { label: "เด็กหญิง", value: "เด็กหญิง" },
                          { label: "ยศ", value: "ยศ" },
                        ]}
                        value={pInfo?.prefix}
                        required
                        onInputChange={(event, newInputValue) => {
                          setPInfo({ ...pInfo, prefix: newInputValue });
                        }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref} className="input">
                            <input type="text" {...params.inputProps} />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>ชื่อ-นามสกุล (ไทย)</p>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="ชื่อ"
                        name="first_name"
                        value={pInfo?.first_name}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="นามสกุล"
                        name="last_name"
                        value={pInfo?.last_name}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>คำนำหน้า (อังกฤษ)</p>
                    <div>
                      <Autocomplete
                        freeSolo
                        options={[
                          { label: "Mr", value: "Mr" },
                          { label: "Mrs", value: "Mrs" },
                          { label: "Miss", value: "Miss" },
                        ]}
                        value={pInfo?.prefixEn}
                        required
                        onInputChange={(event, newInputValue) => {
                          setPInfo({ ...pInfo, prefixEn: newInputValue });
                        }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref} className="input">
                            <input type="text" {...params.inputProps} />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>ชื่อ-นามสกุล (อังกฤษ)</p>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="ชื่อ"
                        name="first_name_en"
                        value={pInfo?.first_name_en}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="นามสกุล"
                        name="last_name_en"
                        value={pInfo?.last_name_en}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="section">
                    <p>วันเดือนปีเกิด</p>
                    <div className="input" id="dob">
                      <DatePicker
                        value={dob}
                        onChange={(e) => handlePicker(e, "dob")}
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>วันออกบัตร</p>
                    <div className="input" id="issueDate">
                      <DatePicker
                        value={issueDate}
                        onChange={(e) => handlePicker(e, "issueDate")}
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>วันหมดอายุบัตร</p>
                    <div className="input" id="doe">
                      <DatePicker
                        value={doe}
                        onChange={(e) => handlePicker(e, "doe")}
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>ที่อยู่ตามบัตรประชาชน</p>
                    <div className="input">
                      <select
                        id="permanent_province"
                        name="provide"
                        onChange={(e) => onInputChange(e)}
                        required
                        value={pInfo?.permanent_address?.provide}
                      >
                        <option value="default" disabled>
                          จังหวัด
                        </option>
                        {ThaiAddress.map((item) => (
                          <option
                            value={item.name_th}
                            id={item.id}
                            key={item.id}
                          >
                            {item.name_th}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="input">
                      {!canAmphure && (
                        <select
                          id="permanent_district"
                          disabled
                          required
                          value={pInfo?.permanent_address?.district}
                        >
                          <option value="default" disabled>
                            กรุณาเลือกจังหวัด
                          </option>
                        </select>
                      )}
                      {canAmphure && (
                        <select
                          id="permanent_district"
                          name="district"
                          onChange={(e) => onInputChange(e)}
                          required
                          value={pInfo?.permanent_address.district}
                        >
                          <option value="default" disabled>
                            อำเภอ / เขต
                          </option>
                          {addressObj?.amphure?.map((item) => (
                            <option
                              value={item.name_th}
                              id={item.id}
                              key={item.id}
                            >
                              {item.name_th}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    <div className="input">
                      {!canTambon && (
                        <select
                          id="permanent_sub_district"
                          disabled
                          required
                          value={pInfo?.permanent_address.sub_district}
                        >
                          <option value="default" disabled>
                            กรุณาเลือกอำเภอ / เขต
                          </option>
                        </select>
                      )}
                      {canTambon && (
                        <select
                          id="permanent_sub_district"
                          name="sub_district"
                          onChange={(e) => onInputChange(e)}
                          required
                          value={pInfo?.permanent_address.sub_district}
                        >
                          <option value="default" disabled>
                            ตำบล / แขวง
                          </option>
                          {addressObj?.amphure
                            ?.find(
                              (element) =>
                                element.name_th ===
                                pInfo?.permanent_address.district
                            )
                            .tambon.map((item) => (
                              <option
                                value={item.name_th}
                                id={item.id}
                                key={item.id}
                              >
                                {item.name_th}
                              </option>
                            ))}
                        </select>
                      )}
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="รหัสไปรษณีย์"
                        name="permanent_zip_code"
                        value={pInfo?.permanent_address.zip_code}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="ที่อยู่"
                        name="house_no"
                        value={pInfo?.permanent_address.house_no}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>หมายเลขหลังบัตรประชาชน (Laser code)</p>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="กรุณากรอกหมายเลขหลังบัตรประชาชน"
                        name="pid_laser_code"
                        value={pInfo?.pid_laser_code}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                  </div>
                  {error && (
                    <div className="error-text">
                      <h4>ข้อมูลผิดพลาด โปรดแก้ไขและลองอีกครั้ง</h4>
                    </div>
                  )}
                  <div className="button-box">
                    <div
                      className="button-layout-1"
                      style={{ marginTop: "32px" }}
                    >
                      <button onClick={handleOpen}>ปฎิเสธ</button>
                      <button
                        type="submit"
                        disabled={pInfo?.pid_laser_code.length < 14}
                      >
                        ยอมรับ
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
        {next && (
          <>
            <div
              className={`${isIOS ? "header-box" : "android-header-box-10vh"}`}
            >
              <div className="header-layout-1">
                <div>&nbsp;</div>
                <div>
                  <p>ข้อมูลส่วนตัว</p>
                </div>
                <div>(2/2)</div>
              </div>
            </div>
            <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
              <div className="content-layout-4">
                <form
                  name="form2"
                  onSubmit={(event) => handleSubmit(event, "2")}
                >
                  <div className="section">
                    <p>อาชีพ</p>
                    <div className="input">
                      <select
                        id="job"
                        name="job"
                        onChange={(e) => onInputChange(e)}
                        required
                        value={pInfo?.job_info.occupation}
                      >
                        <option value="default" disabled>
                          กรุณาเลือกอาชีพ
                        </option>
                        {Occupation.map((item) => (
                          <option value={item.title} id={item.id} key={item.id}>
                            {item.title}
                          </option>
                        ))}
                        <option value="etc">อื่นๆ</option>
                      </select>
                    </div>
                    {jobETC && (
                      <div className="input">
                        <input
                          type="text"
                          name="job_etc"
                          placeholder="โปรดระบุ..."
                          onChange={(e) => onInputChange(e)}
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div className="section">
                    <p>ที่ทำงาน</p>
                    <div className="input">
                      <input
                        type="text"
                        name="company"
                        placeholder="ชื่อที่ทำงาน"
                        value={pInfo?.job_info.company}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                    <div className="input">
                      <select
                        id="business_type"
                        name="business_type"
                        onChange={(e) => onInputChange(e)}
                        required
                        value={pInfo?.job_info.business_type}
                      >
                        <option value="default" disabled>
                          ประเภทธุรกิจ
                        </option>
                        {Business.map((item) => (
                          <option value={item.title} id={item.id} key={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    {businessTypeETC && (
                      <div className="input">
                        <input
                          type="text"
                          name="business_type_etc"
                          placeholder="โปรดระบุ..."
                          onChange={(e) => onInputChange(e)}
                          required
                        />
                      </div>
                    )}

                    <div className="input">
                      <input
                        type="text"
                        name="role"
                        placeholder="ตำแหน่ง"
                        value={pInfo?.job_info.job_position}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>สภานที่ทำงาน</p>
                    <div className="input">
                      <select
                        id="company_province"
                        name="provide"
                        onChange={(e) => onInputChange(e)}
                        required
                        value={pInfo?.company_address.provide}
                      >
                        <option value="default" disabled>
                          จังหวัด
                        </option>
                        {ThaiAddress.map((item) => (
                          <option
                            value={item.name_th}
                            id={item.id}
                            key={item.id}
                          >
                            {item.name_th}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="input">
                      {!canAmphure && (
                        <select
                          id="company_district"
                          name="district"
                          disabled
                          required
                          value={pInfo?.company_address.district}
                        >
                          <option value="default" disabled>
                            กรุณาเลือกจังหวัด
                          </option>
                        </select>
                      )}
                      {canAmphure && (
                        <select
                          id="company_district"
                          name="district"
                          onChange={(e) => onInputChange(e)}
                          required
                          value={pInfo?.company_address.district}
                        >
                          <option value="default" disabled>
                            อำเภอ / เขต
                          </option>
                          {addressObj?.amphure?.map((item) => (
                            <option
                              value={item.name_th}
                              id={item.id}
                              key={item.id}
                            >
                              {item.name_th}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    <div className="input">
                      {!canTambon && (
                        <select
                          id="company_sub_district"
                          name="sub_district"
                          disabled
                          required
                          value={pInfo?.company_address.sub_district}
                        >
                          <option value="default" disabled>
                            กรุณาเลือกอำเภอ / เขต
                          </option>
                        </select>
                      )}
                      {canTambon && (
                        <select
                          id="company_sub_district"
                          name="sub_district"
                          onChange={(e) => onInputChange(e)}
                          required
                          value={pInfo?.company_address.sub_district}
                        >
                          <option value="default" disabled>
                            ตำบล / แขวง
                          </option>
                          {addressObj?.amphure
                            ?.find(
                              (element) =>
                                element.name_th ===
                                pInfo?.company_address.district
                            )
                            .tambon.map((item) => (
                              <option
                                value={item.name_th}
                                id={item.id}
                                key={item.id}
                              >
                                {item.name_th}
                              </option>
                            ))}
                        </select>
                      )}
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="รหัสไปรษณีย์"
                        name="zip_code"
                        value={pInfo?.company_address.zip_code}
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="ที่อยู่"
                        name="house_no"
                        value={pInfo?.company_address.house_no}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="หมายเลขโทรศัพท์​"
                        name="work_tel"
                        value={pInfo?.company_address.workTel}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="section">
                    <p>สถานที่สะดวกในการติดต่อ</p>
                    <div className="input">
                      <select
                        id="select_present_address"
                        name="select_present_address"
                        onChange={(e) => onInputChange(e)}
                        required
                      >
                        <option value="default" disabled>
                          กรุณาเลือกสถานที่สะดวกในการติดต่อ
                        </option>
                        <option value="permanent_address">
                          ตามทะเบียนบ้าน
                        </option>
                        <option value="company_address">สถานที่ทำงาน</option>
                        <option value="etc">อื่นๆ</option>
                      </select>
                    </div>
                    {contactETC && (
                      <div className="section">
                        <div className="input">
                          <select
                            id="contact_province"
                            name="contact_province"
                            onChange={(e) => onInputChange(e)}
                            required
                            value={pInfo?.present_address.provide}
                          >
                            <option value="default" disabled>
                              จังหวัด
                            </option>
                            {ThaiAddress.map((item) => (
                              <option
                                value={item.name_th}
                                id={item.id}
                                key={item.id}
                              >
                                {item.name_th}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="input">
                          {!canCAmphure && (
                            <select
                              id="contact_district"
                              name="contact_district"
                              disabled
                              required
                              value={pInfo?.present_address.district}
                            >
                              <option value="default" disabled>
                                กรุณาเลือกจังหวัด
                              </option>
                            </select>
                          )}
                          {canCAmphure && (
                            <select
                              id="contact_district"
                              name="contact_district"
                              onChange={(e) => onInputChange(e)}
                              required
                              value={pInfo?.present_address.district}
                            >
                              <option value="default" disabled>
                                อำเภอ / เขต
                              </option>
                              {CAddressObj?.amphure?.map((item) => (
                                <option
                                  value={item.name_th}
                                  id={item.id}
                                  key={item.id}
                                >
                                  {item.name_th}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                        <div className="input">
                          {!canCTambon && (
                            <select
                              id="contact_sub_district"
                              name="contact_sub_district"
                              disabled
                              required
                              value={pInfo?.present_address.sub_district}
                            >
                              <option value="default" disabled>
                                กรุณาเลือกอำเภอ / เขต
                              </option>
                            </select>
                          )}
                          {canCTambon && (
                            <select
                              id="contact_sub_district"
                              name="contact_sub_district"
                              onChange={(e) => onInputChange(e)}
                              required
                              value={pInfo?.present_address.sub_district}
                            >
                              <option value="default" disabled>
                                ตำบล / แขวง
                              </option>
                              {CAddressObj?.amphure
                                ?.find(
                                  (element) =>
                                    element.name_th ===
                                    pInfo?.present_address.district
                                )
                                ?.tambon.map((item) => (
                                  <option
                                    value={item.name_th}
                                    id={item.id}
                                    key={item.id}
                                  >
                                    {item.name_th}
                                  </option>
                                ))}
                            </select>
                          )}
                        </div>
                        <div className="input">
                          <input
                            type="text"
                            placeholder="รหัสไปรษณีย์"
                            name="zip_code"
                            value={pInfo?.present_address.zip_code}
                            required
                          />
                        </div>
                        <div className="input">
                          <input
                            type="text"
                            placeholder="ที่อยู่"
                            name="contact_house_no"
                            value={pInfo?.present_address.house_no}
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                        <div className="input">
                          <input
                            type="text"
                            placeholder="หมายเลขโทรศัพท์​"
                            name="contact_tel"
                            value={pInfo?.present_address.contactTel}
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="section">
                    <p>ติดต่อ</p>
                    <div className="input">
                      <input
                        type="text"
                        placeholder="เบอร์ติดต่อ"
                        name="phone_number"
                        value={pInfo?.phone_number}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        type="email"
                        placeholder="อีเมล"
                        name="email"
                        value={pInfo?.email}
                        onChange={(e) => onInputChange(e)}
                        required
                      />
                    </div>
                  </div>
                  {error && (
                    <div className="error-text">
                      <h4 style={{ margin: "12px 0px" }}>
                        ข้อมูลผิดพลาด โปรดแก้ไขและลองอีกครั้ง
                      </h4>
                    </div>
                  )}
                  <div className="button-box">
                    <div className="button-layout-1">
                      <button onClick={handleOpen}>ปฎิเสธ</button>
                      <button>ยอมรับ</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalAccount;
