export const getStorageData = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

export const setStorageData = (key, data) => {
    let originData = JSON.parse(localStorage.getItem(key));
    if (originData !== null) {
        localStorage.removeItem(key);
        let newData = Object.assign(originData, data);
        localStorage.setItem(key, JSON.stringify(newData));
    } else {
        localStorage.setItem(key, JSON.stringify(data));
    }
}

export const removeStorageData = (key) => {
    localStorage.removeItem(key);
}