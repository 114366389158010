export const Occupation = [
    {
        "id": 1,
        "title": "เกษตรกร"
    },
    {
        "id": 2,
        "title": "นักเรียน/นักศึกษา"
    },
    {
        "id": 3,
        "title": "พนักงานรัฐวิสาหกิจ"
    },
    {
        "id": 4,
        "title": "แพทย์/พยาบาล"
    },
    {
        "id": 5,
        "title": "ข้าราชการ"
    },
    {
        "id": 6,
        "title": "นักลงทุน"
    },
    {
        "id": 7,
        "title": "พระภิกษุ/นักบวช"
    },
    {
        "id": 8,
        "title": "พนักงานบริษัท"
    },
    {
        "id": 9,
        "title": "ครู/อาจารย์"
    },
    {
        "id": 10,
        "title": "เจ้าของกิจการ/ธุรกิจส่วนตัว"
    },
    {
        "id": 11,
        "title": "แม่บ้าน/พ่อบ้าน"
    },
    {
        "id": 12,
        "title": "เกษียณอายุ"
    },
    {
        "id": 13,
        "title": "กิจการครอบครัว"
    },
    {
        "id": 14,
        "title": "นักการเมือง"
    },
    {
        "id": 15,
        "title": "อาชีพอิสระ"
    },
];