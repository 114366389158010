import React from "react";
import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from "react-router-dom";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const style = {
    box: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        margin: "16px"
    },
    heading: {
        color: "#373737",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "normal",
    },
    text: {
        color: "#000",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
    },
    box2: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        margin: "12px"
    },
    desc: {
        color: "#7D7D7D",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
    },
    arrow: {
        marginLeft: "16px"
    },
    hr: {
        width: "100%"
    },
}

const QaNdid = () => {
    const navigate = useNavigate();
    return (
        <div className={`${isIOS ? "t" : "a"}`}>
            <div className={`${isIOS ? "t-container" : "a-container"}`}>
                <div className={`${isIOS ? "header-box" : "android-header-box-10vh"}`}>
                    <div className="header-layout-1">
                        <div onClick={() => navigate("/faq")}>
                            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.968751 12.0312C0.359376 11.4687 0.359376 10.4844 0.968751 9.92187L9.96875 0.921874C10.5313 0.312499 11.5156 0.3125 12.0781 0.921875C12.6875 1.48437 12.6875 2.46875 12.0781 3.03125L4.15625 11L12.0781 18.9219C12.6875 19.4844 12.6875 20.4687 12.0781 21.0312C11.5156 21.6406 10.5313 21.6406 9.96875 21.0312L0.968751 12.0312Z" fill="white" />
                            </svg>
                        </div>
                        <div>
                            <p>คำถามที่พบบ่อย</p>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
                    <div className="faq">
                        <div style={style.box}>
                            <p style={style.heading}>การยืนยัน NDID</p>
                        </div>
                        <div style={style.box}>
                            <p style={style.text}>เป็นบริการยืนยันตัวตนรูปแบบดิจิทัล ที่สามารถทำธุรกรรมออนไลน์ต่างๆ เช่น การเปิดบัญชีเงินฝากออนไลน์ การสมัครขอสินเชื่อออนไลน์ เป็นต้น โดยไม่ต้องเดินทางไปที่สาขา หรือสำนักงาน เพื่อทำการแสดงตนสำหรับสมัครบริการ</p>
                        </div>

                        <div style={{ width: "100%", margin: "16px" }}>
                            <p style={style.desc}>คู่มือของผู้ให้บริการ NDID</p>
                        </div>

                        <div style={style.box2} onClick={() => window.open("https://www.kasikornbank.com/th/personal/account/pages/ndid.aspx")}>
                            <div>
                                <p style={style.text}>ธนาคารกสิกรไทย</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box2} onClick={() => window.open("https://www.scb.co.th/th/personal-banking/other-services/ndid.html")}>
                            <div>
                                <p style={style.text}>ธนาคารไทยพาณิชย์</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box2} onClick={() => window.open("https://www.bangkokbank.com/th-TH/Personal/Digital-Banking/NDID")}>
                            <div>
                                <p style={style.text}>ธนาคารกรุงเทพ</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box2} onClick={() => window.open("https://krungthai.com/th/content/personal/ndid")}>
                            <div>
                                <p style={style.text}>ธนาคารกรุงไทย</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box2} onClick={() => window.open("https://www.krungsri.com/th/personal/banking-services/ndid")}>
                            <div>
                                <p style={style.text}>ธนาคารกรุงศรี</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box2} onClick={() => window.open("https://www.ttbbank.com/th/ttb-touch/userguide/customerservice-verify-ndid")}>
                            <div>
                                <p style={style.text}>ธนาคารทีทีบี</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box2} onClick={() => window.open("https://www.lhbank.co.th/th/personal/service/ndid")}>
                            <div>
                                <p style={style.text}>ธนาคารแลนด์ แอนด์ เฮ้าส์</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box2} onClick={() => window.open("https://www.gsb.or.th/online/gsb-ndid")}>
                            <div>
                                <p style={style.text}>ธนาคารออมสิน</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />

                        <div style={style.box2} onClick={() => window.open("https://bank.kkpfg.com/th/personal-banking/digital-banking/kkp-ndid")}>
                            <div>
                                <p style={style.text}>ธนาคารเกียรตินาคิน</p>
                            </div>
                            <div style={style.arrow}>
                                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.984375 21.3125C0.796875 21.1719 0.703125 20.9844 0.703125 20.7969C0.703125 20.6094 0.796875 20.4219 0.9375 20.2812L9.42188 11.0469L0.9375 1.76562C0.65625 1.48438 0.65625 1.01562 0.984375 0.734375C1.26562 0.453125 1.73438 0.453125 2.01562 0.78125L11.0156 10.5312C11.2969 10.8125 11.2969 11.2344 11.0156 11.5156L2.01562 21.2656C1.73438 21.5938 1.26562 21.5938 0.984375 21.3125Z" fill="#7D7D7D" />
                                </svg>
                            </div>
                        </div>
                        <hr style={style.hr} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QaNdid