const PublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAm1XrcS07JvfSb4hvroi1
hA5B/pbsMhxAj8ifFO6/CO5AsyC85xDc7P+zehBvjLTS0uFxPtFFi6uaAqh7v2Aa
40BcyQ/Upe4CB1y83liebAYNfEcjekU6d2YAAzI+K8aql9/LOO2Z6IiWOMATL5wv
KE5ibGFFnyeFuZmmkkGXNcx09hhpR9naSfmIidjOmlK41w1BwESXocJP4ZHxZBPo
VKLfT2pb60N/304zTyL3drxcKikzwwqpKM3FASnVqv40nF50czUac9Gc+yA14qSb
ofJf78r+3T2Dy49nRu2qULgcdl674j5AqSjsUnm3HOuUTesg+7DmAaBjLTmS3h84
VQIDAQAB
-----END PUBLIC KEY-----`;

export default PublicKey ;