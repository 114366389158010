import React, { useState, useEffect, useContext } from "react";
import SignoutModal from "../../components/SignoutModal";
import LoadScreen from "../../components/LoadScreen";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import HolidayModal from "../../components/ErrorOccurred";

import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from 'react-device-detect';
import { AuthContext } from "../../services/auth/AuthContext";
import { getAccount, deleteAccount } from "../../services/api/account.service";
import { getStorageData, setStorageData, removeStorageData } from "../../utils/storage";
import { passbookFormat } from "../../utils/common";
import { getHoliday } from "../../services/api/util.service"
import { refreshToken } from "../../services/api/auth.service";
import ErrorOccurred from "../../components/ErrorOccurred";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const Home = ({ reset, toNewAccount, toNDIDStatus, bypass }) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [accountNumber, setAccountNumber] = useState("");
    const [toggleEye, setToggleEye] = useState(false);
    const [isHoliday, setIsHoliday] = useState(false);
    const [holidayText, setHolidayText] = useState("");
    const [loginData, setLoginData] = useState(getStorageData("loginData"));
    const [openErrorOccurred, setOpenErrorOccurred] = useState(false);

    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        setLoading(true);
        reset();
        removeStorageData("ndid");
        removeStorageData("stamp");
        if (loginData == null) dispatch({ type: "LOGOUT" });
        if (loginData.token == null) dispatch({ type: "LOGOUT" });
        const result = await getAccount(loginData?.uuid);

        if (result.detail === "Signature has expired") {
            const newToken = await refreshToken(loginData.uuid);
            if (newToken.access_token) {
                localStorage.setItem("access_token", newToken.access_token);
                localStorage.setItem("refresh_token", newToken.access_token);
                setStorageData("loginData", { ...loginData, token: { access_token: newToken.access_token } });
                setLoginData({ ...loginData, token: { access_token: newToken.access_token } });
                setLoading(false);
                setOpenErrorOccurred(true);
            } else {
                dispatch({ type: "LOGOUT" });
            }
        } else {
            if (result.status) {
                if (result.status !== "success") {
                    const ndidInfo = result.ndid !== null && result.ndid.ndid_info ? result.ndid.ndid_info : {};
                    if (result.ndid && result.ndid.timestamp) {
                        result.ndid.timestamp = Number(result.ndid.timestamp) * 1000;
                    }
                    const account = { timestamp: { ...result.ndid, timestamp: result.ndid?.timestamp || "",  ndid_info: ndidInfo } }
                    setStorageData("userData", { account: account });
                    setUser(result);
                    if (result.ndid !== null) {
                        toNDIDStatus();
                        navigate('/ndid-status');
                    }
                    if (result.data.bookbank_info && result.data.bookbank_info.account_status === 'approved') {
                        setAccountNumber(passbookFormat(result.data.bookbank_info.clc_account_number));
                    }
                } else {
                    if (result.data.bookbank_info && result.data.bookbank_info.account_status === 'approved') {
                        setAccountNumber(passbookFormat(result.data.bookbank_info.clc_account_number));
                    }
                    setStorageData("userData", { account: result });
                    setUser(result);
                }
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        // console.log('accountNumber', accountNumber)
        // console.log('user.data', user.data)
        if (accountNumber !== "") {
            if (toggleEye) setAccountNumber(user.data.bookbank_info.clc_account_number);
            else setAccountNumber(passbookFormat(user.data.bookbank_info.clc_account_number));
        }
    }, [toggleEye]);

    const deleteStep = () => {
        try {
            setLoading(true);
            const account = deleteAccount(user.pid);
        } catch (error) {
            console.log(error);
        }
    }


    const checkHolidayStep = async () => {
        setLoading(true);
        const holidayResponse = await getHoliday(loginData?.token.access_token);
        if (holidayResponse.data.is_opened === false) {
            setLoading(false);
            setHolidayText(holidayResponse.data.message);
            setIsHoliday(true);
        } else {
            toNewAccount();
            navigate('/new-account');
        }
    }

    // Signout Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleLogout = () => { removeStorageData("loginData"); dispatch({ type: "LOGOUT" }); };
    return (
        <div className="t" >
            {openModal && <SignoutModal open={openModal} logout={handleLogout} handleClose={handleClose} />}
            {loading && <LoadScreen />}
            {isHoliday && <HolidayModal open={isHoliday} handleClose={() => { setIsHoliday(false); toNewAccount(); navigate('/new-account'); }} >
                <div>{holidayText}</div>
            </HolidayModal>}
            {/* <div className="popup" id="popup1">
                <button onClick={() => { document.getElementById("popup1").remove(); }}>X</button>
                <button onClick={dev}>Bypass</button>
            </div> */}
            {openErrorOccurred && <ErrorOccurred open={openErrorOccurred} handleClose={() => { setOpenErrorOccurred(false); }} >
                <div>มีบางอย่างผิดพลาด</div>
                <div>กรุณา Refresh และลองอีกครั้ง</div>
            </ErrorOccurred>}
            <div className="t-container">
                <div className="header-box">
                    <div className="header-layout-1">
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <p>บัญชี</p>
                        </div>
                        <div>
                            <button onClick={handleOpen}>
                                <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.1094 10.3984C20.6172 9.92969 20.6172 9.10938 20.1094 8.64062L15.1094 3.64062C14.6406 3.13281 13.8203 3.13281 13.3516 3.64062C12.8438 4.10938 12.8438 4.92969 13.3516 5.39844L16.2031 8.25H8C7.29688 8.25 6.75 8.83594 6.75 9.5C6.75 10.2031 7.29688 10.75 8 10.75H16.2031L13.3516 13.6406C12.8438 14.1094 12.8438 14.9297 13.3516 15.3984C13.8203 15.9062 14.6406 15.9062 15.1094 15.3984L20.1094 10.3984ZM6.75 3.25C7.41406 3.25 8 2.70312 8 2C8 1.33594 7.41406 0.75 6.75 0.75H4.25C2.17969 0.75 0.5 2.42969 0.5 4.5V14.5C0.5 16.5703 2.17969 18.25 4.25 18.25H6.75C7.41406 18.25 8 17.7031 8 17C8 16.3359 7.41406 15.75 6.75 15.75H4.25C3.54688 15.75 3 15.2031 3 14.5V4.5C3 3.83594 3.54688 3.25 4.25 3.25H6.75Z" fill="white" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="content-box">
                    {user.status === 'initial' && <>
                        <div className="content-layout-1">
                            <p>คุณยังไม่มีบัญชี</p>
                            <div className="loggin-button">
                                <button className="button" onClick={() => { checkHolidayStep(); }}>+ เปิดบัญชีใหม่</button>
                            </div>
                        </div>
                    </>}
                    {user.status === 'success' && (user.data.bookbank_info.account_status === 'pending' || user.data.bookbank_info.account_status === 'reviewed') && <>
                        <div className="content-layout-1">
                            <div className="pending-box">
                                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.125 9.875C18.125 8.85938 18.9062 8 20 8C21.0156 8 21.875 8.85938 21.875 9.875V19.5625L28.5156 23.9375C29.375 24.5625 29.6094 25.7344 28.9844 26.5938C28.4375 27.4531 27.2656 27.6875 26.4062 27.0625L18.9062 22.0625C18.4375 21.75 18.125 21.125 18.125 20.5V9.875ZM20 0.5C31.0156 0.5 40 9.48438 40 20.5C40 31.5938 31.0156 40.5 20 40.5C8.90625 40.5 0 31.5938 0 20.5C0 9.48438 8.90625 0.5 20 0.5ZM3.75 20.5C3.75 29.4844 11.0156 36.75 20 36.75C28.9062 36.75 36.25 29.4844 36.25 20.5C36.25 11.5938 28.9062 4.25 20 4.25C11.0156 4.25 3.75 11.5938 3.75 20.5Z" fill="#4065A9" />
                                </svg>
                                <p className="large-text">เรากำลังตรวจสอบข้อมูล</p>
                                <p className="normal-text">และแจ้งผลให้ทราบผ่านทาง SMS</p>
                            </div>
                        </div>
                    </>}
                    {user.status === 'success' && user.data.bookbank_info.account_status === 'approved' && <>
                        <div className="content-layout-3">
                            <div className="account-box">
                                <div style={{ marginTop: "10px"}}>บัญชีของท่านพร้อมใช้งานแล้ว</div>
                                <div className="account-number">
                                    <p>บัญชี {accountNumber}</p>
                                    <button onClick={() => setToggleEye(!toggleEye)}>
                                        <span>
                                            {!toggleEye && <RemoveRedEyeOutlinedIcon fontSize="large" />}
                                            {toggleEye && <VisibilityOffOutlinedIcon fontSize="large" />}
                                        </span>
                                    </button>

                                </div>
                                <div className="account-info">
                                    <p>บัญชีคู่ฝาก {user.data.bookbank_info.bank_name}</p>
                                    <p>{user.data.bookbank_info.account_number}</p>
                                    <p>{user.data.bookbank_info.account_first_name}</p>
                                </div>
                            </div>
                        </div>
                    </>}
                    {user.status === 'success' && user.data.bookbank_info.account_status === 'rejected' && <>
                        <div className="content-layout-1">
                            <p>เปิดบัญชีไม่สำเร็จ</p>
                            <div className="error-dialog">
                                <div className="text">
                                    <p>เนื่องจากคุณไม่ผ่านการอนุมัติ</p>
                                    <p>ติดต่อเจ้าหน้าที่</p>
                                    <p>โทร 02-678-0666 ต่อ 726-728</p>
                                </div>
                                <button className="question" onClick={() => { navigate("/faq"); }}>
                                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.65625 3C10.6875 3 11.5 3.8125 11.5 4.8125C11.5 5.4375 11.1562 6.03125 10.5938 6.375L9.40625 7.09375V7.15625C9.40625 7.46875 9.125 7.75 8.78125 7.75C8.4375 7.75 8.15625 7.46875 8.15625 7.15625V6.71875C8.15625 6.53125 8.25 6.3125 8.46875 6.1875L9.9375 5.3125C10.125 5.21875 10.2188 5.03125 10.2188 4.8125C10.2188 4.5 9.96875 4.25 9.65625 4.25H8.3125C8 4.25 7.75 4.5 7.75 4.8125C7.75 5.15625 7.4375 5.4375 7.125 5.4375C6.78125 5.4375 6.5 5.15625 6.5 4.8125C6.5 3.8125 7.28125 3 8.3125 3H9.65625ZM8.78125 8.375C9.25 8.375 9.59375 8.75 9.59375 9.1875C9.59375 9.65625 9.21875 10 8.78125 10C8.34375 10 8 9.65625 8 9.1875C8 8.75 8.34375 8.375 8.78125 8.375ZM9 0C13.4062 0 16.9688 2.9375 16.9688 6.5C16.9688 10.0938 13.4062 13 9 13C7.96875 13 6.96875 12.8438 6.09375 12.5625C5.15625 13.2188 3.65625 14 1.75 14C1.4375 14 1.15625 13.8438 1.0625 13.5312C0.96875 13.25 1 12.9375 1.21875 12.7188C1.21875 12.7188 2.1875 11.6562 2.65625 10.4375C1.625 9.34375 1 7.96875 1 6.5C1 2.90625 4.5625 0 9 0ZM9 11.5C12.5625 11.5 15.4375 9.28125 15.4375 6.5C15.4375 3.75 12.5312 1.5 8.96875 1.5C5.40625 1.5 2.5 3.75 2.5 6.5C2.5 7.84375 3.15625 8.84375 3.71875 9.4375L4.375 10.125L4.03125 11C3.875 11.4375 3.65625 11.875 3.40625 12.25C4.15625 12 4.78125 11.6562 5.21875 11.3438L5.8125 10.9062L6.53125 11.125C7.3125 11.375 8.15625 11.5 9 11.5Z" fill="white" />
                                    </svg>
                                    &nbsp;&nbsp;คำถามที่พบบ่อย
                                </button>
                            </div>
                        </div>
                        <div className="footer">
                            <p>หรือ</p>
                            <div className="loggin-button">
                                <button className="button" onClick={() => { deleteStep(); checkHolidayStep(); }}>+ เปิดบัญชีใหม่อีกครั้ง</button>
                            </div>
                        </div>
                    </>}
                    {user.status === 'success' && user.data.bookbank_info.account_status !== 'rejected' && <>
                        <div className="footer">
                            <p className="black-text">
                                <span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 0C3.52812 0 0.143219 3.72188 0.00065 8L0 9.6625C0 10.9531 1.02344 12 2.28562 12H2.85719C3.4875 12 4 11.4781 4 10.8313V6.66875C4 6.02187 3.4875 5.5 2.85719 5.5H2.28562C2.18322 5.5 2.08737 5.52234 1.98881 5.53587C2.9625 3.17187 5.29063 1.50625 8 1.50594C10.7106 1.5063 13.0375 3.17344 14.0125 5.50906C13.9125 5.52187 13.8156 5.5 13.7156 5.5H13.1441C12.5125 5.5 12 6.02187 12 6.66875V10.8313C12 11.4781 12.5125 12 13.1438 12H13.7153C13.9927 12 14.255 11.9418 14.5009 11.8494V12.5C14.5009 13.1894 13.9403 13.75 13.2509 13.75H9.79156C9.53125 13.3031 9.05313 13 8.5 13H7.5C6.67188 13 6 13.6719 6 14.5C6 15.3281 6.67188 16 7.47188 16H8.5C9.05375 16 9.53219 15.6965 9.79187 15.25H13.25C14.7666 15.25 16 14.0166 16 12.5V8C15.8562 3.72188 12.4719 0 8 0ZM2.28562 7H2.5V10.5H2.28562C1.8525 10.5 1.5 10.0969 1.5 9.6625V8C1.5 7.92469 1.50873 7.85156 1.51129 7.77684C1.54156 7.34375 1.87219 7 2.28562 7ZM13.7156 10.5H13.5V7H13.7143C14.1277 7 14.4583 7.345 14.4887 7.77688C14.4906 7.85313 14.5 7.925 14.5 8V9.66375C14.5 10.0969 14.1469 10.5 13.7156 10.5Z" fill="#7D7D7D" />
                                    </svg>
                                </span>
                                &nbsp;&nbsp;หากต้องการเปลี่ยนแปลงข้อมูล</p>
                            <p className="black-text">กรุณาติดต่อเจ้าหน้าที่</p>
                            <p className="black-text">โทร 02-678-0666 ต่อ 726-728</p>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    )
}

export default Home