import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from 'react-device-detect';

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const PassbookSuccess = ({ confirm }) => {
    const navigate = useNavigate();

    const handleback = () => {
        confirm();
    }
    return (
        <div className="t">
            <div className="t-container">
                <div className="header-box">
                    <div className="header-layout-2">
                        <p>บัญชี</p>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-layout-2">
                        <div className="header-icon">

                        </div>
                        <div className="center-sec">
                            <svg width="145" height="128" viewBox="0 0 145 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M80.5 12H16.25C14.25 12 12.25 14 12.25 16V112C12.25 114.25 14.25 116 16.25 116H71.5C74.5 120.75 78.25 124.75 82.75 128C82 128 81.25 128 80.5 128H16.5C7.5 128 0.5 121 0.5 112V16C0.5 7.25 7.5 0 16.5 0H80.5C89.25 0 96.5 7.25 96.5 16V49.75C92 51 88 52.75 84.5 55.25V16C84.5 14 82.5 12 80.5 12ZM24.25 38C24.25 34.75 27 32 30.25 32H66.25C69.75 32 72.25 34.75 72.25 38C72.25 41.5 69.75 44 66.25 44H30.25C27 44 24.25 41.5 24.25 38ZM66.25 56C69.75 56 72.25 58.75 72.25 62C72.25 65.5 69.75 68 66.25 68H30.25C27 68 24.25 65.5 24.25 62C24.25 58.75 27 56 30.25 56H66.25ZM42.25 80C45.75 80 48.25 82.75 48.25 86C48.25 89.5 45.75 92 42.25 92H30.25C27 92 24.25 89.5 24.25 86C24.25 82.75 27 80 30.25 80H42.25ZM144.5 92C144.5 112 128.25 128 108.5 128C88.5 128 72.25 112 72.25 92C72.25 72.25 88.5 56 108.5 56C128.25 56 144.5 72.25 144.5 92ZM119.5 81.25L104.5 96.5L97.25 89.25C95.75 87.75 93 87.75 91.5 89.25C90 90.75 90 93.5 91.5 95L101.5 105C103 106.5 105.75 106.5 107.25 105L125.25 87C126.75 85.5 126.75 82.75 125.25 81.25C123.75 79.75 121 79.75 119.5 81.25Z" fill="#4065A9" />
                            </svg>
                            <div className="texts">
                                <p className="large-text" style={{margin: '18px 0px'}}>สมัครสำเร็จ</p>
                                <p className="normal-text" style={{fontSize: '14px'}}>เรากำลังตรวจสอบข้อมูล</p>
                                <p className="normal-text" style={{fontSize: '14px'}}>และแจ้งผลให้ทราบผ่านทาง SMS</p>
                            </div>
                        </div>
                        <div className="button-box">
                            <div className="button-layout-2">
                                <button className="button-blue" onClick={handleback}>ตกลง</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div className="t">
        //     <div className="t-container">
        //         <div className="header-box">
        //             <div className="header-layout-2">
        //                 <p>เปิดบัญชีใหม่</p>
        //             </div>
        //         </div>
        //         <div className="content-box">
        //             <div className="content-layout-2">
        //                 <div className="header-icon">

        //                 </div>
        //                 <div className="center-sec">
        //                     <svg width="170" height="121" viewBox="0 0 170 121" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                         <path d="M119.25 75.1667C122.714 75.1667 125.5 77.9531 125.5 81.4167C125.5 84.8802 122.714 87.6667 119.25 87.6667H73.4167C69.9531 87.6667 67.1667 84.8802 67.1667 81.4167C67.1667 77.9531 69.9531 75.1667 73.4167 75.1667H119.25ZM67.1667 56.4167C67.1667 52.9531 69.9531 50.1667 73.4167 50.1667H119.25C122.714 50.1667 125.5 52.9531 125.5 56.4167C125.5 59.8802 122.714 62.6667 119.25 62.6667H73.4167C69.9531 62.6667 67.1667 59.8802 67.1667 56.4167ZM0.5 37.6667C0.5 28.4609 7.96094 21 17.1667 21H133.833C143.026 21 150.5 28.4609 150.5 37.6667V104.333C150.5 113.526 143.026 121 133.833 121H17.1667C7.96094 121 0.5 113.526 0.5 104.333V37.6667ZM13 37.6667V104.333C13 106.625 14.8646 108.5 17.1667 108.5H133.833C136.125 108.5 138 106.625 138 104.333V37.6667C138 35.375 136.125 33.5 133.833 33.5H17.1667C14.8646 33.5 13 35.375 13 37.6667Z" fill="#4065A9" />
        //                         <path d="M40.4652 93.3943V46.5391H43.4668V93.3943H40.4652ZM28.4585 88.7088V51.2246H43.4668C46.2245 51.2246 48.5245 51.6334 50.367 52.4509C52.2095 53.2684 53.5944 54.4032 54.5217 55.8552C55.4491 57.295 55.9128 58.9545 55.9128 60.8336C55.9128 62.2978 55.6199 63.5851 55.0342 64.6955C54.4485 65.7937 53.6432 66.6966 52.6182 67.4043C51.6055 68.0998 50.4463 68.594 49.1407 68.8868V69.2529C50.5683 69.3139 51.9044 69.7166 53.149 70.4609C54.4058 71.2052 55.4247 72.2485 56.2056 73.5907C56.9865 74.9207 57.377 76.5069 57.377 78.3494C57.377 80.3383 56.8828 82.1137 55.8945 83.6755C54.9183 85.2252 53.4724 86.4515 51.5567 87.3544C49.641 88.2573 47.2799 88.7088 44.4735 88.7088H28.4585ZM36.3836 82.2296H42.8445C45.0531 82.2296 46.6637 81.8086 47.6765 80.9667C48.6892 80.1126 49.1956 78.9778 49.1956 77.5624C49.1956 76.5252 48.9455 75.6101 48.4452 74.817C47.9449 74.0238 47.2311 73.4015 46.3038 72.9501C45.3886 72.4986 44.2966 72.2729 43.0276 72.2729H36.3836V82.2296ZM36.3836 66.9101H42.2588C43.3448 66.9101 44.3088 66.721 45.1507 66.3427C46.0048 65.9523 46.6759 65.4032 47.164 64.6955C47.6643 63.9878 47.9144 63.1397 47.9144 62.1514C47.9144 60.797 47.4324 59.7049 46.4685 58.8752C45.5167 58.0454 44.1623 57.6306 42.4053 57.6306H36.3836V66.9101Z" fill="#4065A9" />
        //                         <path d="M138.25 54.1667C141.714 54.1667 144.5 56.9531 144.5 60.4167C144.5 63.8802 141.714 66.6667 138.25 66.6667H92.4167C88.9531 66.6667 86.1667 63.8802 86.1667 60.4167C86.1667 56.9531 88.9531 54.1667 92.4167 54.1667H138.25ZM86.1667 35.4167C86.1667 31.9531 88.9531 29.1667 92.4167 29.1667H138.25C141.714 29.1667 144.5 31.9531 144.5 35.4167C144.5 38.8802 141.714 41.6667 138.25 41.6667H92.4167C88.9531 41.6667 86.1667 38.8802 86.1667 35.4167ZM19.5 16.6667C19.5 7.46094 26.9609 0 36.1667 0H152.833C162.026 0 169.5 7.46094 169.5 16.6667V83.3333C169.5 92.526 162.026 100 152.833 100H36.1667C26.9609 100 19.5 92.526 19.5 83.3333V16.6667ZM32 16.6667V83.3333C32 85.625 33.8646 87.5 36.1667 87.5H152.833C155.125 87.5 157 85.625 157 83.3333V50V16.6667C157 14.375 155.125 12.5 152.833 12.5H36.1667C33.8646 12.5 32 14.375 32 16.6667Z" fill="#4065A9" />
        //                         <path fillRule="evenodd" clipRule="evenodd" d="M32 16.6667V83.3333C32 85.625 33.8646 87.5 36.1667 87.5H152.833C155.125 87.5 157 85.625 157 83.3333V50V16.6667C157 14.375 155.125 12.5 152.833 12.5H36.1667C33.8646 12.5 32 14.375 32 16.6667ZM144.5 60.4167C144.5 56.9531 141.714 54.1667 138.25 54.1667H92.4167C88.9531 54.1667 86.1667 56.9531 86.1667 60.4167C86.1667 63.8802 88.9531 66.6667 92.4167 66.6667H138.25C141.714 66.6667 144.5 63.8802 144.5 60.4167ZM92.4167 29.1667C88.9531 29.1667 86.1667 31.9531 86.1667 35.4167C86.1667 38.8802 88.9531 41.6667 92.4167 41.6667H138.25C141.714 41.6667 144.5 38.8802 144.5 35.4167C144.5 31.9531 141.714 29.1667 138.25 29.1667H92.4167Z" fill="white" />
        //                         <path d="M59.4652 72.3943V25.5391H62.4668V72.3943H59.4652ZM47.4585 67.7088V30.2246H62.4668C65.2245 30.2246 67.5245 30.6334 69.367 31.4509C71.2095 32.2684 72.5944 33.4032 73.5217 34.8552C74.4491 36.295 74.9128 37.9545 74.9128 39.8336C74.9128 41.2978 74.6199 42.5851 74.0342 43.6955C73.4485 44.7937 72.6432 45.6966 71.6182 46.4043C70.6055 47.0998 69.4463 47.594 68.1407 47.8868V48.2529C69.5683 48.3139 70.9044 48.7166 72.149 49.4609C73.4058 50.2052 74.4247 51.2485 75.2056 52.5907C75.9865 53.9207 76.377 55.5069 76.377 57.3494C76.377 59.3383 75.8828 61.1137 74.8945 62.6755C73.9183 64.2252 72.4724 65.4515 70.5567 66.3544C68.641 67.2573 66.2799 67.7088 63.4735 67.7088H47.4585ZM55.3836 61.2296H61.8445C64.0531 61.2296 65.6637 60.8086 66.6765 59.9667C67.6892 59.1126 68.1956 57.9778 68.1956 56.5624C68.1956 55.5252 67.9455 54.6101 67.4452 53.817C66.9449 53.0238 66.2311 52.4015 65.3038 51.9501C64.3886 51.4986 63.2966 51.2729 62.0276 51.2729H55.3836V61.2296ZM55.3836 45.9101H61.2588C62.3448 45.9101 63.3088 45.721 64.1507 45.3427C65.0048 44.9523 65.6759 44.4032 66.164 43.6955C66.6643 42.9878 66.9144 42.1397 66.9144 41.1514C66.9144 39.797 66.4324 38.7049 65.4685 37.8752C64.5167 37.0454 63.1623 36.6306 61.4053 36.6306H55.3836V45.9101Z" fill="#4065A9" />
        //                     </svg>
        //                     <div className="texts">
        //                         <p className="large-text">เพิ่มบัญชีคู่ฝากแล้ว</p>
        //                         <p className="normal-text">เจ้าหน้าที่กำลังตรวจสอบข้อมูล</p>
        //                         <p className="normal-text">จะแจ้งผลให้ทราบทางอีเมล</p>
        //                     </div>
        //                 </div>
        //                 <div className="button-box">
        //                     <div className="button-layout-2">
        //                         <button className="button-blue" onClick={() => navigate("/", {
        //                             state: {
        //                                 account: {},
        //                                 status: 'success',
        //                                 tel_number: '0999999999',
        //                                 passbook: { status: 'pending' }
        //                             }
        //                         })}>ตกลง</button>
        //                         {/* <button className="button-blue" onClick={() => navigate("/", { state: { account: {}, status: 'success', tel_number: tel } })}>ตกลง</button> */}
        //                         {/* <button className="button-blue" onClick={() => navigate("/", { state: { account: { passbook: {} }, status: 'success' } })}>ตกลง</button> */}
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default PassbookSuccess