const getItem = (key) => {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return;
  };
  
  const setItem = (key, value) => {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
  };
  
  const removeItem = (key) => {
    localStorage.removeItem(key);
  };
  
  const clearItem = () => {
    localStorage.clear();
  };
  
  const clearEspeciallyItem = (keys) => {
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  };
  
  const localStorageService = {
    getItem,
    setItem,
    removeItem,
    clearItem,
    clearEspeciallyItem,
  };
  
  export default localStorageService;
  