import React, { useEffect, useState } from "react";
import CancelModal from "../../components/CancelModal";

import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { getStorageData, setStorageData } from "../../utils/storage";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const Ndid = ({ reset, confirm }) => {
    const [pid, setPid] = useState("6444349660891");
    const navigate = useNavigate();

    const handleTerms = () => {
        confirm();
        navigate("/ndid-terms");
    }

    const handleChange = (e) => {
        if (e.target.value.length <= 13) {
            setPid(e.target.value.replace(/[^\d]/ig, ""));
            let userData = getStorageData("userData");
            userData.personal_info.pid = e.target.value.replace(/[^\d]/ig, "");
            setStorageData("userData", userData);
        }
    };

    // Signout Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleLogout = () => {
        reset();
        navigate("/decline-support");
    }
    return (
        <div className="t">
            {/* <div className="popup" id="popup1">
                <button onClick={() => { document.getElementById("popup1").remove(); }}>X</button>
                Citizen ID
                <input type="text" placeholder="citizen ID..." value={pid} onChange={(e) => handleChange(e)} />
            </div> */}
            {openModal && <CancelModal open={openModal} logout={handleLogout} handleClose={handleClose} note={"CANCEL_NDID"} status={"ยกเลิกการยืนยันข้อมูล NDID"} />}
            <div className="t-container">
                <div className="header-box">
                    <div className="header-layout-2">
                        <p>เปิดบัญชีใหม่</p>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-layout-2">
                        <div className="header-icon">
                            <svg width="275" height="51" viewBox="0 0 275 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M161.742 12.8477C162.816 13.4336 163.5 14.5078 163.5 15.6797C163.5 17.5352 161.938 19 160.082 19H116.82C114.965 19 113.5 17.5352 113.5 15.6797C113.5 14.5078 114.086 13.4336 115.16 12.8477L137.328 0.542969C138.012 0.25 138.891 0.25 139.574 0.542969L161.742 12.8477ZM135.375 12.75C135.375 11.0898 136.742 9.625 138.5 9.625C140.16 9.625 141.625 11.0898 141.625 12.75C141.625 13.3359 141.43 13.9219 141.137 14.3125H154.809L138.5 5.32812L122.094 14.3125H135.766C135.473 13.9219 135.375 13.3359 135.375 12.75ZM119.75 22.125H124.438V37.75H130.688V22.125H135.375V37.75H141.625V22.125H146.312V37.75H152.562V22.125H157.25V37.75H158.031C159.301 37.75 160.375 38.8242 160.375 40.0938C160.375 41.4609 159.301 42.4375 158.031 42.4375H119.75C118.383 42.4375 117.406 41.4609 117.406 40.0938C117.406 38.8242 118.383 37.75 119.75 37.75V22.125ZM161.156 45.5625C162.426 45.5625 163.5 46.6367 163.5 47.9062C163.5 49.2734 162.426 50.25 161.156 50.25H116.625C115.258 50.25 114.281 49.2734 114.281 47.9062C114.281 46.6367 115.258 45.5625 116.625 45.5625H161.156Z" fill="#D9D9D9" />
                                <path d="M238.812 25.25C242.23 25.25 245.062 22.5156 245.062 19C245.062 15.582 242.23 12.75 238.812 12.75C235.297 12.75 232.562 15.582 232.562 19C232.562 22.5156 235.297 25.25 238.812 25.25ZM263.812 22.9062H254.438C253.07 22.9062 252.094 23.9805 252.094 25.25C252.094 26.6172 253.07 27.5938 254.438 27.5938H263.812C265.082 27.5938 266.156 26.6172 266.156 25.25C266.156 23.9805 265.082 22.9062 263.812 22.9062ZM241.938 28.375H235.688C231.293 28.375 227.875 31.8906 227.875 36.1875C227.875 37.0664 228.559 37.75 229.438 37.75H248.188C248.969 37.75 249.75 37.0664 249.75 36.1875C249.75 31.8906 246.234 28.375 241.938 28.375ZM263.812 15.0938H254.438C253.07 15.0938 252.094 16.168 252.094 17.4375C252.094 18.8047 253.07 19.7812 254.438 19.7812H263.812C265.082 19.7812 266.156 18.8047 266.156 17.4375C266.156 16.168 265.082 15.0938 263.812 15.0938ZM268.5 3.375H224.75C221.234 3.375 218.5 6.20703 218.5 9.625V40.875C218.5 44.3906 221.234 47.125 224.75 47.125H268.5C271.918 47.125 274.75 44.3906 274.75 40.875V9.625C274.75 6.20703 271.918 3.375 268.5 3.375ZM270.062 40.875C270.062 41.7539 269.281 42.4375 268.5 42.4375H224.75C223.871 42.4375 223.188 41.7539 223.188 40.875V9.625C223.188 8.84375 223.871 8.0625 224.75 8.0625H268.5C269.281 8.0625 270.062 8.84375 270.062 9.625V40.875Z" fill="#D9D9D9" />
                                <path d="M24.2305 33.4531C23.1562 34.5273 21.4961 34.5273 20.4219 33.4531L14.1719 27.2031C13.0977 26.1289 13.0977 24.4688 14.1719 23.3945C15.2461 22.3203 16.9062 22.3203 17.9805 23.3945L22.375 27.6914L32.9219 17.1445C33.9961 16.0703 35.6562 16.0703 36.7305 17.1445C37.8047 18.2188 37.8047 19.8789 36.7305 20.9531L24.2305 33.4531ZM50.5 25.25C50.5 39.1172 39.2695 50.25 25.5 50.25C11.6328 50.25 0.5 39.1172 0.5 25.25C0.5 11.4805 11.6328 0.25 25.5 0.25C39.2695 0.25 50.5 11.4805 50.5 25.25ZM25.5 4.9375C14.2695 4.9375 5.1875 14.1172 5.1875 25.25C5.1875 36.4805 14.2695 45.5625 25.5 45.5625C36.6328 45.5625 45.8125 36.4805 45.8125 25.25C45.8125 14.1172 36.6328 4.9375 25.5 4.9375Z" fill="#4065A9" />
                                <line x1="60.5" y1="24" x2="99.5" y2="24" stroke="#4065A9" strokeWidth="4" strokeDasharray="6 6" />
                                <line x1="171.5" y1="24" x2="210.5" y2="24" stroke="#D9D9D9" strokeWidth="4" strokeDasharray="6 6" />
                            </svg>
                        </div>
                        <div className="center-sec">
                            <svg width="124" height="129" viewBox="0 0 124 129" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="18.2559" y="22.4648" width="20.6614" height="20.6614" rx="4" fill="#4065A9" />
                                <rect x="18.2559" y="48.292" width="20.6614" height="20.6614" rx="4" fill="#4065A9" />
                                <rect x="44.083" y="22.4648" width="20.6614" height="20.6614" rx="4" fill="#4065A9" />
                                <rect x="44.083" y="48.292" width="20.6614" height="20.6614" rx="4" fill="#4065A9" />
                                <path d="M65.3 0H16.7C7.5875 0 0.5 7.30664 0.5 16.125V112.875C0.5 121.945 7.5875 129 16.7 129H65.3C74.1594 129 81.5 121.945 81.5 112.875V16.125C81.5 7.30664 74.1594 0 65.3 0ZM73.4 112.875C73.4 117.41 69.6031 120.938 65.3 120.938H16.7C12.1438 120.938 8.6 117.41 8.6 112.875V16.125C8.6 11.8418 12.1438 8.0625 16.7 8.0625H65.3C69.6031 8.0625 73.4 11.8418 73.4 16.125V112.875ZM49.1 100.781H32.9C30.6219 100.781 28.85 102.797 28.85 104.812C28.85 107.08 30.6219 108.844 32.9 108.844H49.1C51.125 108.844 53.15 107.08 53.15 104.812C53.15 102.797 51.125 100.781 49.1 100.781Z" fill="#4065A9" />
                                <path d="M76.8611 61.5C81.2969 61.5 84.9722 57.9375 84.9722 53.3571C84.9722 48.904 81.2969 45.2143 76.8611 45.2143C72.2986 45.2143 68.75 48.904 68.75 53.3571C68.75 57.9375 72.2986 61.5 76.8611 61.5ZM109.306 58.4464H97.1389C95.3646 58.4464 94.0972 59.846 94.0972 61.5C94.0972 63.2812 95.3646 64.5536 97.1389 64.5536H109.306C110.953 64.5536 112.347 63.2812 112.347 61.5C112.347 59.846 110.953 58.4464 109.306 58.4464ZM80.9167 65.5714H72.8056C67.1024 65.5714 62.6667 70.1518 62.6667 75.75C62.6667 76.8951 63.5538 77.7857 64.6944 77.7857H89.0278C90.0417 77.7857 91.0556 76.8951 91.0556 75.75C91.0556 70.1518 86.4931 65.5714 80.9167 65.5714ZM109.306 48.2679H97.1389C95.3646 48.2679 94.0972 49.6674 94.0972 51.3214C94.0972 53.1027 95.3646 54.375 97.1389 54.375H109.306C110.953 54.375 112.347 53.1027 112.347 51.3214C112.347 49.6674 110.953 48.2679 109.306 48.2679ZM115.389 33H58.6111C54.0486 33 50.5 36.6897 50.5 41.1429V81.8571C50.5 86.4375 54.0486 90 58.6111 90H115.389C119.825 90 123.5 86.4375 123.5 81.8571V41.1429C123.5 36.6897 119.825 33 115.389 33ZM117.417 81.8571C117.417 83.0022 116.403 83.8929 115.389 83.8929H58.6111C57.4705 83.8929 56.5833 83.0022 56.5833 81.8571V41.1429C56.5833 40.125 57.4705 39.1071 58.6111 39.1071H115.389C116.403 39.1071 117.417 40.125 117.417 41.1429V81.8571Z" fill="#4065A9" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M117.417 81.8571C117.417 83.0022 116.403 83.8929 115.389 83.8929H58.6111C57.4705 83.8929 56.5833 83.0022 56.5833 81.8571V41.1429C56.5833 40.125 57.4705 39.1071 58.6111 39.1071H115.389C116.403 39.1071 117.417 40.125 117.417 41.1429V81.8571ZM84.9722 53.3571C84.9722 57.9375 81.2969 61.5 76.8611 61.5C72.2986 61.5 68.75 57.9375 68.75 53.3571C68.75 48.904 72.2986 45.2143 76.8611 45.2143C81.2969 45.2143 84.9722 48.904 84.9722 53.3571ZM97.1389 58.4464H109.306C110.953 58.4464 112.347 59.846 112.347 61.5C112.347 63.2812 110.953 64.5536 109.306 64.5536H97.1389C95.3646 64.5536 94.0972 63.2812 94.0972 61.5C94.0972 59.846 95.3646 58.4464 97.1389 58.4464ZM72.8056 65.5714H80.9167C86.4931 65.5714 91.0556 70.1518 91.0556 75.75C91.0556 76.8951 90.0417 77.7857 89.0278 77.7857H64.6944C63.5538 77.7857 62.6667 76.8951 62.6667 75.75C62.6667 70.1518 67.1024 65.5714 72.8056 65.5714ZM97.1389 48.2679H109.306C110.953 48.2679 112.347 49.6674 112.347 51.3214C112.347 53.1027 110.953 54.375 109.306 54.375H97.1389C95.3646 54.375 94.0972 53.1027 94.0972 51.3214C94.0972 49.6674 95.3646 48.2679 97.1389 48.2679Z" fill="white" />
                            </svg>
                            <div className="texts">
                                <p className="large-text">การยืนยันตัวตน NDID</p>
                                <p className="normal-text">การยืนยันข้อมูลจากแอปพลิเคชัน</p>
                                <p className="normal-text">ผู้ให้บริการยืนยันตัวตนของผู้ใช้งาน</p>
                            </div>
                        </div>
                        <div className="button-box">
                            <div className="button-layout-1">
                                <button onClick={handleOpen}>ยกเลิก</button>
                                <button onClick={handleTerms}>ถัดไป</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ndid