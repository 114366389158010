import LoadScreen from "../../components/LoadScreen";
import providerService from "../../services/provider.service";
import CancelModal from "../../components/CancelModal";
import JWE from "../../services/jwe/jwe";

import { saveCancelLog } from "../../services/api/admin.service";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from 'react-device-detect';
import { genCaseRef } from "../../utils/common";
import { getStorageData, setStorageData } from "../../utils/storage";
import { UpdateState } from "../../services/api/util.service";
import { refreshToken } from "../../services/api/auth.service";
import { AuthContext } from "../../services/auth/AuthContext";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const NdidResult = ({ reset, confirm }) => {
    const [selectedItem, setSelectedItem] = useState(getStorageData("userData").account.timestamp.ndid_info);
    const [loginData, setLoginData] = useState(getStorageData("loginData"));
    const [loading, setLoading] = useState(false);
    const [errorMSG, setErrorMSG] = useState("");
    const [user, setUserData] = useState(getStorageData("userData"));

    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        initial();
    }, []);

    const initial = async () => {
        if (user.ndid_result.status === "error") {
            try {
                setLoading(true);
                const response = await providerService.getError(user.ndid_result.error_code);
                setErrorMSG(response.data.description_th);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
                localStorage.removeItem("ndid");
            }
        }
        if (user.ndid_result.status === "rejected") {
            try {
                setLoading(true);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
                localStorage.removeItem("ndid");
            }
        }
    }

    const handleNext = () => {
        confirm();
        navigate("/account-summary");
    }

    // back to NDID Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleLogout = () => {
        reset();
        navigate("/ndid");
    }
    return (
        <div className="t">
            {openModal && <CancelModal open={openModal} logout={handleLogout} handleClose={handleClose} note={"FAILED_NDID"} status={"Reject NDID"} />}
            {loading && <LoadScreen />}
            <div className="t-container">
                <div className="header-box">
                    <div className="header-layout-2">
                        {user.ndid_result.status === "success" ? <p>เปิดบัญชีใหม่</p> : <p>กรุณายืนยันการใช้งาน NDID</p>}
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-layout-2">
                        <div className="header-icon">
                            {user.ndid_result.status === "success" ?
                                <svg width="275" height="51" viewBox="0 0 275 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M161.242 12.8477C162.316 13.4336 163 14.5078 163 15.6797C163 17.5352 161.438 19 159.582 19H116.32C114.465 19 113 17.5352 113 15.6797C113 14.5078 113.586 13.4336 114.66 12.8477L136.828 0.542969C137.512 0.25 138.391 0.25 139.074 0.542969L161.242 12.8477ZM134.875 12.75C134.875 11.0898 136.242 9.625 138 9.625C139.66 9.625 141.125 11.0898 141.125 12.75C141.125 13.3359 140.93 13.9219 140.637 14.3125H154.309L138 5.32812L121.594 14.3125H135.266C134.973 13.9219 134.875 13.3359 134.875 12.75ZM119.25 22.125H123.938V37.75H130.188V22.125H134.875V37.75H141.125V22.125H145.812V37.75H152.062V22.125H156.75V37.75H157.531C158.801 37.75 159.875 38.8242 159.875 40.0938C159.875 41.4609 158.801 42.4375 157.531 42.4375H119.25C117.883 42.4375 116.906 41.4609 116.906 40.0938C116.906 38.8242 117.883 37.75 119.25 37.75V22.125ZM160.656 45.5625C161.926 45.5625 163 46.6367 163 47.9062C163 49.2734 161.926 50.25 160.656 50.25H116.125C114.758 50.25 113.781 49.2734 113.781 47.9062C113.781 46.6367 114.758 45.5625 116.125 45.5625H160.656Z" fill="#D9D9D9" />
                                    <path d="M238.312 25.25C241.73 25.25 244.562 22.5156 244.562 19C244.562 15.582 241.73 12.75 238.312 12.75C234.797 12.75 232.062 15.582 232.062 19C232.062 22.5156 234.797 25.25 238.312 25.25ZM263.312 22.9062H253.938C252.57 22.9062 251.594 23.9805 251.594 25.25C251.594 26.6172 252.57 27.5938 253.938 27.5938H263.312C264.582 27.5938 265.656 26.6172 265.656 25.25C265.656 23.9805 264.582 22.9062 263.312 22.9062ZM241.438 28.375H235.188C230.793 28.375 227.375 31.8906 227.375 36.1875C227.375 37.0664 228.059 37.75 228.938 37.75H247.688C248.469 37.75 249.25 37.0664 249.25 36.1875C249.25 31.8906 245.734 28.375 241.438 28.375ZM263.312 15.0938H253.938C252.57 15.0938 251.594 16.168 251.594 17.4375C251.594 18.8047 252.57 19.7812 253.938 19.7812H263.312C264.582 19.7812 265.656 18.8047 265.656 17.4375C265.656 16.168 264.582 15.0938 263.312 15.0938ZM268 3.375H224.25C220.734 3.375 218 6.20703 218 9.625V40.875C218 44.3906 220.734 47.125 224.25 47.125H268C271.418 47.125 274.25 44.3906 274.25 40.875V9.625C274.25 6.20703 271.418 3.375 268 3.375ZM269.562 40.875C269.562 41.7539 268.781 42.4375 268 42.4375H224.25C223.371 42.4375 222.688 41.7539 222.688 40.875V9.625C222.688 8.84375 223.371 8.0625 224.25 8.0625H268C268.781 8.0625 269.562 8.84375 269.562 9.625V40.875Z" fill="#4065A9" />
                                    <path d="M23.7305 33.4531C22.6562 34.5273 20.9961 34.5273 19.9219 33.4531L13.6719 27.2031C12.5977 26.1289 12.5977 24.4688 13.6719 23.3945C14.7461 22.3203 16.4062 22.3203 17.4805 23.3945L21.875 27.6914L32.4219 17.1445C33.4961 16.0703 35.1562 16.0703 36.2305 17.1445C37.3047 18.2188 37.3047 19.8789 36.2305 20.9531L23.7305 33.4531ZM50 25.25C50 39.1172 38.7695 50.25 25 50.25C11.1328 50.25 0 39.1172 0 25.25C0 11.4805 11.1328 0.25 25 0.25C38.7695 0.25 50 11.4805 50 25.25ZM25 4.9375C13.7695 4.9375 4.6875 14.1172 4.6875 25.25C4.6875 36.4805 13.7695 45.5625 25 45.5625C36.1328 45.5625 45.3125 36.4805 45.3125 25.25C45.3125 14.1172 36.1328 4.9375 25 4.9375Z" fill="#D9D9D9" />
                                    <line x1="60" y1="24" x2="99" y2="24" stroke="#D9D9D9" strokeWidth="4" strokeDasharray="6 6" />
                                    <line x1="171" y1="24" x2="210" y2="24" stroke="#D9D9D9" strokeWidth="4" strokeDasharray="6 6" />
                                </svg> : <svg width="275" height="51" viewBox="0 0 275 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M161.242 12.8477C162.316 13.4336 163 14.5078 163 15.6797C163 17.5352 161.438 19 159.582 19H116.32C114.465 19 113 17.5352 113 15.6797C113 14.5078 113.586 13.4336 114.66 12.8477L136.828 0.542969C137.512 0.25 138.391 0.25 139.074 0.542969L161.242 12.8477ZM134.875 12.75C134.875 11.0898 136.242 9.625 138 9.625C139.66 9.625 141.125 11.0898 141.125 12.75C141.125 13.3359 140.93 13.9219 140.637 14.3125H154.309L138 5.32812L121.594 14.3125H135.266C134.973 13.9219 134.875 13.3359 134.875 12.75ZM119.25 22.125H123.938V37.75H130.188V22.125H134.875V37.75H141.125V22.125H145.812V37.75H152.062V22.125H156.75V37.75H157.531C158.801 37.75 159.875 38.8242 159.875 40.0938C159.875 41.4609 158.801 42.4375 157.531 42.4375H119.25C117.883 42.4375 116.906 41.4609 116.906 40.0938C116.906 38.8242 117.883 37.75 119.25 37.75V22.125ZM160.656 45.5625C161.926 45.5625 163 46.6367 163 47.9062C163 49.2734 161.926 50.25 160.656 50.25H116.125C114.758 50.25 113.781 49.2734 113.781 47.9062C113.781 46.6367 114.758 45.5625 116.125 45.5625H160.656Z" fill="#4065A9" />
                                    <path d="M238.312 25.25C241.73 25.25 244.562 22.5156 244.562 19C244.562 15.582 241.73 12.75 238.312 12.75C234.797 12.75 232.062 15.582 232.062 19C232.062 22.5156 234.797 25.25 238.312 25.25ZM263.312 22.9062H253.938C252.57 22.9062 251.594 23.9805 251.594 25.25C251.594 26.6172 252.57 27.5938 253.938 27.5938H263.312C264.582 27.5938 265.656 26.6172 265.656 25.25C265.656 23.9805 264.582 22.9062 263.312 22.9062ZM241.438 28.375H235.188C230.793 28.375 227.375 31.8906 227.375 36.1875C227.375 37.0664 228.059 37.75 228.938 37.75H247.688C248.469 37.75 249.25 37.0664 249.25 36.1875C249.25 31.8906 245.734 28.375 241.438 28.375ZM263.312 15.0938H253.938C252.57 15.0938 251.594 16.168 251.594 17.4375C251.594 18.8047 252.57 19.7812 253.938 19.7812H263.312C264.582 19.7812 265.656 18.8047 265.656 17.4375C265.656 16.168 264.582 15.0938 263.312 15.0938ZM268 3.375H224.25C220.734 3.375 218 6.20703 218 9.625V40.875C218 44.3906 220.734 47.125 224.25 47.125H268C271.418 47.125 274.25 44.3906 274.25 40.875V9.625C274.25 6.20703 271.418 3.375 268 3.375ZM269.562 40.875C269.562 41.7539 268.781 42.4375 268 42.4375H224.25C223.371 42.4375 222.688 41.7539 222.688 40.875V9.625C222.688 8.84375 223.371 8.0625 224.25 8.0625H268C268.781 8.0625 269.562 8.84375 269.562 9.625V40.875Z" fill="#D9D9D9" />
                                    <path d="M23.7305 33.4531C22.6562 34.5273 20.9961 34.5273 19.9219 33.4531L13.6719 27.2031C12.5977 26.1289 12.5977 24.4688 13.6719 23.3945C14.7461 22.3203 16.4062 22.3203 17.4805 23.3945L21.875 27.6914L32.4219 17.1445C33.4961 16.0703 35.1562 16.0703 36.2305 17.1445C37.3047 18.2188 37.3047 19.8789 36.2305 20.9531L23.7305 33.4531ZM50 25.25C50 39.1172 38.7695 50.25 25 50.25C11.1328 50.25 0 39.1172 0 25.25C0 11.4805 11.1328 0.25 25 0.25C38.7695 0.25 50 11.4805 50 25.25ZM25 4.9375C13.7695 4.9375 4.6875 14.1172 4.6875 25.25C4.6875 36.4805 13.7695 45.5625 25 45.5625C36.1328 45.5625 45.3125 36.4805 45.3125 25.25C45.3125 14.1172 36.1328 4.9375 25 4.9375Z" fill="#D9D9D9" />
                                    <line x1="60" y1="24" x2="99" y2="24" stroke="#D9D9D9" strokeWidth="4" strokeDasharray="6 6" />
                                    <line x1="171" y1="24" x2="210" y2="24" stroke="#4065A9" strokeWidth="4" strokeDasharray="6 6" />
                                </svg>
                            }
                        </div>
                        {user.ndid_result.status === "success" ?
                            <>
                                <div className="center-sec">
                                    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M57.1406 79.6406C54.5859 82.1953 50.4141 82.1953 47.8594 79.6406L32.8594 64.6406C30.3047 62.0859 30.3047 57.9141 32.8594 55.3594C35.4141 52.8047 39.5859 52.8047 42.1406 55.3594L52.5 65.7188L77.8594 40.3594C80.4141 37.8047 84.5859 37.8047 87.1406 40.3594C89.6953 42.9141 89.6953 47.0859 87.1406 49.6406L57.1406 79.6406ZM120 60C120 93.1406 93.1406 120 60 120C26.8594 120 0 93.1406 0 60C0 26.8594 26.8594 0 60 0C93.1406 0 120 26.8594 120 60ZM60 11.25C33.0703 11.25 11.25 33.0703 11.25 60C11.25 86.9297 33.0703 108.75 60 108.75C86.9297 108.75 108.75 86.9297 108.75 60C108.75 33.0703 86.9297 11.25 60 11.25Z" fill="#4065A9" />
                                    </svg>
                                    <div className="texts">
                                        <p className="large-text">ยืนยันตัวตนสำเร็จ</p>
                                        <p className="normal-text">กด ถัดไป เพื่อตรวจสอบยืนยันข้อมูล</p>
                                    </div>
                                </div>
                            </>
                            : <>
                                <div className="center-sec">
                                    <div className="ref">
                                        <div className="texts">
                                            <p className="large-text">{selectedItem.marketing_name_th}</p>
                                            <p className="large-text">(Transaction Ref:{user.account.timestamp.reference_id})</p>
                                        </div>
                                        <div className="logo">
                                            <img src={`data:image/${selectedItem.image_format};base64,${selectedItem.logo_image}`} alt={selectedItem.marketing_name_th} width="72" height="72" />
                                        </div>
                                    </div>
                                </div>
                                <div className="center-sec">
                                    <div className="error-msg">
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.1631 0.646342C7.12688 0.646342 0.675282 7.15454 0.675282 15.1341C0.675282 23.1704 7.12688 29.622 15.1631 29.622C23.1427 29.622 29.6509 23.1704 29.6509 15.1341C29.6509 7.15454 23.1427 0.646342 15.1631 0.646342ZM15.1631 26.9055C8.65489 26.9055 3.39174 21.6423 3.39174 15.1341C3.39174 8.68255 8.65489 3.36281 15.1631 3.36281C21.6147 3.36281 26.9344 8.68255 26.9344 15.1341C26.9344 21.6423 21.6147 26.9055 15.1631 26.9055ZM15.1631 17.8506C15.8988 17.8506 16.5213 17.2847 16.5213 16.4924V9.24848C16.5213 8.51277 15.8988 7.89024 15.1631 7.89024C14.3708 7.89024 13.8049 8.51277 13.8049 9.24848V16.4924C13.8049 17.2847 14.3708 17.8506 15.1631 17.8506ZM15.1631 19.7748C14.1444 19.7748 13.3521 20.5671 13.3521 21.5292C13.3521 22.4912 14.1444 23.2835 15.1631 23.2835C16.1252 23.2835 16.9175 22.4912 16.9175 21.5292C16.9175 20.5671 16.1252 19.7748 15.1631 19.7748Z" fill="#C80303" />
                                        </svg>
                                        <div className="texts">
                                            {user.ndid_result.status === "error" && <p>{errorMSG}</p>}
                                            {user.ndid_result.status === "timeout" && <p>คุณไม่ได้ทำรายการในเวลาที่กำหนด กรุณาลองใหม่อีกครั้ง</p>}
                                            {user.ndid_result.status === "rejected" && <p>การยืนยันตัวตนไม่สำเร็จ เนื่องจากผู้ให้บริการยืนยันตัวตนที่ท่านเลือกไม่สามารถให้บริการได้ในขณะนี้ กรุณาทำรายการใหม่ หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น</p>}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="button-box">
                            <div className="button-layout-2">
                                {user.ndid_result.status === "success" ?
                                    <button className="button-blue" onClick={handleNext}>ถัดไป</button> :
                                    <button className="button-blue" onClick={handleOpen}>กลับ</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NdidResult