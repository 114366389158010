import { v4 as uuidv4 } from 'uuid';

export const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
        return "0 Byte";
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export const ramdomText9Digit = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const genRefID = () => {
    let date = new Date();
    let refID = String(date.getTime()).slice(-9);
    return refID;
}

export const genAccountID = () => {
    let accountID = random(3) + "-" + random(3) + "-" + random(4) + "-" + random(1);
    return accountID;
}

const random = (length) => {
    let string = "";
    for (let i = 0; i < length; i++) string += String(Math.floor(Math.random() * 10));
    return string;
}

export const genTimestamp = () => {
    return new Date().getTime() + "";
}

export const genCaseRef = () => {
    let caseRef = 'CDD' + random(4);
    return caseRef;
}

export const convertQuestionnaireFormat = async (data) => {
    let newObj = {};
    let ans = "";
    for (const key in data) {
        // console.log(`${key}: ${data[key]}`);
        if (data[key] === true) ans += key + ", ";
        if (key === "text" && data[key] !== "") ans += data[key] + ", ";
    }
    newObj.q = data.q;
    newObj.ans = ans;
    return newObj;
}

export const PIDFormat = (pid, method) => {
    if (method === "add") {
        let originPid = pid.replaceAll(" ", "");
        let newPid = "";
        for (let i = 0; i < originPid.length; i++) {
            newPid += originPid[i];
            if (i === 0 || i === 4 || i === 9 || i === 11) {
                newPid += " ";
            }
        }
        return newPid;
        // if (pid.length === 1 || pid.length === 6 || pid.length === 12 || pid.length === 15) return pid + " ";
    } else if (method === "subtract") {
        if (pid.length === 2 || pid.length === 7 || pid.length === 13 || pid.length === 16) return pid.slice(0, -1);
    }
    return pid;
}

export const telephoneFormat = (telephone, method) => {
    if (method === "add") {
        let originTelephone = telephone.replaceAll("-", "");
        let newTelephone = "";
        for (let i = 0; i < originTelephone.length; i++) {
            newTelephone += originTelephone[i];
            if (i === 2 || i === 5) {
                newTelephone += "-";
            }
        }
        return newTelephone;
        // if (telephone.length === 3 || telephone.length === 7) return telephone + "-";
    } else if (method === "subtract") {
        if (telephone.length === 4 || telephone.length === 8) return telephone.slice(0, -1);
    }
    return telephone;
}

export const genUUID = () => {
    return uuidv4();
}

export const sliceText = async (text) => {
    let index = text.search(',');
    let newText = text.slice(index + 1);
    return newText;
}

export const checkSize = async (file, sizeMB) => {
    let maxSize_KB = sizeMB * 1024;
    let fileSize = Math.round((file.size / 1024));
    if (fileSize < maxSize_KB) return true;
    return false;
}

export const checkExpired = async (text) => {
    // let date = new Date(text).getTime();
    // let current = new Date().getTime();
    // if (date - current > 0) return false;
    return true;
}

export const laserCodeFormat = (code, method) => {
    if (method === "add") {
        let originCode = code.replaceAll("-", "");
        let newCode = "";
        for (let i = 0; i < originCode.length; i++) {
            newCode += originCode[i];
            if (i === 2 || i === 9) {
                newCode += "-";
            }
        }
        return newCode;
    } else if (method === "subtract") {
        if (code.length === 4 || code.length === 12) return code.slice(0, -1);
    }
    return code;
}

export const passbookFormat = (number) => {
    let originNumber = number.replaceAll("-", "");
    let newNumber = "";
    for (let i = 0; i < originNumber.length; i++) {
        if (i >= 5 && i <= 8) newNumber += originNumber[i];
        else newNumber += "X";
        if (i === 2 || i === 3 || i === 8) {
            newNumber += "-";
        }
    }
    return newNumber;
}