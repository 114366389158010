import { environment } from "../../utils/environment";

export const getNDIDTimestamp = async (refID, access_token) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/ndid/${refID}`, requestOptions);
    return response.json();
}

export const setNDIDTimestamp = async (data, access_token) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + access_token);

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/ndid`, requestOptions);
    return response.json();
}

export const removeTimestamp = async (refID, access_token) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);

    let requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/ndid/${refID}`, requestOptions);
    return response.json();
}