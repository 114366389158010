import React from "react";
import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { ReactComponent as FrsIcon } from "../../assets/icons/frs_faq.svg";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const style = {
    img: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        margin: "16px"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        margin: "16px"
    },
    heading: {
        color: "#373737",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "normal",
    },
    text: {
        color: "#000",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
    }
}

const Frs = () => {
    const navigate = useNavigate();
    return (
        <div className={`${isIOS ? "t" : "a"}`}>
            <div className={`${isIOS ? "t-container" : "a-container"}`}>
                <div className={`${isIOS ? "header-box" : "android-header-box-10vh"}`}>
                    <div className="header-layout-1">
                        <div onClick={() => navigate("/faq")}>
                            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.968751 12.0312C0.359376 11.4687 0.359376 10.4844 0.968751 9.92187L9.96875 0.921874C10.5313 0.312499 11.5156 0.3125 12.0781 0.921875C12.6875 1.48437 12.6875 2.46875 12.0781 3.03125L4.15625 11L12.0781 18.9219C12.6875 19.4844 12.6875 20.4687 12.0781 21.0312C11.5156 21.6406 10.5313 21.6406 9.96875 21.0312L0.968751 12.0312Z" fill="white" />
                            </svg>
                        </div>
                        <div>
                            <p>คำถามที่พบบ่อย</p>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
                    <div className="faq">
                        <div style={style.box}>
                            <p style={style.heading}>ทำไมถึงถ่ายรูปยืนยันใบหน้าไม่ผ่าน?</p>
                        </div>
                        <div style={style.box}>
                            <p style={style.text}>กรุณามั่นใจว่าท่านได้ทำตามขั้นตอน ดังนี้</p>
                            <ol>
                                <li>กดเริ่มบันทึกวิดีโอ</li>
                                <li>เริ่มจากใบหน้าตรง</li>
                                <li>หันซ้าย และหยุด 1 วินาที</li>
                                <li>หันขวา และหยุด 1 วินาที</li>
                                <li>หันหน้าตรง และกดหยุดบันทึก</li>
                            </ol>
                            <p style={style.text}>หากยังไม่ผ่านกรุณาลองใหม่ และทำตามคำแนะนำ ดังนี้</p>
                            <ul>
                                <li>อยู่ในที่ที่มีแสงสว่างเพียงพอ</li>
                                <li>หันหน้าแล้วหยุดข้างละ 1 วินาที</li>
                                <li>ลองถอดแว่นตา หรือสิ่งบดบังใบหน้า</li>
                            </ul>
                        </div>
                        <div style={style.box}>
                            <p style={style.heading}>ตัวอย่างการถ่ายรูปบัตรประชาชน</p>
                        </div>
                        <div style={style.img}>
                            <FrsIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Frs