import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext, useState } from "react";
import { BrowserView, MobileOnlyView, TabletView } from 'react-device-detect';
import { AuthContext } from "./services/auth/AuthContext";

import Login from "./pages/login/Login";
import Error from "./pages/error/Error";
import Home from "./pages/home/Home";
import New from "./pages/new/New";
import Terms from "./pages/terms/Terms";
import Support from "./pages/support/Support";
import Support2 from "./pages/support/Support2";
import Support3 from "./pages/support/Support3";
import Support4 from "./pages/support/Support4";
import Survey from "./pages/survey/Survey";
import AccountVerify from './pages/account-verify/AccountVerify';
import PersonalAccount from "./pages/personal-account/PersonalAccount";
import Ndid from "./pages/ndid/Ndid";
import NdidTerms from "./pages/ndid-terms/Ndid-terms";
import NdidProvider from "./pages/ndid-provider/Ndid-provider";
import NdidStatus from "./pages/ndid-status/Ndid-status";
import NdidResult from "./pages/ndid-result/Ndid-result";
import AccountSummary from "./pages/account-summary/AccountSum";
import PassbookVerify from "./pages/passbook/Passbook-verify";
import PassbookInfo from "./pages/passbook/Passbook-info";
import PassbookSuccess from "./pages/passbook/Passbook-success";
import Faq from "./pages/faq/Faq";
import OpenAccount from "./pages/faq/OpenAccount";
import Pid from "./pages/faq/Pid";
import Frs from "./pages/faq/Frs";
import Passbook from "./pages/faq/Passbook";
import QaNdid from "./pages/faq/Ndid";

function App() {
  const [canNewAccount, setCanNewAccount] = useState(false);
  const [canTerms, setCanTerms] = useState(false);
  const [canSurvey, setCanSurvey] = useState(false);
  const [canAccountVerify, setCanAccountVerify] = useState(false);
  const [canPersonalAccount, setCanPersonalAccount] = useState(false);
  const [canNDID, setCanNDID] = useState(false);
  const [canNDIDTerms, setCanNDIDTerms] = useState(false);
  const [canNDIDProvider, setCanNDIDProvider] = useState(false);
  const [canNDIDStatus, setCanNDIDStatus] = useState(false);
  const [canNDIDResult, setCanNDIDResult] = useState(false);
  const [canAccountSummary, setCanAccountSummary] = useState(false);
  const [canPassbookVerify, setCanPassbookVerify] = useState(false);
  const [canPassbookInfo, setCanPassbookInfo] = useState(false);
  const [canPassbookSuccess, setCanPassbookSuccess] = useState(false);

  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  }

  const resetAllPath = () => {
    setCanNewAccount(false);
    setCanTerms(false);
    setCanSurvey(false);
    setCanAccountVerify(false);
    setCanPersonalAccount(false);
    setCanNDID(false);
    setCanNDIDTerms(false);
    setCanNDIDProvider(false);
    setCanNDIDStatus(false);
    setCanNDIDResult(false);
    setCanAccountSummary(false);
    setCanPassbookVerify(false);
    setCanPassbookInfo(false);
    setCanPassbookSuccess(false);
  }

  const resetNDID = () => {
    resetAllPath();
    setCanNDID(true);
  }

  return (
    <BrowserRouter basename="/mobile">
      <BrowserView>
        <h1>Sorry, this site only rendered in mobile.</h1>
      </BrowserView>
      <MobileOnlyView>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route
              index
              element={
                <RequireAuth>
                  
                  <Home
                    reset={resetAllPath}
                    toNewAccount={() => setCanNewAccount(true)}
                    toNDIDStatus={() => setCanNDIDStatus(true)}
                    // bypass={() => setCanAccountSummary(true)}
                    // bypass={() => setCanPassbookSuccess(true)}
                    // bypass={() => setCanNDID(true)}
                    // bypass={() => setCanPersonalAccount(true)}
                  />
                </RequireAuth>}
            />
            <Route
              path="new-account"
              element={canNewAccount ?
                <RequireAuth>
                  <New
                    reset={resetAllPath}
                    confirm={() => {
                      setCanTerms(true);
                      setCanNewAccount(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="terms"
              element={canTerms ?
                <RequireAuth>
                  <Terms
                    reset={resetAllPath}
                    confirm={() => {
                      setCanSurvey(true);
                      setCanTerms(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="survey"
              element={canSurvey ?
                <RequireAuth>
                  <Survey
                    reset={resetAllPath}
                    confirm={() => {
                      setCanAccountVerify(true);
                      setCanSurvey(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="account-verify"
              element={canAccountVerify ?
                <RequireAuth>
                  <AccountVerify
                    reset={resetAllPath}
                    confirm={() => {
                      setCanPersonalAccount(true);
                      setCanAccountVerify(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="personal-information"
              element={canPersonalAccount ?
                <RequireAuth>
                  <PersonalAccount
                    reset={resetAllPath}
                    confirm={() => {
                      setCanNDID(true);
                      setCanPersonalAccount(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="ndid"
              element={canNDID ?
                <RequireAuth>
                  <Ndid
                    reset={resetAllPath}
                    confirm={() => {
                      setCanNDIDTerms(true);
                      setCanNDID(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="ndid-terms"
              element={canNDIDTerms ?
                <RequireAuth>
                  <NdidTerms
                    reset={resetNDID}
                    confirm={() => {
                      setCanNDIDProvider(true);
                      setCanNDIDTerms(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="ndid-provider"
              element={canNDIDProvider ?
                <RequireAuth>
                  <NdidProvider
                    reset={resetNDID}
                    confirm={() => {
                      setCanNDIDStatus(true);
                      setCanNDIDProvider(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="ndid-status"
              element={canNDIDStatus ?
                <RequireAuth>
                  <NdidStatus
                    reset={resetNDID}
                    confirm={() => {
                      setCanNDIDResult(true);
                      setCanNDIDStatus(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="ndid-result"
              element={canNDIDResult ?
                <RequireAuth>
                  <NdidResult
                    reset={resetNDID}
                    confirm={() => {
                      setCanAccountSummary(true);
                      setCanNDIDResult(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="account-summary"
              element={canAccountSummary ?
                <RequireAuth>
                  <AccountSummary
                    confirm={() => {
                      setCanAccountSummary(false);
                      setCanPassbookVerify(true);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="passbook-verify"
              element={canPassbookVerify ?
                <RequireAuth>
                  <PassbookVerify
                    reset={resetAllPath}
                    confirm={() => {
                      setCanPassbookInfo(true);
                      setCanPassbookVerify(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="passbook-information"
              element={canPassbookInfo ?
                <RequireAuth>
                  <PassbookInfo
                    reset={resetAllPath}
                    confirm={() => {
                      setCanPassbookSuccess(true);
                      setCanPassbookInfo(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="passbook-success"
              element={canPassbookSuccess ?
                <RequireAuth>
                  <PassbookSuccess
                    confirm={() => {
                      setCanPassbookSuccess(false);
                    }}
                  />
                </RequireAuth>
                : <Navigate to="/" />}
            />
            <Route
              path="decline-support"
              element={<Support />}
            />
            <Route
              path="support"
              element={<Support2 />}
            />
            <Route
              path="login-failed"
              element={<Support3 />}
            />
            <Route
              path="otp-failed"
              element={<Support4 />}
            />
            <Route
              path="faq"
              element={<Faq />}
            />
            <Route
              path="faq/open-account"
              element={<OpenAccount />}
            />
            <Route
              path="faq/pid"
              element={<Pid />}
            />
            <Route
              path="faq/frs"
              element={<Frs />}
            />
            <Route
              path="faq/ndid"
              element={<QaNdid />}
            />
            <Route
              path="faq/passbook"
              element={<Passbook />}
            />
            <Route
              path="*"
              element={<Error />}
            />
          </Route>
        </Routes>
      </MobileOnlyView>
    </BrowserRouter>
  );
}

export default App;
