import React, { useState, useEffect } from "react";
import Radio from '@mui/material/Radio';
import providerService from "../../services/provider.service";
import LoadScreen from "../../components/LoadScreen";
import CancelModal from "../../components/CancelModal";
import NdidMsg from "../../components/NdidMsg";

import { environment } from "../../utils/environment";
import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from 'react-device-detect';
import { genRefID, genTimestamp } from "../../utils/common";
import { encryptData, decryptData } from "../../services/api/ndid.service";
import { v4 as uuidv4 } from 'uuid';
import { getStorageData } from "../../utils/storage";
import { ReactComponent as RadioIcon } from "../../assets/icons/radio_icon.svg";
import { ReactComponent as RadioCheckedIcon } from "../../assets/icons/radio_checked_icon.svg";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const NdidTerms = ({ reset, confirm }) => {
    const [firstValue, setFirstValue] = useState(false);
    const [loading, setLoading] = useState(false);
    const [termData, setTermData] = useState(null);
    const [user, setUserData] = useState(getStorageData("userData"));
    const navigate = useNavigate();

    const handleAccept = () => {
        confirm();
        navigate("/ndid-provider");
    }

    useEffect(() => {
        initialData();
    }, [])

    const editTermFormat = (data) => {
        let textTH = data.data.term_condition[0].richtext_content_th;
        let replacedTextTH = textTH.replace("[สมาชิก]", "บริษัทเครดิตฟองซิเอร์ แคปปิตอล ลิ้งค์ จำกัด");

        let textEN = data.data.term_condition[0].richtext_content_en;
        let replacedTextEN = textEN.replace("[the Member]", "Capital Link Credit Foncier Limited");

        let term = {
            ...data.data.term_condition[0],
            richtext_content_th: replacedTextTH,
            richtext_content_en: replacedTextEN
        }

        let newData = {
            ...data,
            term_condition: [term]
        }
        return newData;
        
    }

    const initialData = async () => {
        try {
            setLoading(true);
            const payloadRequestComplying = {
                flow_id: "custInfo",
                ncb_request_params: {
                    uuid: uuidv4(),
                    member_shortname: user.personal_info.prefix || "",
                    citizen_id: user.personal_info.pid,
                    firstname: user.personal_info.first_name || "",
                    lastname: user.personal_info.last_name || "",
                    dob: user.personal_info.birth_date || "",
                    mobile_no: user.personal_info.phone_number || "",
                    email: user.personal_info.email || "",
                    product_code: "01",
                    product_name: "เปิดบัญชี",
                    trn_type: "NF2F",
                    version: 2
                }
            }

            const response = await providerService.requestComplying(payloadRequestComplying);
            if (response?.status === "success") {
                const term = editTermFormat(response);
                setTermData(term);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // back to NDID Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const [openMSG, setOpenMSG] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleOpenMSG = () => setOpenMSG(true);
    const handleLogout = () => {
        reset();
        navigate("/ndid");
    }
    return (
        <div className={`${isIOS ? "t" : "a"}`}>
            {openModal && <CancelModal open={openModal} logout={handleOpenMSG} handleClose={handleClose} note={"CANCEL_NDID"} status={"ปฏิเสธ NDID Terms"} />}
            {openMSG &&
                <NdidMsg open={openMSG} logout={handleLogout}>
                    <h3>การยืนยันตัวตนไม่สำเร็จ</h3>
                    <p>เนื่องจากท่านไม่ได้ยอมรับเงื่อนไขการให้</p>
                    <p>บริการยืนยันตัวตนของผู้ให้บริการยืนยัน</p>
                    <p>ตัวตนที่ท่านเลือก กรุณาทำรายการใหม่</p>
                    <p>หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น</p>
                </NdidMsg>
            }
            {loading && <LoadScreen />}
            <div className={`${isIOS ? "t-container" : "a-container"}`}>
                {!loading &&
                    <>
                        <div className={`${isIOS ? "header-box" : "android-header-box"}`}>
                            <div className={`${isIOS ? "header-layout-3" : "android-header"}`}>
                                <div className="sec1">
                                    <div className="texts">
                                        <svg width="24" height="24" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
                                            <path d="M1.97187 14.5H8.89062C9.26562 15.075 9.74688 15.5781 10.3031 15.9781C10.2063 15.9906 10.1031 16 10 16H2C0.895313 16 0 15.1031 0 14V2C0 0.895313 0.895313 0 2 0H7.17188C7.70312 0 8.20938 0.210719 8.58438 0.585938L11.4156 3.41563C11.7906 3.79063 12 4.29688 12 4.82812V6.20625C11.4625 6.35938 10.9563 6.59062 10.5 6.89062V5H8C7.44688 5 7 4.55312 7 4V1.5H2C1.72375 1.5 1.5 1.72375 1.5 2V14C1.5 14.275 1.72375 14.5 2 14.5H1.97187ZM18 11.5C18 13.9844 15.9844 16 13.5 16C11.0156 16 8.97188 13.9844 8.97188 11.5C8.97188 9.01562 11.0156 7 13.5 7C15.9844 7 18 9.01562 18 11.5ZM14.8969 10.1469L13 12.0437L12.1031 11.1469C11.9094 10.95 11.5906 10.95 11.3969 11.1469C11.2 11.3406 11.2 11.6594 11.3969 11.8531L12.6469 13.1031C12.8406 13.3 13.1594 13.3 13.3531 13.1031L15.6031 10.8531C15.8 10.6594 15.8 10.3406 15.6031 10.1469C15.4094 9.95 15.0906 9.95 14.8969 10.1469Z" fill="white" />
                                        </svg>
                                        <p>NDID Terms</p>
                                    </div>
                                    <div className="pages">
                                        <p>(1/1)</p>
                                    </div>
                                </div>
                                <div className="sec2">
                                    <div className="texts">
                                        <p>การยินยอมเปิดเผยข้อมูลส่วนตัว</p>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
                            <div className="content-layout-3">
                                <div className="terms">
                                    {termData?.term_condition[0]?.richtext_content_th &&
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `${termData?.term_condition[0].richtext_content_th}`,
                                            }}
                                        ></div>
                                    }
                                    {termData?.term_condition[0]?.richtext_content_en &&
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `${termData?.term_condition[0].richtext_content_en}`,
                                            }}
                                        ></div>
                                    }
                                </div>
                                <div className="checkboxs">
                                    <div className="eachCheckbox">
                                        {termData?.term_condition &&
                                            <>
                                                <Radio
                                                    icon={<RadioIcon />}
                                                    checkedIcon={<RadioCheckedIcon />}
                                                    checked={firstValue}
                                                    onClick={() => setFirstValue(!firstValue)}
                                                />
                                                <p>{termData?.term_condition[0]?.options[0]?.name_th}</p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="button-box">
                                    <div className="button-layout-1">
                                        <button onClick={handleOpen}>ปฎิเสธ</button>
                                        <button disabled={firstValue ? false : true}
                                            onClick={() => handleAccept()}>ยอมรับ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default NdidTerms