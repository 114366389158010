function ProcessEnvironment() {
    return {
        baseUrl: process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_BASE_URL_PROD : "",
        ndidKey: process.env.REACT_APP_API_KEY,
        ndidSecret: process.env.REACT_APP_SECRET,
        ndidTenant: process.env.REACT_APP_TENANT_ID,
        ndidEncryptPath: process.env.REACT_APP_ENCRYPT_PATH,
        ndidDecryptPath: process.env.REACT_APP_DECRYPT_PATH,
        port: process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_PORT_DEV : process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_PORT_PROD : "",
        xApiKey: process.env.REACT_APP_X_API_KEY,
    };
}

export const environment = ProcessEnvironment();