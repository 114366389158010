import { environment } from "../../utils/environment";

const token = function (ctx) {
  return new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem("access_token");
    resolve(accessToken);
  });
};

export const LiveDetect = async (data) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + (await token()));

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/frs/face-compare-live-detect`,
    requestOptions
  );
  return response.json();
};

export const FaceCompare = async (data, access_token) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + access_token);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ data: data }),
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/frs/face-compare`,
    requestOptions
  );
  return response.json();
};
