import httpClient from "./httpClientNDID";
import { environment } from "../utils/environment";

const token = function (ctx) {
  return new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem("access_token");
    resolve(accessToken);
  });
};

const providerList = async (data) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + (await token()));
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/ndid/identity/complying/result`,
    requestOptions
  )

  return response.json();
};

const requestAPI = async (data) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + (await token()));
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/ndid/identity/request`,
    requestOptions
  )

  return response.json();
};

const checkStatus = async () => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + (await token()));
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/ndid/identity/request/status`,
    requestOptions
  )

  return response.json();
};

const requestComplying = async (data) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + (await token()));
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/ndid/identity/complying`,
    requestOptions
  )

  return response.json();
};

const cancelByUser = async (body) => {
  const url = `/request/cancel`;
  const data = await httpClient().post(url, body);
  return data;
};

const getError = async (error_code) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + (await token()));
  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/ndid/identity/error?error_code=${error_code}`,
    requestOptions
  )

  return response.json();
};

const providerService = {
  getError,
  cancelByUser,
  providerList,
  requestAPI,
  checkStatus,
  requestComplying,
};

export default providerService;
