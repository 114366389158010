import React, { useState } from "react";
import CancelModal from "../../components/CancelModal";

import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from 'react-device-detect';

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const New = ({ reset, confirm }) => {
    const navigate = useNavigate();

    const handleNext = () => {
        confirm();
        navigate("/terms");
    }

    // Signout Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleLogout = () => {
        reset();
        navigate("/decline-support");
    }
    return (
        <div className={`${isIOS ? "t" : "a"}`}>
            {openModal && <CancelModal open={openModal} logout={handleLogout} handleClose={handleClose} note={"CANCEL"} status={"ยกเลิกการเปิดบัญชี"} />}
            <div className={`${isIOS ? "t-container" : "a-container"}`}>
                <div className={`${isIOS ? "header-box" : "android-header-box-10vh"}`}>
                    <div className="header-layout-2">
                        <p>เปิดบัญชีใหม่</p>
                    </div>
                </div>
                <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
                    <div className="content-layout-4">
                        <div className="center-div">
                            <p className="text-large">ขั้นตอนการเปิดบัญชี</p>
                            <p className="text">กรุณายืนยัน และรับทราบข้อควรระวัง</p>
                            <p className="text">สามารถอ่านรายละเอียดเพิ่มเติมก่อนได้ที่</p>
                            <button className="blue-button" onClick={() => { navigate("/faq"); }}>คู่มือการเปิดบัญชี</button>
                        </div>
                        <div className="center-div">
                            <div className="box">
                                <p className="large-text">1.</p>
                                <p className="text">การยอมรับและยินยอมเงื่อนไข</p>
                            </div>
                            <div className="box">
                                <p className="large-text">2.</p>
                                <p className="text">ทำแบบสอบถามการเปิดบัญชี</p>
                            </div>
                            <div className="box">
                                <p className="large-text">3.</p>
                                <p className="text">สแกนบัตรประชาชน</p>
                            </div>
                            <div className="box">
                                <p className="large-text">4.</p>
                                <p className="text">สแกนใบหน้ายืนยันตัวตน</p>
                            </div>
                            <div className="box">
                                <p className="large-text">5.</p>
                                <p className="text">การยืนยันข้อมูล</p>
                            </div>
                            <div className="box">
                                <p className="large-text">6.</p>
                                <p className="text">ยืนยันตัวตนผ่าน NDID</p>
                            </div>
                            <div className="box">
                                <p className="large-text">7.</p>
                                <p className="text">เพิ่มบัญชีคู่ฝาก</p>
                            </div>
                        </div>
                        <div className="center-div">
                            <div className="caution">
                                <p className="text-red">• การยืนยันตัวตนในระบบมีเวลา 60 นาที</p>
                                <p className="text-red">• โปรดเตรียมบัตรประชาชน และสมุดบัญชีคู่ฝาก</p>
                            </div>
                        </div>
                        <div className="center-div">
                            <div className="button-box">
                                <div className="button-layout-1">
                                    <button onClick={() => navigate("/")}>กลับ</button>
                                    <button onClick={() => handleNext()}>ถัดไป</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default New