import React from "react";

import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from "react-router-dom";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const Support4 = () => {
    const navigate = useNavigate();
    return (
        <div className="t">
            <div className="t-container">
                <div className="header-box">
                    <div className="header-layout-2">
                        <p>พบปัญหา</p>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-layout-2">
                        <div className="header-icon">

                        </div>
                        <div className="center-sec">
                            <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
                                <path d="M35 0.75C15.5859 0.75 0 16.4727 0 35.75C0 55.1641 15.5859 70.75 35 70.75C54.2773 70.75 70 55.1641 70 35.75C70 16.4727 54.2773 0.75 35 0.75ZM35 64.1875C19.2773 64.1875 6.5625 51.4727 6.5625 35.75C6.5625 20.1641 19.2773 7.3125 35 7.3125C50.5859 7.3125 63.4375 20.1641 63.4375 35.75C63.4375 51.4727 50.5859 64.1875 35 64.1875ZM35 42.3125C36.7773 42.3125 38.2812 40.9453 38.2812 39.0312V21.5312C38.2812 19.7539 36.7773 18.25 35 18.25C33.0859 18.25 31.7188 19.7539 31.7188 21.5312V39.0312C31.7188 40.9453 33.0859 42.3125 35 42.3125ZM35 46.9609C32.5391 46.9609 30.625 48.875 30.625 51.1992C30.625 53.5234 32.5391 55.4375 35 55.4375C37.3242 55.4375 39.2383 53.5234 39.2383 51.1992C39.2383 48.875 37.3242 46.9609 35 46.9609Z" fill="#C80303" />
                            </svg>
                            <div className="texts">
                                <p className="large-text-2">ไม่สามารถดำเนินการต่อได้</p>
                                <p className="large-text-2">เนื่องจากคุณขอรหัส OTP</p>
                                <p className="large-text-2">เกินจำนวนครั้งที่กำหนด</p>
                            </div>
                            <div className="texts">
                                <p className="normal-text-2">กรุณาติดต่อเจ้าหน้าที่</p>
                                <p className="normal-text-2">02-678-0666 ต่อ 726-728</p>
                            </div>
                        </div>
                        <div className="button-box">
                            <div className="button-layout-2">
                                <a className="button-blue tel" href="tel:026780666">โทร</a>
                                <button className="button-white" onClick={() => { navigate("/"); }}>กลับหน้าแรก</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support4