import { environment } from "../../utils/environment";

const getRefreshToken = function (ctx) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem("refresh_token")
        resolve(accessToken)
    })
}

export const refreshToken = async (uuid) => {
    let myHeaders = new Headers();
    myHeaders.append("x-user-id", uuid);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + await getRefreshToken());

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    const response = await fetch(`${environment.baseUrl}/api/v1/auth/refresh`, requestOptions);
    return response.json();
}