import React, { useContext, useState, useEffect } from "react";
import CancelModal from "../../components/CancelModal";
import LoadScreen from "../../components/LoadScreen";
import JWE from "../../services/jwe/jwe";

import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from "react-device-detect";
import { createAccount } from "../../services/api/account.service";
import { getStorageData, setStorageData } from "../../utils/storage";
import { checkCreateAccount } from "../../services/api/util.service";
import { refreshToken } from "../../services/api/auth.service";
import { AuthContext } from "../../services/auth/AuthContext";
import moment from "moment";

if (isIOS) import("../../assets/styles/ios.scss");
if (isAndroid) import("../../assets/styles/android.scss");

const PassbookInfo = ({ reset, confirm }) => {
  const [user, setUserData] = useState(getStorageData("userData"));
  const [error, setError] = useState(false);
  let [passbook, setPassbook] = useState({
    account_name: "",
    account_prefix: "",
    account_first_name: "",
    account_last_name: "",
    bank_code: "",
    bank_name_en: "",
    bank_name_th: "",
    branch: "",
    branch_code: "",
    img_src: "",
    image_base64: "",
  });
  const [loginData, setLoginData] = useState(getStorageData("loginData"));
  const [open, setOpen] = useState(false);
  const [prefix, setPrefix] = useState("");

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const bookBankInfo = getStorageData("passbookData");
    if (bookBankInfo && bookBankInfo.passbook_info) {
      const userNameSplit =
        bookBankInfo.passbook_info?.info?.account_name.split(" ");

      const prefix = userNameSplit.length > 2 ? userNameSplit[0] : "-";
      const firstName =
        userNameSplit.length > 2
          ? userNameSplit[1]
          : userNameSplit[userNameSplit.length - 2];
      const lastName = userNameSplit[userNameSplit.length - 1] ?? "";

      setPassbook({
        account_name: bookBankInfo.passbook_info.info.account_name ?? "",
        account_prefix: prefix,
        account_first_name: firstName,
        account_last_name: lastName,
        account_number: bookBankInfo.passbook_info.info.account_number ?? "",
        bank_code: bookBankInfo.passbook_info.info.bank_code ?? "",
        bank_name_en: bookBankInfo.passbook_info.info.bank_name_en ?? "",
        bank_name_th: bookBankInfo.passbook_info.info.bank_name_th ?? "",
        branch: bookBankInfo.passbook_info.info.branch ?? "",
        branch_code: "",
        img_src: bookBankInfo.passbook_info.img_src ?? "",
        image_base64: bookBankInfo.passbook_info.image_base64 ?? "",
      });
    }
  }, []);

  const buildAllData = async () => {
    // ...user.personal_info
    const mapData = {
      pid: user.personal_info.pid,
      pid_laser_code: user.personal_info.pid_laser_code,
      phone_number: user.personal_info.phone_number,
      email: user.personal_info.email,
      prefix: user.personal_info.prefix,
      first_name: user.personal_info.first_name,
      last_name: user.personal_info.last_name,
      request_by_device: user.personal_info.request_by_device,
      staff_name: "",
      birth_date: user.personal_info.birth_date,
      expire_date: user.personal_info.expire_date,
      id_card_created_date: moment(
        user.verification_info.ocr_result.date_of_issue_en,
        "DD MMM. YYYY"
      ).format("YYYY-MM-DD"),
      image_base64: user.verification_info.pid_base64,
      present_address: {
        house_no: user.personal_info.present_address.house_no,
        village_no: user.personal_info.present_address.village_no,
        road: user.personal_info.present_address.road,
        sub_district: user.personal_info.present_address.sub_district,
        district: user.personal_info.present_address.district,
        province: user.personal_info.present_address.provide,
        zip_code: user.personal_info.present_address.zip_code,
      },
      permanent_address: {
        house_no: user.personal_info.permanent_address.house_no,
        village_no: user.personal_info.permanent_address.village_no,
        road: user.personal_info.permanent_address.road,
        sub_district: user.personal_info.permanent_address.sub_district,
        district: user.personal_info.permanent_address.district,
        province: user.personal_info.permanent_address.provide,
        zip_code: user.personal_info.permanent_address.zip_code,
      },
      company_address: {
        house_no: user.personal_info.company_address.house_no,
        village_no: user.personal_info.company_address.village_no,
        alley: user.personal_info.company_address.alley,
        sub_district: user.personal_info.company_address.sub_district,
        district: user.personal_info.company_address.district,
        province: user.personal_info.company_address.provide,
        zip_code: user.personal_info.company_address.zip_code,
      },
      job_info: {
        occupation: user.personal_info.job_info.occupation,
        company: user.personal_info.job_info.company,
        business_type: user.personal_info.job_info.business_type,
        job_position: user.personal_info.job_info.job_position,
      },
      bookbank_info: {
        account_prefix: passbook.account_prefix,
        account_first_name: passbook.account_first_name,
        account_last_name: passbook.account_last_name,
        account_number: passbook.account_number,
        bank_name: passbook.bank_name_th,
        branch_code: passbook.branch_code,
        branch_name: passbook.branch,
        image_base64: passbook.image_base64,
      },
      questionnaire_info: {
        ...user.questionnaire_info,
      },
    };

    return mapData;
  };

  const handlePassbook = async () => {
    if (
      passbook.account_name === "" ||
      passbook.account_number === "" ||
      passbook.account_number.length < 13 ||
      passbook.bank_code === "" ||
      passbook.bank_name_th === "" ||
      passbook.branch === "" ||
      passbook.branch_code === ""
    ) {
      setError(true);
      return false;
    } else {
      setError(false);
    }
    //prefix check needed
    setOpen(true);
    const data = await buildAllData();
    try {
      const createAccountResponse = await createAccount(data);
      if (
        createAccountResponse.message ===
          "The account has been created successfully." ||
        createAccountResponse.status === "success"
      ) {
        confirm();
        navigate("/passbook-success");
      } else {
        if (createAccountResponse.detail === "Signature has expired") {
          const newToken = await refreshToken(loginData.uuid);
          if (newToken.access_token) {
            localStorage.setItem("access_token", newToken.access_token);
            localStorage.setItem("refresh_token", newToken.access_token);
            setStorageData("loginData", {
              ...loginData,
              token: { access_token: newToken.access_token },
            });
            setLoginData({
              ...loginData,
              token: { access_token: newToken.access_token },
            });
            setOpen(false);
            return;
          } else {
            dispatch({ type: "LOGOUT" });
          }
        }
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const onInputChange = (e) => {
    switch (e.target.name) {
      case "accountBranchCode":
        setPassbook({
          ...passbook,
          branch_code: e.target.value,
        });
        break;
      case "accountBranch":
        setPassbook({
          ...passbook,
          branch: e.target.value,
        });
        break;
      case "accountNumber":
        const formatValue = e.target.value
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{3})(\d{1})(\d{5})(\d{1})/g, "$1-$2-$3-$4")
          .replace(/^(\d{3})(\d{1})(\d{5})/g, "$1-$2-$3")
          .replace(/^(\d{3})(\d{1})/g, "$1-$2")
          .substr(0, 13)
          .trim();

        // console.log('accountNumber', formatValue)
        setPassbook({
          ...passbook,
          account_number: formatValue,
        });
        break;
      case "accountName":
        const fullNameFormat = e.target.value.split(" ");
        const prefix = fullNameFormat.length > 2 ? fullNameFormat[0] : "-";
        const firstName =
          fullNameFormat.length > 2
            ? fullNameFormat[1]
            : fullNameFormat[0] ?? "-";
        const lastName =
          fullNameFormat.length > 2
            ? fullNameFormat[fullNameFormat.length - 1]
            : fullNameFormat[1] ?? "-";

        setPassbook({
          ...passbook,
          account_name: e.target.value,
          account_prefix: prefix,
          account_first_name: firstName,
          account_last_name: lastName,
        });
        break;
      default:
    }
  };

  // Signout Modal Variables
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const handleLogout = () => {
    reset();
    navigate("/");
  };
  return (
    <div className={`${isIOS ? "t" : "a"}`}>
      {open && <LoadScreen />}
      {openModal && (
        <CancelModal
          open={openModal}
          logout={handleLogout}
          handleClose={handleClose}
          note={"CANCEL"}
          status={"ยกเลิกการเพิ่มบัญชีคู่ฝาก"}
        />
      )}
      <div className={`${isIOS ? "t-container" : "a-container"}`}>
        <div className={`${isIOS ? "header-box" : "android-header-box-10vh"}`}>
          <div className="header-layout-1">
            <div>&nbsp;</div>
            <div>
              <p>เพิ่มบัญชีคู่ฝาก</p>
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
        <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
          <div className="content-layout-5">
            <div className="user-img">
              <img src={passbook.img_src} alt="snapshot" />
            </div>
            <div className="section">
              <p>ธนาคาร</p>
              <div className="input">
                <input type="text" value={passbook.bank_name_th} disabled />
              </div>
            </div>
            <div className="section">
              <p>รหัสสาขา</p>
              <div className="input">
                <input
                  name="accountBranchCode"
                  type="text"
                  value={passbook.branch_code}
                  required
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="section">
              <p>สาขา</p>
              <div className="input">
                <input
                  name="accountBranch"
                  type="text"
                  value={passbook.branch}
                  required
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="section">
              <p>เลขที่บัญชีธนาคาร</p>
              <div className="input">
                <input
                  name="accountNumber"
                  type="text"
                  value={passbook.account_number}
                  required
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="section">
              <p>ชื่อบัญชี</p>
              <div className="input">
                <input
                  name="accountName"
                  type="text"
                  value={passbook.account_name}
                  required
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            {error && (
              <div className="error-text">
                <h4
                  style={{
                    margin: "0px 0px 16px",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  โปรดกรอกข้อมูลให้ครบและลองอีกครั้ง
                </h4>
              </div>
            )}
            <div className="button-box">
              <div className="button-layout-1">
                <button onClick={handleOpen}>ยกเลิก</button>
                <button onClick={handlePassbook}>ยืนยัน</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassbookInfo;
