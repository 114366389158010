import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import logo from "../../assets/images/clc-logo.png";
import LoadScreen from "../../components/LoadScreen";
import JWE from "../../services/jwe/jwe";

import { requestOTP, verifyOTP } from "../../services/api/login.service";
import { isIOS, isAndroid } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MobileIcon } from "../../assets/icons/mobile.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { AuthContext } from "../../services/auth/AuthContext";
import { PIDFormat, telephoneFormat, genUUID } from "../../utils/common";
import ErrorOccurred from "../../components/ErrorOccurred";

if (isIOS) import("../../assets/styles/ios.scss");
if (isAndroid) import("../../assets/styles/android.scss");

const Login = () => {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pidState, setPidState] = useState(true);
  const [telState, setTelState] = useState(false);
  const [otpState, setOtpState] = useState(false);
  const [pid, setPid] = useState("");
  const [telephone, setTelephone] = useState("");
  const [otp, setOTP] = useState("");
  const [refNo, setRefNo] = useState("");
  const [token, setToken] = useState("");
  const [uuid, setUuid] = useState(genUUID());
  const [error, setError] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [timer, setTimer] = useState(60);
  const [openErrorOccurred, setOpenErrorOccurred] = useState(false);

  const onInputChange = (event, type) => {
    switch (type) {
      case "pid":
        if (event.target.value.length <= 17) {
          let input = event.target.value.replace(/[^\d\s]/gi, "");
          let format = PIDFormat(
            input,
            event.target.value.length > pid.length ? "add" : "subtract"
          );
          setPid(format);
        }
        break;
      case "tel":
        if (event.target.value.length <= 12) {
          let input = event.target.value.replace(/[^\d-]/gi, "");
          let format = telephoneFormat(
            input,
            event.target.value.length > telephone.length ? "add" : "subtract"
          );
          setTelephone(format);
        }
        break;
      case "otp":
        if (event.target.value.length <= 6)
          setOTP(event.target.value.replace(/[^\d]/gi, ""));
        break;
      default:
    }
  };

  useEffect(() => {
    if (telState) if (errorCount >= 3) navigate("/login-failed");
    if (otpState) if (errorCount >= 5) navigate("/otp-failed");
  }, [errorCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const reqOTP = async () => {
    setLoading(true);
    const payloadRequestOtp = {
      pid: pid.replaceAll(" ", ""),
      phone_number: telephone.replaceAll("-", ""),
    };
    const response = await requestOTP(payloadRequestOtp, uuid);
    if (response.status === "success") {
      setRefNo(response.refno);
      setErrorCount(0);
      setError(false);
      setTimer(60);
      setTelState(false);
      setOtpState(true);
      setLoading(false);
    } else {
      setErrorCount(errorCount + 1);
      setError(true);
      setLoading(false);
    }
  };

  const verify = async () => {
    setLoading(true);
    const payloadVerifyOtp = {
      pid: pid.replaceAll(" ", ""),
      phone_number: telephone.replaceAll("-", ""),
      pin: otp,
      refno: refNo,
    };
    const response = await verifyOTP(payloadVerifyOtp, uuid);
    if (response.status === "success") {
      localStorage.setItem(
        "loginData",
        JSON.stringify({
          uuid: uuid,
          pid: pid,
          token: response.data.access_token,
        })
      );
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      setErrorCount(0);
      setError(false);
      setLoading(false);
      dispatch({ type: "LOGIN", payload: { tel: telephone } });
      navigate("/");
    } else {
      setErrorCount(errorCount + 1);
      setError(true);
      setLoading(false);
    }
  };

  const bypass = () => {
    localStorage.setItem(
      "loginData",
      JSON.stringify({ uuid: genUUID(), pid: "3293431770773" })
    );
    dispatch({ type: "LOGIN", payload: { tel: "092722852" } });
    navigate("/");
  };

  return (
    <div className="login" onLoad={() => setLoading(false)}>
      {/* <div className="popup" id="popup1">
                <button onClick={() => { document.getElementById("popup1").remove(); }}>X</button>
                <button onClick={() => bypass()}>bypass login</button>
            </div> */}
      {openErrorOccurred && (
        <ErrorOccurred
          open={openErrorOccurred}
          handleClose={() => {
            setOpenErrorOccurred(false);
          }}
        >
          <div>มีบางอย่างผิดพลาด กรุณาลองอีกครั้ง</div>
        </ErrorOccurred>
      )}
      {loading && <LoadScreen />}
      <div className="container">
        <div className="white-box">
          <div className="content-box">
            <div className="content-1">
              <img src={logo} alt="test" className="login-logo" />
            </div>
            <div className="content-2">
              {pidState && (
                <>
                  <p>เข้าสู่ระบบ</p>
                  <div className="input-box">
                    <div className="input-field">
                      <svg
                        className="icon"
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 7C5.375 7 4.5 6.125 4.5 5C4.5 3.90625 5.375 3 6.5 3C7.59375 3 8.5 3.90625 8.5 5C8.5 6.125 7.59375 7 6.5 7ZM14.5 6.25C14.9062 6.25 15.25 6.59375 15.25 7C15.25 7.4375 14.9062 7.75 14.5 7.75H11.5C11.0625 7.75 10.75 7.4375 10.75 7C10.75 6.59375 11.0625 6.25 11.5 6.25H14.5ZM7.5 8C8.875 8 10 9.125 10 10.5C10 10.7812 9.75 11 9.5 11H3.5C3.21875 11 3 10.7812 3 10.5C3 9.125 4.09375 8 5.5 8H7.5ZM14.5 3.75C14.9062 3.75 15.25 4.09375 15.25 4.5C15.25 4.9375 14.9062 5.25 14.5 5.25H11.5C11.0625 5.25 10.75 4.9375 10.75 4.5C10.75 4.09375 11.0625 3.75 11.5 3.75H14.5ZM16 0C17.0938 0 18 0.90625 18 2V12C18 13.125 17.0938 14 16 14H2C0.875 14 0 13.125 0 12V2C0 0.90625 0.875 0 2 0H16ZM16.5 12V2C16.5 1.75 16.25 1.5 16 1.5H2C1.71875 1.5 1.5 1.75 1.5 2V12C1.5 12.2812 1.71875 12.5 2 12.5H16C16.25 12.5 16.5 12.2812 16.5 12Z"
                          fill="#373737"
                        />
                      </svg>
                      <input
                        placeholder="หมายเลขบัตรประชาชน"
                        className="input"
                        type="text"
                        pattern="[^\d-]"
                        value={pid}
                        onChange={(e) => onInputChange(e, "pid")}
                      />
                    </div>
                    <div className="under-input">
                      <div>
                        {!error && <p>&nbsp;</p>}
                        {error && <p className="danger">เกิดข้อผิดพลาด</p>}
                      </div>
                    </div>
                  </div>
                  <div className="content-3">
                    <Button
                      onClick={() => {
                        setPidState(false);
                        setTelState(true);
                      }}
                      disabled={pid.length !== 17}
                      className="verify-button"
                      variant="contained"
                    >
                      เข้าสู่ระบบ
                    </Button>
                  </div>
                </>
              )}
              {telState && (
                <>
                  <div className="content-2">
                    <p>เข้าสู่ระบบ</p>
                    <div className="input-box">
                      <div className="input-field">
                        <MobileIcon className="icon" />
                        <input
                          placeholder="หมายเลขโทรศัพท์"
                          className="input"
                          type="text"
                          value={telephone}
                          onChange={(e) => onInputChange(e, "tel")}
                        />
                      </div>
                      <div className="helper-text">
                        {!error && <p>&nbsp;</p>}
                        {error && (
                          <p className="danger">
                            หมายเลขไม่ถูกต้อง กรุณากรอกใหม่ ({errorCount}/3)
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="content-3">
                    <Button
                      onClick={() => reqOTP()}
                      disabled={telephone.length !== 12}
                      className="verify-button"
                      variant="contained"
                    >
                      เข้าสู่ระบบ
                    </Button>
                  </div>
                </>
              )}
              {otpState && (
                <>
                  <div className="content-2">
                    <p>ยืนยัน OTP</p>
                    <div className="input-box">
                      <div className="input-field">
                        <LockIcon className="icon" />
                        <input
                          placeholder="รหัส OTP"
                          className="input"
                          type="text"
                          value={otp}
                          onChange={(e) => onInputChange(e, "otp")}
                        />
                      </div>
                      {!error && (
                        <>
                          <div className="under-input">
                            <div>
                              <p>ไม่ได้รับรหัส OTP</p>
                            </div>
                            <div>
                              <button
                                onClick={() => reqOTP()}
                                disabled={timer > 0}
                                className="try-button"
                              >
                                ขอรหัสใหม่ {timer > 0 ? "(" + timer + ")" : ""}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {error && (
                        <>
                          <div className="helper-text">
                            <div>
                              <p className="danger">
                                OTP ไม่ถูกต้อง กรุณากรอกใหม่ ({errorCount}/5)
                              </p>
                            </div>
                            <div>
                              <button
                                onClick={() => reqOTP()}
                                disabled={timer > 0}
                                className="try-button"
                              >
                                ขอรหัสใหม่ {timer > 0 ? "(" + timer + ")" : ""}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="content-3">
                    <Button
                      onClick={() => verify()}
                      className="verify-button"
                      variant="contained"
                    >
                      ยืนยัน
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {!pidState && (
          <>
            <div className="footer">
              <p className="white-text">เปลี่ยนหมายเลขโทรศัพท์</p>
              <p className="white-text">โปรดติดต่อเจ้าหน้าที่</p>
              <p className="white-text">02-678-0666 ต่อ 726-728</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
