import React from "react";
import TextField from '@mui/material/TextField';
import 'moment/locale/th';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const style = {
    "& .MuiInputBase-root": {
        fontFamily: ['Kanit', 'sans-serif'],
    },
    "& .MuiInput-root": {
        fontFamily: ['Kanit', 'sans-serif'],
    },
    border: 'none',
    fontFamily: ['Kanit', 'sans-serif'],
    margin: '0px',
    p: 0,
};

const DatePicker = ({ value, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="th">
            <MobileDatePicker
                inputFormat="DD MMMM YYYY"
                value={value}
                onChange={onChange}
                renderInput={(params) => <TextField variant="standard" {...params} sx={style} />}
            />
        </LocalizationProvider>
    )
}

export default DatePicker