import React, { useContext, useEffect, useState } from "react";
import LoadScreen from "../../components/LoadScreen";
import providerService from "../../services/provider.service";
import CancelModal from "../../components/CancelModal";
import NdidMsg from "../../components/NdidMsg";

import { environment } from "../../utils/environment";
import { setNDIDTimestamp, getNDIDTimestamp } from "../../services/api/ndid.service";
import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from 'react-device-detect';
import { genRefID, genTimestamp } from "../../utils/common";
import { encryptData, decryptData } from "../../services/api/ndid.service";
import { getAccount } from "../../services/api/account.service";
import { getStorageData, setStorageData } from "../../utils/storage";
import { refreshToken } from "../../services/api/auth.service";
import { AuthContext } from "../../services/auth/AuthContext";

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const NdidProvider = ({ reset, confirm }) => {
    const [loading, setLoading] = useState(false);
    const [refId, setRefId] = useState("");
    const [dataProvide, setDataProvider] = useState({
        on_the_fly: [],
        idp: [],
    });
    const [user, setUserData] = useState(getStorageData("userData"));
    const [loginData, setLoginData] = useState(getStorageData("loginData"));

    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
        try {
            setLoading(true)
            const payloadIdentityComplying = {
                flow_id: "custInfo",
                namespace: "citizen_id",
                identifier: user.personal_info.pid,
                show_on_the_fly_list: true,
                include_logo: true
            }

            const response = await providerService.providerList(payloadIdentityComplying);
            if (response.status === "success") {
                const data = response?.data;
                setDataProvider({
                    on_the_fly: data.on_the_fly,
                    idp: data.idp
                });

                if (data.idp.length !== 0) {
                    let element = document.getElementById("idp_box");
                    if (element !== null) element.classList.add("bordered");
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false)
        }
    }

    const onSelectProvider = async (item, on_the_fly) => {
        try {
            setLoading(true)
            const payloadRequestProvider = {
                idp_id_list: [item.node_id],
                on_the_fly: on_the_fly,
                ndid_info: {
                    marketing_name_th: item.marketing_name_th,
                    image_format: item.image_format,
                    marketing_name_en: item.marketing_name_en,
                    on_the_fly_support: item.on_the_fly_support,
                    logo_image: item.logo_image,
                    node_id: item.node_id,
                }
            }

            const response = await providerService.requestAPI(payloadRequestProvider);

            if (response.status === "success") {
                let userData = getStorageData("userData");
                const account = { ...userData.account, timestamp: { ...response, timestamp: response.data.timestamp,  ndid_info: payloadRequestProvider.ndid_info } }
                setStorageData("userData", { account });
                confirm();
                navigate("/ndid-status");
            } else {
                if (response.detail === "Signature has expired") {
                    const newToken = await refreshToken(loginData.uuid);
                    if (newToken.access_token) {
                        localStorage.setItem("access_token", newToken.access_token);
                        localStorage.setItem("refresh_token", newToken.access_token);
                        setStorageData("loginData", { ...loginData, token: { access_token: newToken.access_token } });
                        setLoginData({ ...loginData, token: { access_token: newToken.access_token } });
                        setLoading(false);
                        return;
                    } else {
                        dispatch({ type: "LOGOUT" });
                    }
                } else {
                    setLoading(false);
                    return;
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false)
        }
    };

    // back to NDID Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const [openMSG, setOpenMSG] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleOpenMSG = () => setOpenMSG(true);
    const handleLogout = () => {
        reset();
        navigate("/ndid");
    }
    return (
        <div className="t">
            {loading && <LoadScreen />}
            <div className="t-container">
                {!loading && <>
                    {openModal && <CancelModal open={openModal} logout={handleOpenMSG} handleClose={handleClose} note={"CANCEL_NDID"} status={"ยกเลิกการเลือกผู้ให้บริการ NDID"} />}
                    {openMSG && <NdidMsg open={openMSG} logout={handleLogout}>
                        <h3>ท่านได้ยกเลิกรายการคำขอ</h3>
                        <p>หรือเปลี่ยนผู้ให้บริการยืนยันตัวตน</p>
                        <p>กรุณาเลือกผู้ให้บริการรายอื่น</p>
                    </NdidMsg>}
                    <div className="header-box">
                        <div className="header-layout-2">
                            <p>เลือกผู้ให้บริการ</p>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="content-layout-2-ndid">
                            <div className="header-text">
                                <p>กรุณาเลือกผู้ให้บริการยืนยันตัวตน</p>
                                <p>ที่เคยลงทะเบียนไว้เพื่อยืนยันตัวตน</p>
                                <p>และมีโมบายแอปพลิเคชันของผู้ให้บริการ</p>
                                <p>ยืนยันตัวตนดังกล่าว</p>
                            </div>
                            <div className="center-sec">
                                {dataProvide.idp.length !== 0 ?
                                    <div className="provider-box bordered">
                                        <div className="provider-head">
                                            <p>ผู้ให้บริการที่ท่านได้ลงทะเบียน NDID ไว้แล้ว</p>
                                            <p>สามารถยืนยันตัวตนได้ทันที</p>
                                        </div>
                                        <div className="provider-picker">
                                            <div className="grid-box">
                                                {dataProvide.idp.map((item, index) => (
                                                    <div id={index} className="eachImg" key={index}>
                                                        <button id={index} className="logo-button" onClick={() => onSelectProvider(item, false)}>
                                                            <img id={index} src={`data:image/${item.image_format};base64,${item.logo_image}`} alt="logo" width="72" />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    : <div id="idp_box" className="provider-box">
                                        <div className="provider-head">
                                            <p>ผู้ให้บริการที่ท่านได้ลงทะเบียน NDID ไว้แล้ว</p>
                                            <p>สามารถยืนยันตัวตนได้ทันที</p>
                                        </div>
                                        <div className="provider-picker">
                                            <div className="grid-box">
                                                <div className="eachImg">
                                                    <div className="none-logo"><p>ไม่พบ</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="provider-box">
                                    <div className="provider-head">
                                        <p>ผู้ให้บริการที่จะนำท่านลงทะเบียน NDID ก่อน</p>
                                        <p>จากนั้นจึงยืนยันตัวตนได้</p>
                                    </div>
                                    <div className="provider-picker">
                                        <div className="grid-box">
                                            {dataProvide.on_the_fly.map((item, index) => (
                                                <div id={index} className="eachImg" key={index}>
                                                    <button id={index} className="logo-button" onClick={() => onSelectProvider(item, true)} >
                                                        <img id={index} src={`data:image/${item.image_format};base64,${item.logo_image}`} alt="logo" width="72" />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button-box" style={{ paddingBottom: '48px' }}>
                                <div className="button-layout-2">
                                    <button className="button-white" onClick={handleOpen}>ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default NdidProvider