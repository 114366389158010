import { useEffect, useState } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';

if (isIOS) import('../assets/styles/ios.scss');
if (isAndroid) import('../assets/styles/android.scss');

const Timer = ({ start }) => {
    const calculateTimeLeft = () => {
        const thisTime = new Date().getTime();
        const endTime = new Date(start).getTime();
        const countTime = 60 * 60 * 1000;

        let difference = endTime + countTime - thisTime;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                // days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                // hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
        return timeLeft;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval, index) => {
        if (!timeLeft[interval]) {
            return;
        }
        if (index === 1) {
            timerComponents.push(
                ` : ${timeLeft[interval]}`
            );
        } else {
            timerComponents.push(
                `${timeLeft[interval]}`
            );
        }
    });

    return (
        <>
            <div className="timer-container">
                <div className="clock-logo">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C9.30859 0 8.75 0.558594 8.75 1.25V3.75C8.75 4.44141 9.30859 5 10 5C10.6914 5 11.25 4.44141 11.25 3.75V2.60547C14.7969 3.19922 17.5 6.28516 17.5 10C17.5 14.1406 14.1406 17.5 10 17.5C5.85938 17.5 2.5 14.1406 2.5 10C2.5 7.90234 3.35938 6.00391 4.75 4.64453C5.24219 4.16016 5.25 3.37109 4.76953 2.875C4.28906 2.37891 3.49609 2.375 3 2.85547C1.15234 4.67188 0 7.20312 0 10C0 15.5234 4.47656 20 10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0ZM7.53906 6.21094C7.17188 5.84375 6.57813 5.84375 6.21484 6.21094C5.85156 6.57812 5.84766 7.17187 6.21484 7.53516L9.33984 10.6602C9.70703 11.0273 10.3008 11.0273 10.6641 10.6602C11.0273 10.293 11.0312 9.69922 10.6641 9.33594L7.53906 6.21094Z" fill="white" />
                    </svg>
                </div>
                <div className="clock-text">
                    <p style={{ width: "30%" }}>ภายใน</p>
                    <p style={{ width: "50%" }}>{timerComponents.length ? timerComponents : "-"}</p>
                    <p style={{ width: "20%" }}>นาที</p>
                </div>
            </div>
        </>
    )
}

export default Timer