import httpClient from "./httpClient";
import { environment } from "../utils/environment";

const token = function (ctx) {
  return new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem("access_token");
    resolve(accessToken);
  });
};

const verifyIdCard = async (formData) => {
  let myHeaders = new Headers();
  myHeaders.append("x-api-key", environment.xApiKey);
  myHeaders.append("Authorization", "Bearer " + (await token()));

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/ocr/thai-id-card-front`,
    requestOptions
  );
  return response.json();
};

const verifyVideo = async (body) => {
  const url = `frs/live-detect`;
  const data = await httpClient().post(url, body);
  return data;
};

const verifyPassbook = async (formData) => {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + (await token()));

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow",
  };

  const response = await fetch(
    `${environment.baseUrl}/api/v1/ocr/book-bank`,
    requestOptions
  );
  return response.json();
};

const faceCompare = async (body) => {
  const url = `frs/face-compare`;
  const data = await httpClient().post(url, body);
  return data;
};

const verifyService = {
  verifyIdCard,
  verifyVideo,
  verifyPassbook,
  faceCompare,
};

export default verifyService;
