import React, { useState } from "react";
import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { ReactComponent as Pic1 } from "../../assets/icons/opacc_1.svg";
import { ReactComponent as Pic2 } from "../../assets/icons/opacc_2.svg";
import { ReactComponent as Pic3_1 } from "../../assets/icons/opacc_3_1.svg";
import { ReactComponent as Pic3_2 } from "../../assets/icons/opacc_3_2.svg";
import { ReactComponent as Pic4_1 } from "../../assets/icons/opacc_4_1.svg";
import { ReactComponent as Pic4_2 } from "../../assets/icons/opacc_4_2.svg";
import { ReactComponent as Pic5 } from "../../assets/icons/opacc_5.svg";
import { ReactComponent as Pic6_1 } from "../../assets/icons/opacc_6_1.svg";
import { ReactComponent as Pic6_2 } from "../../assets/icons/opacc_6_2.svg";
import { ReactComponent as Pic6_3 } from "../../assets/icons/opacc_6_3.svg";
import { ReactComponent as Pic6_4 } from "../../assets/icons/opacc_6_4.svg";
import { ReactComponent as Pic7 } from "../../assets/icons/opacc_7.svg";
import { ReactComponent as Pic8_1 } from "../../assets/icons/opacc_8_1.svg";
import { ReactComponent as Pic8_2 } from "../../assets/icons/opacc_8_2.svg";
import { ReactComponent as Pic8_3 } from "../../assets/icons/opacc_8_3.svg";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

if (isIOS) import('../../assets/styles/ios.scss');
if (isAndroid) import('../../assets/styles/android.scss');

const MyAccordion = ({ header, content }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    const style = {
        accordion: {
            width: "100%",
            boxShadow: "none",
            margin: "8px",
        },
        accordionSummary: {
            border: "1px solid #DFDFDF",
            borderRadius: "8px",
            backgroundColor: isExpanded ? "#4065A9" : "transparent",
            text: {
                color: isExpanded ? "#FFF" : "#272525",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
            },
            arrow: {
                color: isExpanded ? "#FFF" : "#272525",
            }
        },
        accordionDetails: {
            border: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
            padding: "12px"
        }
    }

    return (
        <Accordion
            expanded={isExpanded}
            onChange={toggleAccordion}
            style={style.accordion}
            sx={{
                '&:before': {
                    display: 'none',
                }
            }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={style.accordionSummary.arrow} />}
                style={style.accordionSummary}
            >
                <div style={{ display: "flex" }}>
                    <p style={style.accordionSummary.text}>{header}</p>
                </div>
            </AccordionSummary>
            <AccordionDetails
                style={style.accordionDetails}>
                {content}
            </AccordionDetails>
        </Accordion>
    );
};

const OpenAccount = () => {
    const navigate = useNavigate();
    return (
        <div className={`${isIOS ? "t" : "a"}`}>
            <div className={`${isIOS ? "t-container" : "a-container"}`}>
                <div className={`${isIOS ? "header-box" : "android-header-box-10vh"}`}>
                    <div className="header-layout-1">
                        <div onClick={() => navigate("/faq")}>
                            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.968751 12.0312C0.359376 11.4687 0.359376 10.4844 0.968751 9.92187L9.96875 0.921874C10.5313 0.312499 11.5156 0.3125 12.0781 0.921875C12.6875 1.48437 12.6875 2.46875 12.0781 3.03125L4.15625 11L12.0781 18.9219C12.6875 19.4844 12.6875 20.4687 12.0781 21.0312C11.5156 21.6406 10.5313 21.6406 9.96875 21.0312L0.968751 12.0312Z" fill="white" />
                            </svg>
                        </div>
                        <div>
                            <p>ขั้นตอนการเปิดบัญชี</p>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className={`${isIOS ? "content-box" : "android-content-box"}`}>
                    <div className="faq">
                        <Accordion expanded style={{ width: "100%", boxShadow: "none", margin: "16px" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                                sx={{
                                    borderRadius: "8px",
                                    backgroundColor: "#4065A9",
                                }}
                            >
                                <div style={{ display: "flex" }}>
                                    <DescriptionOutlinedIcon style={{ color: "#FFFFFF", marginRight: "8px" }} />
                                    <p style={{ color: "#FFFFFF" }}>การเตรียมตัว และเอกสารจำเป็น</p>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul>
                                    <li>บัตรประจำตัวประชาชน</li>
                                    <li>หมายเลขโทรศัพท์มือถือ</li>
                                    <li>สมุดบัญชีคู่ฝาก (ออมทรัพย์)</li>
                                    <li>เปิดในเวลาทำการ 09:30-16:30 (หยุดตามราชการ และนักขัตฤกษ์)</li>
                                    <li>อยู่ในที่มีแสงสว่างเพียงพอ</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <MyAccordion
                            header={"1. การยอมรับและยินยอมเงื่อนไข"}
                            content={<>
                                <Pic1 style={{ margin: "12px 0px" }} />
                                <p>กรุณาอ่านและศึกษาเงื่อนไขก่อนกดยอมรับ ซึ่งมีเอกสารทั้ง 2 ฉบับ ดังนี้</p>
                                <ul>
                                    <li>การยอมรับ และยินยอมข้อกำหนด และเงื่อนไขการใช้งาน การขอนำข้อมูลไปใช้</li>
                                    <li>การยอมรับ และยินยอมข้อมูลผลิตภัณฑ์ เงินฝาก</li>
                                </ul>
                                <p>หากท่านไม่ยินยอมและยอมรับเงื่อนไข กรุณาติดต่อเจ้าหน้าที่ โทร 02-678-0666 ต่อ 726-728</p>
                            </>}
                        />
                        <MyAccordion
                            header={"2. ทำแบบสอบถามการเปิดบัญชี"}
                            content={<>
                                <Pic2 style={{ margin: "12px 0px" }} />
                                <p>กรุณาตอบแบบสอบถามตามความจริง ซึ่งมีแบบสอบถาม 2 ฉบับ ดังนี้</p>
                                <ul>
                                    <li>แบบสอบถามเพื่อหาข้อบ่งชี้การเป็นบุคคลสหรัฐฯ</li>
                                    <li>แบบสอบถามวัตถุประสงค์ในการทําธุรกรรม</li>
                                </ul>
                                <p>หากท่านไม่ผ่านแบบสอบถาม กรุณาติดต่อเจ้าหน้าที่ โทร 02-678-0666 ต่อ 726-728</p>
                            </>}
                        />
                        <MyAccordion
                            header={"3. สแกนบัตรประชาชน"}
                            content={<>
                                <p>เริ่มขั้นตอนการยืนยันข้อมูลโดยใช้บัตรประชาชน กรุณาทำตามขั้นตอน ดังนี้</p>
                                <Pic3_1 style={{ margin: "12px 0px" }} />
                                <ul>
                                    <li>วางบัตรในแนวนอน</li>
                                    <li>อยู่ในที่ที่มีแสงสว่างเพียงพอ</li>
                                    <li>กด “อนุญาต” หากมีข้อความแจ้งเตือนเพื่อเข้าถึงกล้องถ่ายรูปในโทรศัพท์ของคุณ</li>
                                    <li>กดปุ่มถ่ายรูป</li>
                                </ul>
                                <Pic3_2 style={{ margin: "12px 0px" }} />
                            </>}
                        />
                        <MyAccordion
                            header={"4. สแกนใบหน้ายืนยันตัวตน"}
                            content={<>
                                <p>เริ่มขั้นตอนการยืนยันตนตัวโดยใช้ใบหน้า โดยมีเวลา 5 วินาทีในการบันทึกวิดีโอ กรุณาทำตามขั้นตอน ดังนี้</p>
                                <Pic4_1 style={{ margin: "12px 0px" }} />
                                <ol>
                                    <li>กดเริ่มบันทึกวิดีโอ</li>
                                    <li>เริ่มจากใบหน้าตรง</li>
                                    <li>หันซ้าย และหยุด 1 วินาที</li>
                                    <li>หันขวา และหยุด 1 วินาที</li>
                                    <li>หันหน้าตรง และกดหยุดบันทึก</li>
                                </ol>
                                <Pic4_2 style={{ margin: "12px 0px" }} />
                                <p>หากมีหน้าต่างแจ้งเตือนข้อมูลไม่ถูกต้อง กรุณาตรวจสอบ ดังนี้</p>
                                <ul>
                                    <li>อยู่ในที่ที่มีแสงสว่างเพียงพอ</li>
                                    <li>หันหน้าแล้วหยุดข้างละ 1 วินาที</li>
                                    <li>ลองถอดแว่นตา หรือสิ่งบดบังใบหน้า</li>
                                </ul>
                                <p>และกรุณาลองใหม่ ได้สูงสุดอีก 2 ครั้ง หากยังไม่ผ่าน กรุณาติดต่อเจ้าหน้าที่ โทร 02-678-0666 ต่อ 726-728</p>
                            </>}
                        />
                        <MyAccordion
                            header={"5. การยืนยันข้อมูล"}
                            content={<>
                                <p>ขั้นตอนการยืนยันข้อมูล ดังนี้</p>
                                <ul>
                                    <li>กรุณาตรวจสอบความถูกต้องของข้อมูล</li>
                                    <li>ท่านสามารถแก้ไขข้อมูลให้ถูกต้องได้</li>
                                    <li>กรอกรหัสหลังบัตรประชาชนเพื่อยืนยันข้อมูล</li>
                                    <li>กรอกข้อมูลการติดต่อ</li>
                                </ul>
                                <Pic5 style={{ margin: "12px 0px" }} />
                            </>}
                        />
                        <MyAccordion
                            header={"6. ยืนยันตัวตนผ่าน NDID"}
                            content={<>
                                <p>ขั้นตอนนี้จำเป็นจะต้องใช้แอพพลิเคชั่นทางการเงินอื่นๆ โดยมีขั้นตอนดังนี้</p>
                                <Pic6_1 style={{ margin: "12px 0px" }} />
                                <p>1. การยินยอมเปิดเผยข้อมูลส่วนตัว เพื่อการยืนยันตัวตน</p>
                                <Pic6_2 style={{ margin: "12px 0px" }} />
                                <p>2. เลือกแอพพลิเคชั่นที่ท่านต้องการจะดำเนินการต่อ หากท่านเคยมีข้อมูลการยืนยันตัวกับผู้ให้บริการแล้ว จะแสดงอยู่ในกรอบด้านบน (ภาพซ้าย) หากท่านไม่เคยมีข้อมูลการยืนยันกับผู้ให้บริการใดเลย ท่านสามารถเลือกผู้ให้บริการใดก็ได้ (ภาพขวา)และดำเนินการยืนยันตัวตนตามขั้นตอนของแต่ละผู้ให้บริการต่อไปได้</p>
                                <Pic6_3 style={{ margin: "12px 0px" }} />
                                <p>3. การยืนยันตัวตนมีเวลา 60 นาที กรุณาไปที่แอพพลิเคชั่นที่ท่านได้เลือกไว้ก่อนหน้านี้ ทำตามขั้นตอนของผู้ให้บริการยืนยันตัวตน จนสำเร็จ และกลับมาที่หน้าจอเปิดบัญชี</p>
                                <Pic6_4 style={{ margin: "12px 0px" }} />
                                <p>4. เมื่อท่านกลับมาที่หน้าจอเปิดบัญชี หลังจากยืนยันตัวตน ถือว่าเป็นการยืนยันตัวตนเสร็จสิ้น เราจะดำเนินการตรวจสอบเอกสารของท่าน และแจ้งผลให้ทราบผ่าน SMS</p>
                            </>}
                        />
                        <MyAccordion
                            header={"7. เพิ่มบัญชีคู่ฝาก"}
                            content={<>
                                <p>กรุณาเตรียมสมุดบัญชีที่เป็นออมทรัพย์เท่านั้น และทำตามขั้นตอน ดังนี้</p>
                                <ul>
                                    <li>วางสมุดบัญชีในแนวนอน</li>
                                    <li>อยู่ในที่ที่มีแสงสว่างเพียงพอ</li>
                                    <li>กดถ่ายรูป</li>
                                    <li>ตรวจสอบและแก้ไขข้อมูลให้ถูกต้อง</li>
                                </ul>
                                <Pic7 style={{ margin: "12px 0px" }} />
                            </>}
                        />
                        <MyAccordion
                            header={"8. สถานะหลังการเปิดบัญชี"}
                            content={<>
                                <Pic8_1 style={{ margin: "12px 0px" }} />
                                <p><b>เรากำลังตรวจสอบข้อมูล</b></p>
                                <p>ท่านเปิดบัญชีและได้ยืนยันตัวตนแล้ว ทางเรากำลังตรวจสอบข้อมูลให้ และแจ้งผลให้ท่านทราบผ่านทาง SMS ไม่เกิน 14 วันทำการ</p>
                                <Pic8_2 style={{ margin: "12px 0px" }} />
                                <p><b>เปิดบัญชีไม่สำเร็จ</b></p>
                                <p>ท่านเปิดบัญชีและได้ยืนยันตัวตนแล้ว อาจจะเกิดจากเอกสารของท่านมีปัญหา ท่านสามารถลองเปิดบัญชีใหม่ได้อีกครั้ง หรือต้องการความช่วยเหลือเพิ่มเติม กรุณาติดต่อเจ้าหน้าที่ โทร 02-678-0666 ต่อ 726-728</p>
                                <Pic8_3 style={{ margin: "12px 0px" }} />
                                <p><b>เปิดบัญชีสำเร็จ</b></p>
                                <p>หากท่านต้องการเปลี่ยนแปลงข้อมูลบัญชี กรุณาติดต่อเจ้าหน้าที่ โทร 02-678-0666 ต่อ 726-728</p>
                            </>}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpenAccount