import * as jose from 'jose';
import PrivateKey from "../../utils/key/private_key";
import PublicKey from "../../utils/key/public_key";

const EncryptData = async (data) => {
    const ecPublicKey = await jose.importSPKI(PublicKey, 'RSA-OAEP-256');
    const jwe = await new jose.FlattenedEncrypt(new TextEncoder().encode(JSON.stringify(data)))
        .setProtectedHeader({ alg: 'RSA-OAEP-256', enc: 'A256CBC-HS512' })
        .encrypt(ecPublicKey);
    return jwe;
}

const DecryptData = async (jwe) => {
    const ecPrivateKey = await jose.importPKCS8(PrivateKey, 'RSA-OAEP-256');
    const { plaintext } = await jose.flattenedDecrypt(jwe, ecPrivateKey);
    let decodedText = new TextDecoder('utf-8').decode(plaintext);
    return decodedText;
}

const convertJSONFormat = async (stringJSON) => {
    let newStringJSON = "";
    for (let i in stringJSON) {
        stringJSON[i] === "'" ? newStringJSON += '"' : (stringJSON[i] === '"' ? newStringJSON += "'" : newStringJSON += stringJSON[i]);
    }
    newStringJSON = newStringJSON.replaceAll("True", "true");
    newStringJSON = newStringJSON.replaceAll("False", "false");
    return newStringJSON;
}

const JWE = { EncryptData, DecryptData, convertJSONFormat };

export default JWE;
